import React from 'react'
import {Image, StyleProp, ViewStyle} from 'react-native'
import {Path} from 'react-native-svg'

// import {useTheme} from '#/alf'
// import {useThemeName} from '#/alf/util/useColorModeTheme'
import {SdlSVG} from './ProfileIcons'

interface FeedIconsProps {
  size?: number
  style?: StyleProp<ViewStyle>
  active?: boolean
}

export const BooIcon = ({size, style, active}: FeedIconsProps) => {
  if (active) {
    return (
      <SdlSVG
        defaultSize={16}
        size={size}
        style={[{transform: [{translateY: 1}]}, style]}>
        <Path
          d="M10.1018 9.82629C10.3786 11.0626 10.5718 11.9568 10.682 12.5107C10.9591 13.9051 10.2395 15.2 8.81327 15.2C7.52722 15.2 6.88135 14.5609 6.5005 13.2834L6.49073 13.2461C6.26212 12.2126 5.91254 11.4115 5.44981 10.8383C5.20904 10.5392 4.90584 10.2964 4.56143 10.1268C4.21702 9.95721 3.83971 9.86488 3.45589 9.85631L3.09104 9.8485C2.221 9.82984 1.52539 9.11363 1.52539 8.23631L1.52539 2.94341C1.52539 1.8643 2.39312 0.98946 3.46371 0.98946L10.3334 0.98946C11.7446 0.98946 13.0041 1.88153 13.4829 3.21963L14.91 7.20942C15.273 8.22459 14.7511 9.34438 13.7442 9.71048C13.5334 9.78711 13.3108 9.8263 13.0866 9.82629L10.1018 9.82629Z"
          fill="#FF3489"
          stroke="#FF3489"
          stroke-width="1.5"
        />
      </SdlSVG>
    )
  }

  return (
    <SdlSVG
      defaultSize={16}
      size={size}
      style={[{transform: [{translateY: 1}]}, style]}>
      <Path
        d="M10.1018 0.00913811H3.12062C1.6586 0.00913811 0.470703 1.21531 0.470703 2.70474V8.15077C0.470703 9.41176 1.48498 10.4717 2.7277 10.4991H3.10234C3.38561 10.5174 3.67801 10.5814 3.93387 10.7093C4.18972 10.8372 4.4273 11.0291 4.60091 11.2576C5.00297 11.7693 5.31365 12.5094 5.52382 13.4506C5.85277 14.5928 6.51068 16 8.54837 16C9.35248 16 10.0561 15.6802 10.5404 15.0862C11.1069 14.4009 11.3171 13.4232 11.116 12.4089C11.0338 11.9703 10.8967 11.3215 10.7049 10.4626H12.8796C13.1903 10.4626 13.4918 10.4078 13.7842 10.2981C15.1549 9.79555 15.8676 8.25129 15.3833 6.85323L13.9304 2.75043C13.3456 1.10565 11.8014 9.53674e-07 10.0835 9.53674e-07L10.1018 0.00913811ZM8.55751 14.6294C7.76254 14.6294 7.23255 14.3826 6.86705 13.1491C6.62033 12.0069 6.21828 11.0748 5.67915 10.3986C5.37761 10.0148 4.98469 9.69503 4.54609 9.48487C4.10748 9.26556 3.62319 9.14677 3.12976 9.13764H2.75511C2.25254 9.11936 1.83221 8.68075 1.83221 8.15991V2.71388C1.83221 1.98287 2.40788 1.38892 3.11148 1.38892H10.0835C11.2257 1.38892 12.2491 2.12907 12.6329 3.22559L14.0858 7.32838C14.3325 8.02284 13.9853 8.78127 13.3091 9.02798C13.172 9.08281 13.0258 9.10109 12.8796 9.10109H8.99612L9.17887 9.93261C9.46214 11.1936 9.65403 12.1165 9.76368 12.6739C9.88247 13.277 9.76368 13.8527 9.47127 14.209C9.24283 14.4832 8.93215 14.6202 8.53924 14.6202L8.55751 14.6294Z"
        fill={'#536471'}
      />
    </SdlSVG>
  )
}

export const UpvoteIcon = ({size, style, active}: FeedIconsProps) => {
  if (active) {
    return (
      <SdlSVG
        defaultSize={16}
        size={size}
        style={[{transform: [{translateY: 1}]}, style]}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.45831 1.13028C6.94256 0.711965 7.55218 0.529999 8.27406 0.529999C9.15454 0.529999 9.85015 0.939407 10.2664 1.5802C10.6724 2.20522 10.7902 3.01104 10.6324 3.80528C10.5449 4.24524 10.405 4.89917 10.2131 5.76591H12.4377C12.7201 5.7659 13.0004 5.81526 13.2658 5.91175C14.5334 6.3726 15.1847 7.77829 14.7305 9.04833L13.34 12.9358C12.7963 14.4554 11.364 15.4723 9.75525 15.4723H3.06169C1.7117 15.4723 0.625 14.3703 0.625 13.0204V7.8632C0.625 6.71426 1.53706 5.76905 2.68683 5.7444L3.04183 5.7368C3.33599 5.73023 3.62516 5.65948 3.88911 5.52949C4.15307 5.39949 4.38544 5.21341 4.56997 4.98424C4.95058 4.51274 5.26454 3.81922 5.47595 2.86354L5.47822 2.85325L5.49274 2.79781L5.49537 2.78899C5.69551 2.11763 5.98627 1.53804 6.45831 1.13028Z"
          fill="#FF3489"
        />
      </SdlSVG>
    )
  }

  return (
    <SdlSVG
      defaultSize={16}
      size={size}
      style={[{transform: [{translateY: 1}]}, style]}>
      <Path
        d="M10.1018 15.9909H3.12062C1.6586 15.9909 0.470703 14.7847 0.470703 13.2953V7.84923C0.470703 6.58824 1.48498 5.52827 2.7277 5.50086H3.10234C3.38561 5.48258 3.67801 5.41862 3.93387 5.29069C4.18972 5.16276 4.4273 4.97087 4.60091 4.74243C5.00297 4.23073 5.31365 3.49058 5.52382 2.5494C5.85277 1.4072 6.51068 0 8.54837 0C9.35248 0 10.0561 0.319817 10.5404 0.913764C11.1069 1.59909 11.3171 2.57681 11.116 3.59109C11.0338 4.0297 10.8967 4.67847 10.7049 5.53741H12.8796C13.1903 5.53741 13.4918 5.59223 13.7842 5.70188C15.1549 6.20445 15.8676 7.74871 15.3833 9.14677L13.9304 13.2496C13.3456 14.8943 11.8014 16 10.0835 16L10.1018 15.9909ZM8.55751 1.37065C7.76254 1.37065 7.23255 1.61736 6.86705 2.85094C6.62033 3.99315 6.21828 4.92519 5.67916 5.60137C5.37761 5.98515 4.98469 6.30497 4.54609 6.51513C4.10748 6.73444 3.62319 6.85323 3.12976 6.86236H2.75511C2.25254 6.88064 1.83221 7.31925 1.83221 7.84009V13.2861C1.83221 14.0171 2.40788 14.6111 3.11148 14.6111H10.0835C11.2257 14.6111 12.2491 13.8709 12.6329 12.7744L14.0858 8.67162C14.3325 7.97716 13.9853 7.21873 13.3091 6.97202C13.172 6.91719 13.0258 6.89891 12.8796 6.89891H8.99612L9.17887 6.06739C9.46214 4.8064 9.65403 3.8835 9.76368 3.3261C9.88247 2.72302 9.76368 2.14734 9.47127 1.79098C9.24283 1.51685 8.93215 1.37978 8.53923 1.37978L8.55751 1.37065Z"
        fill="#536471"
      />
    </SdlSVG>
  )
}

export const ReprintIcon = (props: FeedIconsProps) => {
  // const t = useTheme()
  return (
    <SdlSVG
      defaultSize={16}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9447 1.53581C12.296 1.53581 13.5667 2.04591 14.5243 2.98556L14.5153 2.97661C15.4729 3.91626 16.0009 5.16018 16.0009 6.48464C16.0009 6.94104 15.9382 7.3885 15.8219 7.81805C15.7414 8.10442 15.4639 8.31025 15.1597 8.31025C15.097 8.31025 15.0344 8.3013 14.9718 8.2834C14.8017 8.23866 14.6496 8.12232 14.5601 7.96124C14.4706 7.8091 14.4527 7.62117 14.4974 7.45114C14.5869 7.13792 14.6317 6.81576 14.6317 6.48464C14.6317 4.5069 12.9761 2.88712 10.9447 2.88712H5.89741V3.71938C5.89741 3.91626 5.82582 4.07735 5.69158 4.14894C5.55734 4.22053 5.37836 4.21158 5.20833 4.10419L2.83683 2.67234C2.6668 2.5739 2.56836 2.42177 2.56836 2.26069C2.56836 2.0996 2.65785 1.94747 2.82788 1.84008L5.20833 0.33664C5.38731 0.229251 5.55734 0.211353 5.69158 0.282946C5.82582 0.354538 5.89741 0.506672 5.89741 0.703551V1.53581H10.9447ZM11.2132 9.88298L13.5847 11.3148V11.3238C13.7547 11.4222 13.8531 11.5744 13.8531 11.7354C13.8531 11.8965 13.7636 12.0487 13.5936 12.156L11.2132 13.6595C11.1058 13.7221 11.0073 13.7579 10.9089 13.7579C10.8463 13.7579 10.7836 13.74 10.7299 13.7132C10.5957 13.6416 10.5241 13.4894 10.5241 13.2926V12.4603H5.06517C3.71386 12.4603 2.44309 11.9502 1.48554 11.0106C0.527994 10.0709 0 8.82699 0 7.50253C0 6.54498 0.28637 5.61428 0.814364 4.80887C1.01124 4.49565 1.4408 4.40616 1.76296 4.60304C1.92405 4.70148 2.03144 4.85361 2.06723 5.03259C2.10303 5.21157 2.06723 5.39055 1.96879 5.54269C1.58398 6.12438 1.37815 6.79556 1.37815 7.49358C1.37815 9.48027 3.03373 11.0911 5.06517 11.0911H10.5241V10.2678C10.5241 10.062 10.5957 9.90983 10.7299 9.83823C10.8642 9.75769 11.0431 9.77559 11.2132 9.88298Z"
        fill={props.active ? '#16AF69' : '#536471'}
      />
    </SdlSVG>
  )
}

export const ReplyIcon = ({size, style}: FeedIconsProps) => {
  return (
    <SdlSVG
      defaultSize={17}
      size={size}
      style={[{transform: [{translateY: 1}]}, style]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1575 1.16393C13.6384 2.0689 14.8085 3.45836 15.4392 5.0672L15.4484 5.05806C16.0791 6.67604 16.1705 8.47684 15.7043 10.1497C15.2381 11.8225 14.2143 13.3125 12.834 14.3637C10.503 16.1371 7.17563 16.4662 4.55212 15.2139L0.822535 15.9817C0.77683 16 0.731124 16 0.685418 16C0.502595 16 0.328914 15.9269 0.200938 15.7989C0.0455385 15.6344 -0.0275906 15.4058 0.0181151 15.1773L0.77683 11.4477C0.072962 9.95771 -0.164708 8.25746 0.118668 6.63033C0.411184 4.92094 1.27959 3.33038 2.54107 2.14203C3.81169 0.962827 5.45709 0.213254 7.18477 0.0395719C8.91244 -0.13411 10.6767 0.2681 12.1575 1.16393ZM12.0021 13.2851C13.1448 12.4167 13.9858 11.1735 14.3788 9.79317L14.388 9.80231C14.7719 8.422 14.6988 6.92285 14.1686 5.58824C13.6476 4.2445 12.6786 3.10185 11.4537 2.35228C10.2196 1.60271 8.75704 1.27363 7.33103 1.41988C5.90501 1.56614 4.53384 2.18774 3.48261 3.16584C2.43138 4.14394 1.71837 5.46027 1.47156 6.87714C1.22475 8.29402 1.45327 9.77489 2.11144 11.0546C2.18456 11.1918 2.20285 11.3472 2.17542 11.5026L1.57211 14.446L4.51556 13.8427C4.56126 13.8244 4.60697 13.8244 4.65267 13.8244C4.75323 13.8244 4.86292 13.8427 4.96347 13.8975C7.17563 15.0402 10.0002 14.8025 12.0021 13.2851ZM7.09348 8.01065C7.09348 7.50789 7.50483 7.0874 8.00759 7.0874H8.01673C8.5195 7.0874 8.93085 7.49875 8.93085 8.00151C8.93085 8.50427 8.5195 8.92476 8.01673 8.92476C7.51397 8.92476 7.09348 8.51341 7.09348 8.01065ZM4.35114 7.0874C3.84837 7.0874 3.43702 7.50789 3.43702 8.01065C3.43702 8.51341 3.85751 8.92476 4.36028 8.92476C4.86304 8.92476 5.27439 8.50427 5.27439 8.00151C5.27439 7.49875 4.86304 7.0874 4.36028 7.0874H4.35114ZM10.7499 8.01065C10.7499 7.50789 11.1613 7.0874 11.664 7.0874H11.6732C12.176 7.0874 12.5873 7.49875 12.5873 8.00151C12.5873 8.50427 12.176 8.92476 11.6732 8.92476C11.1704 8.92476 10.7499 8.51341 10.7499 8.01065Z"
        fill="#536471"
      />
    </SdlSVG>
  )
}

export const ShareIcon = ({size, style}: FeedIconsProps) => {
  return (
    <SdlSVG
      defaultSize={16}
      size={size}
      style={[{transform: [{translateY: 1}]}, style]}>
      <Path
        d="M0.685714 15.5358C0.64 15.5358 0.585143 15.5358 0.539429 15.5176C0.228571 15.4536 0 15.1701 0 14.8501V14.0364C0 9.09013 3.52 4.94841 8.128 4.30841V1.13584C8.128 0.861556 8.29257 0.605556 8.54857 0.504985C8.80457 0.39527 9.09714 0.45927 9.29829 0.65127L15.7989 7.10613C15.9269 7.23413 16 7.40784 16 7.5907C16 7.77356 15.9269 7.94727 15.7989 8.07527L9.29829 14.5301C9.09714 14.7221 8.80457 14.7861 8.54857 14.6764C8.29257 14.5667 8.128 14.3198 8.128 14.0456V10.9827C5.06514 11.193 2.37714 12.8113 1.30743 15.1336C1.19771 15.3804 0.950857 15.5358 0.685714 15.5358ZM9.49943 2.78156V4.93013C9.49943 5.29584 9.216 5.58841 8.85943 5.61584C5.16571 5.85356 2.176 8.76098 1.50857 12.4821C3.25486 10.6901 5.93371 9.59298 8.81371 9.59298C9.18857 9.59298 9.49943 9.90384 9.49943 10.2787V12.3998L14.3451 7.5907L9.49943 2.78156Z"
        fill="#536471"
      />
    </SdlSVG>
  )
}

export const FollowIcon = ({size, style}: FeedIconsProps) => {
  return (
    <SdlSVG
      defaultSize={16}
      size={size}
      style={[{transform: [{translateY: 1}]}, style]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8978 7.2616C4.89301 7.19274 3.35499 5.60115 3.35499 3.63463C3.35499 1.62984 4.98483 0 6.98962 0C8.9944 0 10.6242 1.62984 10.6242 3.63463C10.6166 5.60115 9.07857 7.19274 7.1197 7.2616H7.1044H7.06614C7.02023 7.25395 6.95901 7.25395 6.8978 7.2616ZM0.450195 12.1716C0.450195 11.1525 1.10101 10.1996 2.28179 9.50355C4.89436 7.96236 9.12468 7.96236 11.7187 9.50355C12.8901 10.1913 13.5317 11.1442 13.541 12.1551C13.541 13.1825 12.8901 14.1271 11.7187 14.8314C10.417 15.6103 8.7063 15.9997 6.99557 15.9997C5.28485 15.9997 3.57413 15.6103 2.27249 14.8314C1.10101 14.1354 0.450195 13.1908 0.450195 12.1716ZM6.83873 13.3581C6.74274 13.4541 6.61474 13.5021 6.48675 13.5021C6.35875 13.5021 6.23076 13.4461 6.13476 13.3581L5.12681 12.3501C4.93482 12.1581 4.93482 11.8381 5.12681 11.6461C5.3188 11.4542 5.63879 11.4542 5.83078 11.6461L6.48675 12.3021L8.15068 10.6382C8.34267 10.4462 8.66266 10.4462 8.85465 10.6382C9.05464 10.8302 9.05464 11.1502 8.85465 11.3422L6.83873 13.3581Z"
        fill="#FF6B00"
      />
    </SdlSVG>
  )
}

export const TeaTagIcon = ({size = 16, style}: FeedIconsProps) => {
  return (
    <Image
      style={[{height: size, width: size}, style]}
      source={require('../../../assets/icons/tea_tag.png')}
      accessibilityIgnoresInvertColors={true}
    />
  )
}

export const TGTagIcon = ({size = 16, style}: FeedIconsProps) => {
  return (
    <Image
      style={[{height: size, width: size}, style]}
      source={require('../../../assets/icons/tg_tag.png')}
      accessibilityIgnoresInvertColors={true}
    />
  )
}

export const GuarantorIcon = ({size, style}: FeedIconsProps) => {
  return (
    <SdlSVG
      defaultSize={16}
      size={size}
      style={[{transform: [{translateY: 1}]}, style]}>
      <Path
        d="M1.52422 8.93575H0.977196C0.832117 8.93575 0.692981 8.87812 0.590394 8.77554C0.487808 8.67295 0.430176 8.53381 0.430176 8.38873C0.430176 8.24365 0.487808 8.10452 0.590394 8.00193C0.692981 7.89934 0.832117 7.84171 0.977196 7.84171H13.0116C13.1567 7.84171 13.2959 7.89934 13.3984 8.00193C13.501 8.10452 13.5587 8.24365 13.5587 8.38873C13.5587 8.53381 13.501 8.67295 13.3984 8.77554C13.2959 8.87812 13.1567 8.93575 13.0116 8.93575H12.4646V14.406H13.0116C13.1567 14.406 13.2959 14.4636 13.3984 14.5662C13.501 14.6688 13.5587 14.8079 13.5587 14.953C13.5587 15.0981 13.501 15.2372 13.3984 15.3398C13.2959 15.4424 13.1567 15.5 13.0116 15.5H0.977196C0.832117 15.5 0.692981 15.4424 0.590394 15.3398C0.487808 15.2372 0.430176 15.0981 0.430176 14.953C0.430176 14.8079 0.487808 14.6688 0.590394 14.5662C0.692981 14.4636 0.832117 14.406 0.977196 14.406H1.52422V8.93575ZM8.79241 4.87601C11.2945 4.87601 11.2945 7.06409 11.2945 7.06409H2.54215V7.03674C2.5531 6.78183 2.70954 4.87601 5.04204 4.87601H8.79241ZM6.91832 0.499849C7.49863 0.499849 8.05518 0.730378 8.46552 1.14072C8.87587 1.55107 9.1064 2.10761 9.1064 2.68793C9.1064 3.26824 8.87587 3.82479 8.46552 4.23514C8.05518 4.64548 7.49863 4.87601 6.91832 4.87601C6.338 4.87601 5.78145 4.64548 5.37111 4.23514C4.96076 3.82479 4.73024 3.26824 4.73024 2.68793C4.73024 2.10761 4.96076 1.55107 5.37111 1.14072C5.78145 0.730378 6.338 0.499849 6.91832 0.499849Z"
        fill="#1B76FF"
      />
    </SdlSVG>
  )
}

export const StakeNotificationIcon = ({size, style}: FeedIconsProps) => {
  return (
    <SdlSVG
      defaultSize={16}
      size={size}
      style={[{transform: [{translateY: 1}]}, style]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15385 6.86391C4.2371 6.86391 2.5068 6.41641 1.37273 5.67926C1.09827 5.95374 0.94359 6.24915 0.94359 6.54457C0.94359 7.7097 3.24103 8.81739 6.15385 8.81739C7.04821 8.81739 7.88513 8.71072 8.61539 8.53021V9.45739C7.86051 9.6297 7.02359 9.71995 6.15385 9.71995C4.14359 9.71995 2.33846 9.24405 1.21436 8.46457L1.2142 8.46446C0.94359 8.81723 0.94359 9.20276 0.94359 9.43246C0.94359 10.5976 3.24103 11.6971 6.15385 11.6971C7.17128 11.6971 8.11487 11.5658 8.91077 11.336C9.01744 11.6232 9.14872 11.9104 9.30462 12.173C8.37744 12.4519 7.30256 12.6078 6.15385 12.6078C2.78974 12.6078 0 11.2704 0 9.43246C0 8.89919 0.229687 8.41514 0.648051 7.98851C0.229687 7.56188 0 7.07784 0 6.54457C0 6.01294 0.23681 5.519 0.653761 5.08887C0.236102 4.64498 0 4.13564 0 3.58186C0 1.67827 2.78974 0.299805 6.15385 0.299805C9.51795 0.299805 12.3077 1.67827 12.3077 3.58186C12.3077 5.48545 9.51795 6.86391 6.15385 6.86391ZM6.15385 5.92852C9.06667 5.92852 11.3641 4.78801 11.3641 3.58186C11.3641 2.3757 9.06667 1.23519 6.15385 1.23519C3.24103 1.23519 0.94359 2.3757 0.94359 3.58186C0.94359 4.78801 3.24103 5.92852 6.15385 5.92852ZM12.0617 6.58538C12.275 6.49513 12.4965 6.4541 12.7181 6.4541C12.9396 6.4541 13.1612 6.49513 13.3745 6.58538L15.6801 7.53718C15.8771 7.61923 16.0001 7.80795 16.0001 8.02128V9.67872C16.0001 11.5905 14.5971 13.3628 12.7181 13.8305C10.8391 13.3546 9.43604 11.5905 9.43604 9.67872V8.02128C9.43604 7.80795 9.55911 7.61923 9.75604 7.53718L12.0617 6.58538Z"
        fill="#8386FF"
      />
    </SdlSVG>
  )
}
