import React from 'react'
import {FlexStyle, Text} from 'react-native'
// import { useLingui } from "@lingui/react"
import {View} from 'react-native'
import {Trans} from '@lingui/macro'

import {atoms as a, useTheme} from '#/alf'
import {Divider} from '#/components/Divider'

type Props = {
  style?: FlexStyle
}

export function Footer({style}: Props) {
  const t = useTheme()
  // const {_} = useLingui()

  return (
    <View
      style={[
        //   a.absolute,
        // a.flex_row,
        // a.flex_wrap,
        a.align_center,
        a.gap_sm,
        a.w_full,
        // a.flex_1,
        t.atoms.border_contrast_medium,
        style,
      ]}>
      <Text style={[{fontSize: 15, fontWeight: 500, color: '#9999'}]}>
        <Trans>Powered by SendingNetwork</Trans>
      </Text>
      <Divider style={[a.w_full]} />
      <Text style={[{fontWeight: 400}, t.atoms.text_contrast_low, a.text_xs]}>
        <Trans>© 2024 LinX Communication Inc.</Trans>
      </Text>
    </View>
  )
}
