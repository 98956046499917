import React, {ComponentProps} from 'react'
import {StyleSheet, Text, TextInput as RNTextInput, View} from 'react-native'
import InputSpinner from 'react-native-input-spinner'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {usePalette} from 'lib/hooks/usePalette'
import {s} from 'lib/styles'
import {useTheme} from 'lib/ThemeContext'
import {atoms as a, useTheme as useThemeAlf} from '#/alf'
import {Button} from '#/components/Button'

interface Props extends Omit<ComponentProps<typeof RNTextInput>, 'onChange'> {
  testID?: string
  icon: IconProp
  onChange: (v: string) => void
}

export function TextInput({testID, icon, onChange, ...props}: Props) {
  const theme = useTheme()
  const pal = usePalette('default')
  return (
    <View style={[pal.border, styles.container]}>
      <FontAwesomeIcon icon={icon} style={[pal.textLight, styles.icon]} />
      <RNTextInput
        testID={testID}
        style={[pal.text, styles.textInput]}
        placeholderTextColor={pal.colors.textLight}
        autoCapitalize="none"
        autoCorrect={false}
        keyboardAppearance={theme.colorScheme}
        onChangeText={v => onChange(v)}
        {...props}
      />
    </View>
  )
}

interface TokenInputProps
  extends Omit<ComponentProps<typeof RNTextInput>, 'onChange'> {
  testID?: string
  tokenSymbol?: string
  min?: number
  max?: number
  step?: number
  onPressStep?: (type: 'minus' | 'plus') => void
  onChange: (v: string) => void
  isError?: boolean
}

export function TokenInput({
  testID,
  tokenSymbol,
  onChange,
  isError,
  ...props
}: TokenInputProps) {
  const theme = useTheme()
  const pal = usePalette('default')

  return (
    <View style={[pal.border, styles.container, isError && styles.error]}>
      <Text style={[s.f14, s.pl10]}>Amount</Text>
      <RNTextInput
        testID={testID}
        style={[pal.text, styles.textInput, styles.tokenInput]}
        placeholderTextColor={pal.colors.textLight}
        autoCapitalize="none"
        autoCorrect={false}
        keyboardAppearance={theme.colorScheme}
        onChangeText={v => onChange(v)}
        {...props}
      />
      <Text style={[s.f16, s.pr10]}>{tokenSymbol}</Text>
    </View>
  )
}

export function TokenSelectInput({
  testID,
  onChange,
  ...props
}: TokenInputProps) {
  const theme = useTheme()
  const pal = usePalette('default')
  return (
    <View style={[pal.border, styles.container]}>
      <Text style={[s.f14, s.pl10]}>Amount</Text>
      <RNTextInput
        testID={testID}
        style={[pal.text, styles.textInput, styles.tokenInput]}
        placeholderTextColor={pal.colors.textLight}
        autoCapitalize="none"
        autoCorrect={false}
        keyboardAppearance={theme.colorScheme}
        onChangeText={v => onChange(v)}
        {...props}
      />
    </View>
  )
}

export function TokenMinMaxInput({
  onChange,
  value,
  min,
  max,
  step,
  placeholder,
  isError,
}: {
  onChange: (v: number | undefined) => void
  value?: number
  min: number
  max: number
  step: number
  placeholder: string
  isError?: boolean
}) {
  const t = useThemeAlf()
  const pal = usePalette('default')
  const {_} = useLingui()

  return (
    <View
      style={[
        pal.border,
        styles.container,
        {width: '100%'},
        isError && styles.error,
      ]}>
      <Button
        label={_(msg`Min`)}
        style={[a.px_md]}
        variant="outline"
        onPress={() => {
          onChange(min)
        }}>
        <Text
          style={[a.text_sm, a.font_semibold, {color: t.palette.contrast_500}]}>
          Min
        </Text>
      </Button>
      <InputSpinner
        style={[styles.spinner]}
        value={value}
        // min={min}
        max={max}
        step={step}
        rounded={false}
        showBorder={true}
        color="#fff"
        fontSize={24}
        placeholder={placeholder}
        buttonTextColor={t.palette.contrast_500}
        buttonStyle={{
          borderRadius: 0,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderColor: t.palette.contrast_100,
        }}
        onChange={(v: number) => {
          onChange(v)
        }}
      />
      <Button
        label={_(msg`Max`)}
        style={[a.px_md]}
        onPress={() => {
          onChange(max)
        }}>
        <Text
          style={[a.text_sm, a.font_semibold, {color: t.palette.contrast_500}]}>
          Max
        </Text>
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
  },
  icon: {
    marginLeft: 10,
  },
  textInput: {
    flex: 1,
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 10,
    fontSize: 17,
    letterSpacing: 0.25,
    fontWeight: '400',
    borderRadius: 10,
  },
  tokenInput: {
    fontSize: 24,
    textAlign: 'right',
    fontWeight: '700',
  },
  stepBtn: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 0,
    padding: 16,
    alignItems: 'center',
  },
  spinner: {
    flex: 1,
    marginRight: 10,
    minWidth: 150,
  },
  error: {
    borderColor: '#FF543D',
  },
})
