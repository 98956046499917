import React from 'react'
import {View} from 'react-native'
import RNPickerSelect from 'react-native-picker-select'

import {atoms as a, useTheme} from '#/alf'
import {ApiCountryCode} from './types'

interface IProps {
  list: ApiCountryCode[]
  //   defVal: string
  updateCountry: (val: ApiCountryCode) => void
}
export function CountryDropdown(props: IProps) {
  const {list, updateCountry} = props
  const t = useTheme()
  //   const [value, setValue] = useState<string>()

  const onCountryChange = (value: string) => {
    const country = list.find(item => item.defaultName === value)
    updateCountry(country!)
  }

  //   useEffect(() => {
  //     setValue(defVal)
  //   }, [defVal])

  return (
    <View style={a.relative}>
      <RNPickerSelect
        placeholder={{}}
        // value={'USA'}
        onValueChange={onCountryChange}
        items={list?.map(l => ({
          label: `(+${l.countryCode}) ${l.defaultName}`,
          value: l.defaultName,
          key: l.defaultName,
        }))}
        useNativeAndroidPickerStyle={false}
        style={{
          inputAndroid: {
            color: t.atoms.text_contrast_medium.color,
            fontSize: 16,
            paddingRight: 12 + 4,
          },
          inputIOS: {
            color: t.atoms.text.color,
            fontSize: 16,
            paddingRight: 12 + 4,
          },
          inputWeb: {
            borderWidth: 1,
            height: 44,
            ...a.relative,
            ...a.p_xs,
            ...a.rounded_md,
            ...t.atoms.text,
            ...a.text_md,
            ...a.pl_sm,
            ...a.pr_md,
            ...t.atoms.border_contrast_low,
            backgroundColor: t.palette.white,
          },
        }}
      />
    </View>
  )
}
