import React, {useEffect, useState} from 'react'
import {
  Keyboard,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native'

import {isWeb} from '#/platform/detection'
import {ITelegramModal} from '#/state/modals'
import {useTelegramClient, useTelegramLink} from './hooks'
import {LoginBase} from './LoginBase'
import {LoginCode} from './LoginCode'
import {BaseLoginParams} from './types'
import {getContacts} from './util'

export const snapPoints = ['fullscreen']

function userAuthParamCallback<T>(param: T): () => Promise<T> {
  return async function () {
    return await new Promise<T>(resolve => {
      resolve(param)
    })
  }
}

export function Component({}: ITelegramModal) {
  const [params, setParams] = useState<BaseLoginParams>()
  const [step, setStep] = useState<number>(1) // 1|2
  // const {closeModal} = useModalControls()
  const {client, resetClient} = useTelegramClient()
  const {handleBind} = useTelegramLink()
  const onLogin = async (code: string) => {
    if (client && params?.phone) {
      await client.connect()
      await client.start({
        phoneNumber: params.phone,
        password: userAuthParamCallback(params.password),
        phoneCode: userAuthParamCallback(code),
        onError: (err: any) => {
          console.log(err)
          return true
        },
        onSuccess: () => {
          console.log('onSuccess')
        },
      })
      const newClient = resetClient(client.session.save())
      // getContacts(client)
      await handleBind(newClient)
    }
  }

  useEffect(() => {
    if (client) getContacts(client)
  }, [client])

  return (
    <TouchableWithoutFeedback
      accessibilityRole="button"
      onPress={() => !isWeb && Keyboard.dismiss()}>
      <View style={styles.page}>
        {step === 1 && (
          <LoginBase
            client={client}
            updateLoginParams={p => {
              setParams(p)
              setStep(2)
            }}
          />
        )}
        {step === 2 && <LoginCode onLogin={onLogin} />}
      </View>
    </TouchableWithoutFeedback>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    // padding: 10,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
