import * as telegram from '../../../../../web/telegram'

export async function getContacts(client: any) {
  const {Api} = telegram.tl

  try {
    await client.connect()
    if (await client.checkAuthorization()) {
      const res = await client.invoke(
        new Api.contacts.GetContacts({
          hash: BigInt('-4156887774564') as any,
        }),
      )
      console.log('getContacts', res)
      return res
    }

    // const getUserProfilePhoto = result.users.map(user => {
    //   return Promise.all([user, client.downloadProfilePhoto(user)])
    // })

    // for await (const [user, buffer] of getUserProfilePhoto) {
    //   const img = new Blob([buffer], {type: 'image/jpeg'})
    //   _userAvatarMap.set(user as Api.User, URL.createObjectURL(img))
    // }
    // return result as Api.contacts.Contacts
  } catch (err) {
    return Promise.reject(err)
  }
}
