import React, {useEffect} from 'react'
import {StyleSheet, View} from 'react-native'

import {useAgent} from '#/state/session'
import {TeaTagIcon, TGTagIcon} from '#/view/icons/FeedIcons'
import {getRelationship} from '../util/sdlStore/Relationship'

export function RelationshipTag({did}: {did: string}) {
  const [relations, setRelations] = React.useState<string[]>([])
  const agent = useAgent()

  useEffect(() => {
    getRelationship(did, agent).then(res => {
      setRelations(res)
    })
  }, [agent, did])

  return (
    <>
      {relations.length > 0 ? (
        <View style={styles.page}>
          {relations.map((relation, index) => {
            switch (relation) {
              case 'telegram':
                return <TGTagIcon key={index} style={{marginLeft: -10}} />
              case 'sipz':
                return <TeaTagIcon key={index} style={{marginLeft: -10}} />
            }
          })}
        </View>
      ) : null}
    </>
  )
}
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 10,
    paddingLeft: 20,
    flexDirection: 'row',
    transform: [{translateY: 2}],
  },
})
