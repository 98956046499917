export const countries: any[] = [
  {
    defaultName: 'Andorra',
    name: null,
    iso2: 'AD',
    hidden: false,
    countryCode: '376',
    patterns: ['XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'United Arab Emirates',
    name: null,
    iso2: 'AE',
    hidden: false,
    countryCode: '971',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Afghanistan',
    name: null,
    iso2: 'AF',
    hidden: false,
    countryCode: '93',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Antigua & Barbuda',
    name: null,
    iso2: 'AG',
    hidden: false,
    countryCode: '1268',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Anguilla',
    name: null,
    iso2: 'AI',
    hidden: false,
    countryCode: '1264',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Albania',
    name: null,
    iso2: 'AL',
    hidden: false,
    countryCode: '355',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Armenia',
    name: null,
    iso2: 'AM',
    hidden: false,
    countryCode: '374',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Angola',
    name: null,
    iso2: 'AO',
    hidden: false,
    countryCode: '244',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Argentina',
    name: null,
    iso2: 'AR',
    hidden: false,
    countryCode: '54',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'American Samoa',
    name: null,
    iso2: 'AS',
    hidden: false,
    countryCode: '1684',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Austria',
    name: null,
    iso2: 'AT',
    hidden: false,
    countryCode: '43',
    patterns: ['XXX XXXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Australia',
    name: null,
    iso2: 'AU',
    hidden: false,
    countryCode: '61',
    patterns: ['X XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Aruba',
    name: null,
    iso2: 'AW',
    hidden: false,
    countryCode: '297',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Azerbaijan',
    name: null,
    iso2: 'AZ',
    hidden: false,
    countryCode: '994',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Bosnia & Herzegovina',
    name: null,
    iso2: 'BA',
    hidden: false,
    countryCode: '387',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Barbados',
    name: null,
    iso2: 'BB',
    hidden: false,
    countryCode: '1246',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Bangladesh',
    name: null,
    iso2: 'BD',
    hidden: false,
    countryCode: '880',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Belgium',
    name: null,
    iso2: 'BE',
    hidden: false,
    countryCode: '32',
    patterns: ['XXX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Burkina Faso',
    name: null,
    iso2: 'BF',
    hidden: false,
    countryCode: '226',
    patterns: ['XX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Bulgaria',
    name: null,
    iso2: 'BG',
    hidden: false,
    countryCode: '359',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Bahrain',
    name: null,
    iso2: 'BH',
    hidden: false,
    countryCode: '973',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Burundi',
    name: null,
    iso2: 'BI',
    hidden: false,
    countryCode: '257',
    patterns: ['XX XX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Benin',
    name: null,
    iso2: 'BJ',
    hidden: false,
    countryCode: '229',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Bermuda',
    name: null,
    iso2: 'BM',
    hidden: false,
    countryCode: '1441',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Brunei Darussalam',
    name: null,
    iso2: 'BN',
    hidden: false,
    countryCode: '673',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Bolivia',
    name: null,
    iso2: 'BO',
    hidden: false,
    countryCode: '591',
    patterns: ['X XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Bonaire, Sint Eustatius & Saba',
    name: null,
    iso2: 'BQ',
    hidden: false,
    countryCode: '599',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Brazil',
    name: null,
    iso2: 'BR',
    hidden: false,
    countryCode: '55',
    patterns: ['XX XXXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Bahamas',
    name: null,
    iso2: 'BS',
    hidden: false,
    countryCode: '1242',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Bhutan',
    name: null,
    iso2: 'BT',
    hidden: false,
    countryCode: '975',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Botswana',
    name: null,
    iso2: 'BW',
    hidden: false,
    countryCode: '267',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Belarus',
    name: null,
    iso2: 'BY',
    hidden: false,
    countryCode: '375',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Belize',
    name: null,
    iso2: 'BZ',
    hidden: false,
    countryCode: '501',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Canada',
    name: null,
    iso2: 'CA',
    hidden: false,
    countryCode: '1',
    patterns: ['XXX XXX XXXX'],
    prefixes: [
      '403',
      '587',
      '780',
      '825',
      '236',
      '250',
      '604',
      '672',
      '778',
      '204',
      '431',
      '506',
      '709',
      '902',
      '782',
      '226',
      '249',
      '289',
      '343',
      '365',
      '416',
      '437',
      '519',
      '548',
      '613',
      '647',
      '705',
      '807',
      '905',
      '418',
      '438',
      '450',
      '514',
      '579',
      '581',
      '819',
      '873',
      '306',
      '639',
      '867',
    ],
  },
  {
    defaultName: 'Congo (Dem. Rep.)',
    name: null,
    iso2: 'CD',
    hidden: false,
    countryCode: '243',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Central African Rep.',
    name: null,
    iso2: 'CF',
    hidden: false,
    countryCode: '236',
    patterns: ['XX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Congo (Rep.)',
    name: null,
    iso2: 'CG',
    hidden: false,
    countryCode: '242',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Switzerland',
    name: null,
    iso2: 'CH',
    hidden: false,
    countryCode: '41',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: "Côte d'Ivoire",
    name: null,
    iso2: 'CI',
    hidden: false,
    countryCode: '225',
    patterns: ['XX XX XX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Cook Islands',
    name: null,
    iso2: 'CK',
    hidden: false,
    countryCode: '682',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Chile',
    name: null,
    iso2: 'CL',
    hidden: false,
    countryCode: '56',
    patterns: ['X XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Cameroon',
    name: null,
    iso2: 'CM',
    hidden: false,
    countryCode: '237',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'China',
    name: null,
    iso2: 'CN',
    hidden: false,
    countryCode: '86',
    patterns: ['XXX XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Colombia',
    name: null,
    iso2: 'CO',
    hidden: false,
    countryCode: '57',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Costa Rica',
    name: null,
    iso2: 'CR',
    hidden: false,
    countryCode: '506',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Cuba',
    name: null,
    iso2: 'CU',
    hidden: false,
    countryCode: '53',
    patterns: ['X XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Cape Verde',
    name: null,
    iso2: 'CV',
    hidden: false,
    countryCode: '238',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Curaçao',
    name: null,
    iso2: 'CW',
    hidden: false,
    countryCode: '599',
    patterns: null,
    prefixes: ['9'],
  },
  {
    defaultName: 'Cyprus',
    name: null,
    iso2: 'CY',
    hidden: false,
    countryCode: '357',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Czech Republic',
    name: null,
    iso2: 'CZ',
    hidden: false,
    countryCode: '420',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Germany',
    name: null,
    iso2: 'DE',
    hidden: false,
    countryCode: '49',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Djibouti',
    name: null,
    iso2: 'DJ',
    hidden: false,
    countryCode: '253',
    patterns: ['XX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Denmark',
    name: null,
    iso2: 'DK',
    hidden: false,
    countryCode: '45',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Dominica',
    name: null,
    iso2: 'DM',
    hidden: false,
    countryCode: '1767',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Dominican Rep.',
    name: null,
    iso2: 'DO',
    hidden: false,
    countryCode: '1809',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Algeria',
    name: null,
    iso2: 'DZ',
    hidden: false,
    countryCode: '213',
    patterns: ['XXX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Ecuador',
    name: null,
    iso2: 'EC',
    hidden: false,
    countryCode: '593',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Estonia',
    name: null,
    iso2: 'EE',
    hidden: false,
    countryCode: '372',
    patterns: ['XXXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Egypt',
    name: null,
    iso2: 'EG',
    hidden: false,
    countryCode: '20',
    patterns: ['XX XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Eritrea',
    name: null,
    iso2: 'ER',
    hidden: false,
    countryCode: '291',
    patterns: ['X XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Spain',
    name: null,
    iso2: 'ES',
    hidden: false,
    countryCode: '34',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Ethiopia',
    name: null,
    iso2: 'ET',
    hidden: false,
    countryCode: '251',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Finland',
    name: null,
    iso2: 'FI',
    hidden: false,
    countryCode: '358',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Fiji',
    name: null,
    iso2: 'FJ',
    hidden: false,
    countryCode: '679',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Falkland Islands',
    name: null,
    iso2: 'FK',
    hidden: false,
    countryCode: '500',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Micronesia',
    name: null,
    iso2: 'FM',
    hidden: false,
    countryCode: '691',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Faroe Islands',
    name: null,
    iso2: 'FO',
    hidden: false,
    countryCode: '298',
    patterns: ['XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'France',
    name: null,
    iso2: 'FR',
    hidden: false,
    countryCode: '33',
    patterns: ['X XX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Anonymous Numbers',
    name: null,
    iso2: 'FT',
    hidden: false,
    countryCode: '888',
    patterns: ['8 XXX', 'XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Gabon',
    name: null,
    iso2: 'GA',
    hidden: false,
    countryCode: '241',
    patterns: ['X XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'United Kingdom',
    name: null,
    iso2: 'GB',
    hidden: false,
    countryCode: '44',
    patterns: ['XXXX XXXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Grenada',
    name: null,
    iso2: 'GD',
    hidden: false,
    countryCode: '1473',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Georgia',
    name: null,
    iso2: 'GE',
    hidden: false,
    countryCode: '995',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'French Guiana',
    name: null,
    iso2: 'GF',
    hidden: false,
    countryCode: '594',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Ghana',
    name: null,
    iso2: 'GH',
    hidden: false,
    countryCode: '233',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Gibraltar',
    name: null,
    iso2: 'GI',
    hidden: false,
    countryCode: '350',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Greenland',
    name: null,
    iso2: 'GL',
    hidden: false,
    countryCode: '299',
    patterns: ['XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Gambia',
    name: null,
    iso2: 'GM',
    hidden: false,
    countryCode: '220',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Guinea',
    name: null,
    iso2: 'GN',
    hidden: false,
    countryCode: '224',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Guadeloupe',
    name: null,
    iso2: 'GP',
    hidden: false,
    countryCode: '590',
    patterns: ['XXX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Equatorial Guinea',
    name: null,
    iso2: 'GQ',
    hidden: false,
    countryCode: '240',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Greece',
    name: null,
    iso2: 'GR',
    hidden: false,
    countryCode: '30',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Guatemala',
    name: null,
    iso2: 'GT',
    hidden: false,
    countryCode: '502',
    patterns: ['X XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Guam',
    name: null,
    iso2: 'GU',
    hidden: false,
    countryCode: '1671',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Guinea-Bissau',
    name: null,
    iso2: 'GW',
    hidden: false,
    countryCode: '245',
    patterns: ['XXX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Guyana',
    name: null,
    iso2: 'GY',
    hidden: false,
    countryCode: '592',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Hong Kong',
    name: null,
    iso2: 'HK',
    hidden: false,
    countryCode: '852',
    patterns: ['X XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Honduras',
    name: null,
    iso2: 'HN',
    hidden: false,
    countryCode: '504',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Croatia',
    name: null,
    iso2: 'HR',
    hidden: false,
    countryCode: '385',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Haiti',
    name: null,
    iso2: 'HT',
    hidden: false,
    countryCode: '509',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Hungary',
    name: null,
    iso2: 'HU',
    hidden: false,
    countryCode: '36',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Indonesia',
    name: null,
    iso2: 'ID',
    hidden: false,
    countryCode: '62',
    patterns: ['XXX XXXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Ireland',
    name: null,
    iso2: 'IE',
    hidden: false,
    countryCode: '353',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Israel',
    name: null,
    iso2: 'IL',
    hidden: false,
    countryCode: '972',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'India',
    name: null,
    iso2: 'IN',
    hidden: false,
    countryCode: '91',
    patterns: ['XXXXX XXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Diego Garcia',
    name: null,
    iso2: 'IO',
    hidden: false,
    countryCode: '246',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Iraq',
    name: null,
    iso2: 'IQ',
    hidden: false,
    countryCode: '964',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Iran',
    name: null,
    iso2: 'IR',
    hidden: false,
    countryCode: '98',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Iceland',
    name: null,
    iso2: 'IS',
    hidden: false,
    countryCode: '354',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Italy',
    name: null,
    iso2: 'IT',
    hidden: false,
    countryCode: '39',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Jamaica',
    name: null,
    iso2: 'JM',
    hidden: false,
    countryCode: '1876',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Jordan',
    name: null,
    iso2: 'JO',
    hidden: false,
    countryCode: '962',
    patterns: ['X XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Japan',
    name: null,
    iso2: 'JP',
    hidden: false,
    countryCode: '81',
    patterns: ['XX XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Kenya',
    name: null,
    iso2: 'KE',
    hidden: false,
    countryCode: '254',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Kyrgyzstan',
    name: null,
    iso2: 'KG',
    hidden: false,
    countryCode: '996',
    patterns: ['XXX XXXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Cambodia',
    name: null,
    iso2: 'KH',
    hidden: false,
    countryCode: '855',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Kiribati',
    name: null,
    iso2: 'KI',
    hidden: false,
    countryCode: '686',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Comoros',
    name: null,
    iso2: 'KM',
    hidden: false,
    countryCode: '269',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Saint Kitts & Nevis',
    name: null,
    iso2: 'KN',
    hidden: false,
    countryCode: '1869',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'North Korea',
    name: null,
    iso2: 'KP',
    hidden: false,
    countryCode: '850',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'South Korea',
    name: null,
    iso2: 'KR',
    hidden: false,
    countryCode: '82',
    patterns: ['XX XXXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Kuwait',
    name: null,
    iso2: 'KW',
    hidden: false,
    countryCode: '965',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Cayman Islands',
    name: null,
    iso2: 'KY',
    hidden: false,
    countryCode: '1345',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Kazakhstan',
    name: null,
    iso2: 'KZ',
    hidden: false,
    countryCode: '7',
    patterns: ['XXX XXX XX XX'],
    prefixes: ['6', '7'],
  },
  {
    defaultName: 'Laos',
    name: null,
    iso2: 'LA',
    hidden: false,
    countryCode: '856',
    patterns: ['XX XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Lebanon',
    name: null,
    iso2: 'LB',
    hidden: false,
    countryCode: '961',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Saint Lucia',
    name: null,
    iso2: 'LC',
    hidden: false,
    countryCode: '1758',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Liechtenstein',
    name: null,
    iso2: 'LI',
    hidden: false,
    countryCode: '423',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Sri Lanka',
    name: null,
    iso2: 'LK',
    hidden: false,
    countryCode: '94',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Liberia',
    name: null,
    iso2: 'LR',
    hidden: false,
    countryCode: '231',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Lesotho',
    name: null,
    iso2: 'LS',
    hidden: false,
    countryCode: '266',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Lithuania',
    name: null,
    iso2: 'LT',
    hidden: false,
    countryCode: '370',
    patterns: ['XXX XXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Luxembourg',
    name: null,
    iso2: 'LU',
    hidden: false,
    countryCode: '352',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Latvia',
    name: null,
    iso2: 'LV',
    hidden: false,
    countryCode: '371',
    patterns: ['XXX XXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Libya',
    name: null,
    iso2: 'LY',
    hidden: false,
    countryCode: '218',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Morocco',
    name: null,
    iso2: 'MA',
    hidden: false,
    countryCode: '212',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Monaco',
    name: null,
    iso2: 'MC',
    hidden: false,
    countryCode: '377',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Moldova',
    name: null,
    iso2: 'MD',
    hidden: false,
    countryCode: '373',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Montenegro',
    name: null,
    iso2: 'ME',
    hidden: false,
    countryCode: '382',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Madagascar',
    name: null,
    iso2: 'MG',
    hidden: false,
    countryCode: '261',
    patterns: ['XX XX XXX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Marshall Islands',
    name: null,
    iso2: 'MH',
    hidden: false,
    countryCode: '692',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'North Macedonia',
    name: null,
    iso2: 'MK',
    hidden: false,
    countryCode: '389',
    patterns: ['XX XXXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Mali',
    name: null,
    iso2: 'ML',
    hidden: false,
    countryCode: '223',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Myanmar',
    name: null,
    iso2: 'MM',
    hidden: false,
    countryCode: '95',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Mongolia',
    name: null,
    iso2: 'MN',
    hidden: false,
    countryCode: '976',
    patterns: ['XX XX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Macau',
    name: null,
    iso2: 'MO',
    hidden: false,
    countryCode: '853',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Northern Mariana Islands',
    name: null,
    iso2: 'MP',
    hidden: false,
    countryCode: '1670',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Martinique',
    name: null,
    iso2: 'MQ',
    hidden: false,
    countryCode: '596',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Mauritania',
    name: null,
    iso2: 'MR',
    hidden: false,
    countryCode: '222',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Montserrat',
    name: null,
    iso2: 'MS',
    hidden: false,
    countryCode: '1664',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Malta',
    name: null,
    iso2: 'MT',
    hidden: false,
    countryCode: '356',
    patterns: ['XX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Mauritius',
    name: null,
    iso2: 'MU',
    hidden: false,
    countryCode: '230',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Maldives',
    name: null,
    iso2: 'MV',
    hidden: false,
    countryCode: '960',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Malawi',
    name: null,
    iso2: 'MW',
    hidden: false,
    countryCode: '265',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Mexico',
    name: null,
    iso2: 'MX',
    hidden: false,
    countryCode: '52',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Malaysia',
    name: null,
    iso2: 'MY',
    hidden: false,
    countryCode: '60',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Mozambique',
    name: null,
    iso2: 'MZ',
    hidden: false,
    countryCode: '258',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Namibia',
    name: null,
    iso2: 'NA',
    hidden: false,
    countryCode: '264',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'New Caledonia',
    name: null,
    iso2: 'NC',
    hidden: false,
    countryCode: '687',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Niger',
    name: null,
    iso2: 'NE',
    hidden: false,
    countryCode: '227',
    patterns: ['XX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Norfolk Island',
    name: null,
    iso2: 'NF',
    hidden: false,
    countryCode: '672',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Nigeria',
    name: null,
    iso2: 'NG',
    hidden: false,
    countryCode: '234',
    patterns: ['XX XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Nicaragua',
    name: null,
    iso2: 'NI',
    hidden: false,
    countryCode: '505',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Netherlands',
    name: null,
    iso2: 'NL',
    hidden: false,
    countryCode: '31',
    patterns: ['X XX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Norway',
    name: null,
    iso2: 'NO',
    hidden: false,
    countryCode: '47',
    patterns: ['XXX XX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Nepal',
    name: null,
    iso2: 'NP',
    hidden: false,
    countryCode: '977',
    patterns: ['XX XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Nauru',
    name: null,
    iso2: 'NR',
    hidden: false,
    countryCode: '674',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Niue',
    name: null,
    iso2: 'NU',
    hidden: false,
    countryCode: '683',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'New Zealand',
    name: null,
    iso2: 'NZ',
    hidden: false,
    countryCode: '64',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Oman',
    name: null,
    iso2: 'OM',
    hidden: false,
    countryCode: '968',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Panama',
    name: null,
    iso2: 'PA',
    hidden: false,
    countryCode: '507',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Peru',
    name: null,
    iso2: 'PE',
    hidden: false,
    countryCode: '51',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'French Polynesia',
    name: null,
    iso2: 'PF',
    hidden: false,
    countryCode: '689',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Papua New Guinea',
    name: null,
    iso2: 'PG',
    hidden: false,
    countryCode: '675',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Philippines',
    name: null,
    iso2: 'PH',
    hidden: false,
    countryCode: '63',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Pakistan',
    name: null,
    iso2: 'PK',
    hidden: false,
    countryCode: '92',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Poland',
    name: null,
    iso2: 'PL',
    hidden: false,
    countryCode: '48',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Saint Pierre & Miquelon',
    name: null,
    iso2: 'PM',
    hidden: false,
    countryCode: '508',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Puerto Rico',
    name: null,
    iso2: 'PR',
    hidden: false,
    countryCode: '1787',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Palestine',
    name: null,
    iso2: 'PS',
    hidden: false,
    countryCode: '970',
    patterns: ['XXX XX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Portugal',
    name: null,
    iso2: 'PT',
    hidden: false,
    countryCode: '351',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Palau',
    name: null,
    iso2: 'PW',
    hidden: false,
    countryCode: '680',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Paraguay',
    name: null,
    iso2: 'PY',
    hidden: false,
    countryCode: '595',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Qatar',
    name: null,
    iso2: 'QA',
    hidden: false,
    countryCode: '974',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Réunion',
    name: null,
    iso2: 'RE',
    hidden: false,
    countryCode: '262',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Romania',
    name: null,
    iso2: 'RO',
    hidden: false,
    countryCode: '40',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Serbia',
    name: null,
    iso2: 'RS',
    hidden: false,
    countryCode: '381',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Russian Federation',
    name: null,
    iso2: 'RU',
    hidden: false,
    countryCode: '7',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Rwanda',
    name: null,
    iso2: 'RW',
    hidden: false,
    countryCode: '250',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Saudi Arabia',
    name: null,
    iso2: 'SA',
    hidden: false,
    countryCode: '966',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Solomon Islands',
    name: null,
    iso2: 'SB',
    hidden: false,
    countryCode: '677',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Seychelles',
    name: null,
    iso2: 'SC',
    hidden: false,
    countryCode: '248',
    patterns: ['X XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Sudan',
    name: null,
    iso2: 'SD',
    hidden: false,
    countryCode: '249',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Sweden',
    name: null,
    iso2: 'SE',
    hidden: false,
    countryCode: '46',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Singapore',
    name: null,
    iso2: 'SG',
    hidden: false,
    countryCode: '65',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Saint Helena',
    name: null,
    iso2: 'SH',
    hidden: false,
    countryCode: '247',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Slovenia',
    name: null,
    iso2: 'SI',
    hidden: false,
    countryCode: '386',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Slovakia',
    name: null,
    iso2: 'SK',
    hidden: false,
    countryCode: '421',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Sierra Leone',
    name: null,
    iso2: 'SL',
    hidden: false,
    countryCode: '232',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'San Marino',
    name: null,
    iso2: 'SM',
    hidden: false,
    countryCode: '378',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Senegal',
    name: null,
    iso2: 'SN',
    hidden: false,
    countryCode: '221',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Somalia',
    name: null,
    iso2: 'SO',
    hidden: false,
    countryCode: '252',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Suriname',
    name: null,
    iso2: 'SR',
    hidden: false,
    countryCode: '597',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'South Sudan',
    name: null,
    iso2: 'SS',
    hidden: false,
    countryCode: '211',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'São Tomé & Príncipe',
    name: null,
    iso2: 'ST',
    hidden: false,
    countryCode: '239',
    patterns: ['XX XXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'El Salvador',
    name: null,
    iso2: 'SV',
    hidden: false,
    countryCode: '503',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Sint Maarten',
    name: null,
    iso2: 'SX',
    hidden: false,
    countryCode: '1721',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Syria',
    name: null,
    iso2: 'SY',
    hidden: false,
    countryCode: '963',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Eswatini',
    name: null,
    iso2: 'SZ',
    hidden: false,
    countryCode: '268',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Turks & Caicos Islands',
    name: null,
    iso2: 'TC',
    hidden: false,
    countryCode: '1649',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Chad',
    name: null,
    iso2: 'TD',
    hidden: false,
    countryCode: '235',
    patterns: ['XX XX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Togo',
    name: null,
    iso2: 'TG',
    hidden: false,
    countryCode: '228',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Thailand',
    name: null,
    iso2: 'TH',
    hidden: false,
    countryCode: '66',
    patterns: ['X XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Tajikistan',
    name: null,
    iso2: 'TJ',
    hidden: false,
    countryCode: '992',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Tokelau',
    name: null,
    iso2: 'TK',
    hidden: false,
    countryCode: '690',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Timor-Leste',
    name: null,
    iso2: 'TL',
    hidden: false,
    countryCode: '670',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Turkmenistan',
    name: null,
    iso2: 'TM',
    hidden: false,
    countryCode: '993',
    patterns: ['XX XXXXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Tunisia',
    name: null,
    iso2: 'TN',
    hidden: false,
    countryCode: '216',
    patterns: ['XX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Tonga',
    name: null,
    iso2: 'TO',
    hidden: false,
    countryCode: '676',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Turkey',
    name: null,
    iso2: 'TR',
    hidden: false,
    countryCode: '90',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Trinidad & Tobago',
    name: null,
    iso2: 'TT',
    hidden: false,
    countryCode: '1868',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Tuvalu',
    name: null,
    iso2: 'TV',
    hidden: false,
    countryCode: '688',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Taiwan',
    name: null,
    iso2: 'TW',
    hidden: false,
    countryCode: '886',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Tanzania',
    name: null,
    iso2: 'TZ',
    hidden: false,
    countryCode: '255',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Ukraine',
    name: null,
    iso2: 'UA',
    hidden: false,
    countryCode: '380',
    patterns: ['XX XXX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Uganda',
    name: null,
    iso2: 'UG',
    hidden: false,
    countryCode: '256',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'USA',
    name: null,
    iso2: 'US',
    hidden: false,
    countryCode: '1',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Uruguay',
    name: null,
    iso2: 'UY',
    hidden: false,
    countryCode: '598',
    patterns: ['X XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Uzbekistan',
    name: null,
    iso2: 'UZ',
    hidden: false,
    countryCode: '998',
    patterns: ['XX XXX XX XX'],
    prefixes: null,
  },
  {
    defaultName: 'Saint Vincent & the Grenadines',
    name: null,
    iso2: 'VC',
    hidden: false,
    countryCode: '1784',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Venezuela',
    name: null,
    iso2: 'VE',
    hidden: false,
    countryCode: '58',
    patterns: ['XXX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'British Virgin Islands',
    name: null,
    iso2: 'VG',
    hidden: false,
    countryCode: '1284',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'US Virgin Islands',
    name: null,
    iso2: 'VI',
    hidden: false,
    countryCode: '1340',
    patterns: ['XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Vietnam',
    name: null,
    iso2: 'VN',
    hidden: false,
    countryCode: '84',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Vanuatu',
    name: null,
    iso2: 'VU',
    hidden: false,
    countryCode: '678',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Wallis & Futuna',
    name: null,
    iso2: 'WF',
    hidden: false,
    countryCode: '681',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Samoa',
    name: null,
    iso2: 'WS',
    hidden: false,
    countryCode: '685',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Global Mobile Satellite System',
    name: null,
    iso2: 'XG',
    hidden: true,
    countryCode: '881',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Kosovo',
    name: null,
    iso2: 'XK',
    hidden: false,
    countryCode: '383',
    patterns: ['XXXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'International Networks',
    name: null,
    iso2: 'XV',
    hidden: true,
    countryCode: '882',
    patterns: null,
    prefixes: null,
  },
  {
    defaultName: 'Yemen',
    name: null,
    iso2: 'YE',
    hidden: false,
    countryCode: '967',
    patterns: ['XXX XXX XXX'],
    prefixes: null,
  },
  {
    defaultName: 'Y-land',
    name: null,
    iso2: 'YL',
    hidden: true,
    countryCode: '42',
    patterns: null,
    prefixes: ['4', '7'],
  },
  {
    defaultName: 'South Africa',
    name: null,
    iso2: 'ZA',
    hidden: false,
    countryCode: '27',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Zambia',
    name: null,
    iso2: 'ZM',
    hidden: false,
    countryCode: '260',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
  {
    defaultName: 'Zimbabwe',
    name: null,
    iso2: 'ZW',
    hidden: false,
    countryCode: '263',
    patterns: ['XX XXX XXXX'],
    prefixes: null,
  },
]
