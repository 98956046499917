import {
  ComAtprotoServerBindWalletAddress,
  ComAtprotoServerSetPaymentWallet,
} from '@atproto/api'
import {BindWalletAddress} from '@atproto/api/dist/client/types/com/atproto/server/defs'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {BetListType} from '#/view/screens/BetList'
import {useAgent, useSession} from '../session'

export const RQKEY_ROOT = 'linkWallet'
export const RQKEY = () => [RQKEY_ROOT]
export function useLinkedWalletsQuery() {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<BindWalletAddress[], Error>({
    // refetchOnWindowFocus: true,
    queryKey: [RQKEY_ROOT],
    queryFn: async () => {
      const res = await agent.com.atproto.server.bindWalletAddressList()
      return res.data.data
    },
    enabled: !!currentAccount,
  })
}

export function usePayWalletQuery(did: string, dataType?: BetListType) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<string, Error>({
    // refetchOnWindowFocus: true,
    queryKey: [RQKEY_ROOT, did],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getPaymentWalletAddress({did})
      return res.data.walletAddress
    },
    enabled: !!did && (!dataType || dataType === 'donate') && !!currentAccount,
  })
}

export function useWalletLinkMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation<
    ComAtprotoServerBindWalletAddress.Response,
    Error,
    {param: ComAtprotoServerBindWalletAddress.InputSchema}
  >({
    mutationFn: async ({param}) => {
      return await agent.com.atproto.server.bindWalletAddress(param)
    },
    onSuccess() {
      queryClient.invalidateQueries({queryKey: RQKEY()})
    },
  })
}

export function usePaymentMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation<
    ComAtprotoServerSetPaymentWallet.Response,
    Error,
    {walletAddress: string}
  >({
    mutationFn: ({walletAddress}) => {
      return agent.com.atproto.server.setPaymentWallet({
        walletAddress,
      })
    },
    onSuccess() {
      queryClient.invalidateQueries({queryKey: RQKEY()})
      // track('Post:Repost')
    },
  })
}
