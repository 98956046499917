import React from 'react'
import {View} from 'react-native'
import {Trans} from '@lingui/macro'

import {useTwitter} from '#/lib/hooks/useSocial'
import {useModalControls} from '#/state/modals'
import {usePalette} from 'lib/hooks/usePalette'
import {Button} from '#/components/Button'
import {Loader} from '#/components/Loader'
import {Text} from '../com/util/text/Text'

export const AuthorizeScreen = () => {
  const pal = usePalette('default')
  const {openModal} = useModalControls()
  const {bindTwitter} = useTwitter()

  React.useEffect(() => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    const state = url.searchParams.get('state')
    if (state === 'task_x_bind' && code) {
      bindTwitter(code).then(res => {
        localStorage.setItem('task_x_bind', JSON.stringify(res))
      })
      // 测试时可以先注释此行
      window.close()
      return
    }
  }, [bindTwitter])

  return (
    <View
      testID="authorizeView"
      style={{
        ...pal.view,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}>
      <Loader />
      <Text>Loading...</Text>
      {/* 此处添加授权 loading 效果代码 */}
      <Button
        color="primary"
        variant="solid"
        size="large"
        label="Link Social"
        onPress={() => {
          openModal({
            name: 'link-social',
          })
        }}>
        <Text>
          <Trans>Link Social</Trans>
        </Text>
      </Button>
    </View>
  )
}
