import React, {useCallback, useMemo} from 'react'
import {StyleSheet} from 'react-native'
import {
  AppBskyActorDefs,
  AppBskyGraphGetActorStarterPacks,
  moderateProfile,
  ModerationOpts,
  RichText as RichTextAPI,
} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useFocusEffect} from '@react-navigation/native'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useQueryClient,
} from '@tanstack/react-query'

import {useIsMe} from '#/lib/hooks/useIsMe'
import {cleanError} from '#/lib/strings/errors'
import {useProfileShadow} from '#/state/cache/profile-shadow'
import {useModerationOpts} from '#/state/preferences/moderation-opts'
import {useLabelerInfoQuery} from '#/state/queries/labeler'
import {resetProfilePostsQueries} from '#/state/queries/post-feed'
import {useProfileQuery} from '#/state/queries/profile'
import {useResolveDidQuery} from '#/state/queries/resolve-uri'
import {useAgent, useSession} from '#/state/session'
import {useSetDrawerSwipeDisabled, useSetMinimalShellMode} from '#/state/shell'
import {useAnalytics} from 'lib/analytics/analytics'
import {useSetTitle} from 'lib/hooks/useSetTitle'
import {CommonNavigatorParams, NativeStackScreenProps} from 'lib/routes/types'
import {combinedDisplayName} from 'lib/strings/display-names'
import {colors} from 'lib/styles'
import {listenSoftReset} from 'state/events'
import {useActorStarterPacksQuery} from 'state/queries/actor-starter-packs'
import {PagerWithHeader} from 'view/com/pager/PagerWithHeader'
import {ProfileHeader, ProfileHeaderLoading} from '#/screens/Profile/Header'
import {ProfileFeedSection} from '#/screens/Profile/Sections/Feed'
// import {ProfileLabelsSection} from '#/screens/Profile/Sections/Labels'
import {ScreenHider} from '#/components/moderation/ScreenHider'
// import {ProfileStarterPacks} from '#/components/StarterPack/ProfileStarterPacks'
import {ExpoScrollForwarderView} from '../../../modules/expo-scroll-forwarder'
// import {LoggedOut} from '../com/auth/LoggedOut'
import {PagerRef} from '../com/pager/Pager'
// import {ProfileFeedgens} from '../com/feeds/ProfileFeedgens'
// import {ProfileLists} from '../com/lists/ProfileLists'
import {ErrorScreen} from '../com/util/error/ErrorScreen'
import {ListRef} from '../com/util/List'
import {useProfileStore} from '../com/util/sdlStore/ProfileStore'
import {CenteredView} from '../com/util/Views'

interface SectionRef {
  scrollToTop: () => void
}

type Props = NativeStackScreenProps<CommonNavigatorParams, 'Profile'>
export function ProfileScreen({route}: Props) {
  const {_} = useLingui()
  const {currentAccount} = useSession()
  const queryClient = useQueryClient()
  const name =
    route.params.name === 'me' ? currentAccount?.did : route.params.name
  const moderationOpts = useModerationOpts()
  const {
    data: resolvedDid,
    error: resolveError,
    refetch: refetchDid,
    isLoading: isLoadingDid,
  } = useResolveDidQuery(name)
  const {
    data: profile,
    error: profileError,
    refetch: refetchProfile,
    isLoading: isLoadingProfile,
    isPlaceholderData: isPlaceholderProfile,
  } = useProfileQuery({
    did: resolvedDid,
  })
  const starterPacksQuery = useActorStarterPacksQuery({did: resolvedDid})

  const onPressTryAgain = React.useCallback(() => {
    if (resolveError) {
      refetchDid()
    } else {
      refetchProfile()
    }
  }, [resolveError, refetchDid, refetchProfile])

  // When we open the profile, we want to reset the posts query if we are blocked.
  React.useEffect(() => {
    if (resolvedDid && profile?.viewer?.blockedBy) {
      resetProfilePostsQueries(queryClient, resolvedDid)
    }
  }, [queryClient, profile?.viewer?.blockedBy, resolvedDid])

  // if (!currentAccount?.did) {
  //   return <LoggedOut />
  // }
  // Most pushes will happen here, since we will have only placeholder data
  if (isLoadingDid || isLoadingProfile || starterPacksQuery.isLoading) {
    return (
      <CenteredView>
        <ProfileHeaderLoading />
      </CenteredView>
    )
  }
  if (resolveError || profileError) {
    return (
      <ErrorScreen
        testID="profileErrorScreen"
        title={profileError ? _(msg`Not Found`) : _(msg`Oops!`)}
        message={cleanError(resolveError || profileError)}
        onPressTryAgain={onPressTryAgain}
        showHeader
      />
    )
  }
  if (profile && moderationOpts) {
    return (
      <ProfileScreenLoaded
        profile={profile}
        starterPacksQuery={starterPacksQuery}
        moderationOpts={moderationOpts}
        isPlaceholderProfile={isPlaceholderProfile}
        hideBackButton={!!route.params.hideBackButton}
      />
    )
  }
  // should never happen
  return (
    <ErrorScreen
      testID="profileErrorScreen"
      title="Oops!"
      message="Something went wrong and we're not sure what."
      onPressTryAgain={onPressTryAgain}
      showHeader
    />
  )
}

function ProfileScreenLoaded({
  profile: profileUnshadowed,
  isPlaceholderProfile,
  moderationOpts,
  hideBackButton,
}: // starterPacksQuery,
{
  profile: AppBskyActorDefs.ProfileViewDetailed
  moderationOpts: ModerationOpts
  hideBackButton: boolean
  isPlaceholderProfile: boolean
  starterPacksQuery: UseInfiniteQueryResult<
    InfiniteData<AppBskyGraphGetActorStarterPacks.OutputSchema, unknown>,
    Error
  >
}) {
  const profile = useProfileShadow(profileUnshadowed)
  // const {currentAccount} = useSession()
  const setMinimalShellMode = useSetMinimalShellMode()
  const {screen} = useAnalytics()
  const {
    data: labelerInfo,
    // error: labelerError,
    // isLoading: isLabelerLoading,
  } = useLabelerInfoQuery({
    did: profile.did,
    enabled: !!profile.associated?.labeler,
  })
  const [currentPage, setCurrentPage] = React.useState(0)
  const {_} = useLingui()
  const setDrawerSwipeDisabled = useSetDrawerSwipeDisabled()

  const [scrollViewTag, setScrollViewTag] = React.useState<number | null>(null)

  const dissSectionRef = React.useRef<SectionRef>(null),
    bookmarksSectionRef = React.useRef<SectionRef>(null),
    engagementsSectionRef = React.useRef<SectionRef>(null)

  useSetTitle(combinedDisplayName(profile))

  const description = profile.description ?? ''
  const hasDescription = description !== ''
  const [descriptionRT, isResolvingDescriptionRT] = useRichText(description)
  const showPlaceholder = isPlaceholderProfile || isResolvingDescriptionRT
  const moderation = useMemo(
    () => moderateProfile(profile, moderationOpts),
    [profile, moderationOpts],
  )

  const isMe = useIsMe(profile.did)

  const showDissTab = true,
    showBookmarks = isMe,
    showEngagements = isMe

  const sectionTitles = [
    showDissTab ? _(msg`Tea spill`) : undefined,
    showBookmarks ? _(msg`Bookmarks`) : undefined,
    showEngagements ? _(msg`Teabox`) : undefined,
  ].filter(Boolean) as string[]

  let nextIndex = 0
  let dissIndex: number | null = null
  let bookmarksIndex: number | null = null
  let engagementsIndex: number | null = null
  if (showDissTab) {
    dissIndex = nextIndex++
  }
  if (showBookmarks) {
    bookmarksIndex = nextIndex++
  }
  if (showEngagements) {
    engagementsIndex = nextIndex++
  }

  const scrollSectionToTop = useCallback(
    (index: number) => {
      if (index === dissIndex) {
        dissSectionRef.current?.scrollToTop()
      } else if (index === bookmarksIndex) {
        bookmarksSectionRef.current?.scrollToTop()
      } else if (index === engagementsIndex) {
        engagementsSectionRef.current?.scrollToTop()
      }
    },
    [dissIndex, bookmarksIndex, engagementsIndex],
  )

  useFocusEffect(
    React.useCallback(() => {
      setMinimalShellMode(false)
      screen('Profile')
      return listenSoftReset(() => {
        scrollSectionToTop(currentPage)
      })
    }, [setMinimalShellMode, screen, currentPage, scrollSectionToTop]),
  )

  useFocusEffect(
    React.useCallback(() => {
      setDrawerSwipeDisabled(currentPage > 0)
      return () => {
        setDrawerSwipeDisabled(false)
      }
    }, [setDrawerSwipeDisabled, currentPage]),
  )

  // events
  // =

  const onPageSelected = (i: number) => {
    setCurrentPage(i)
  }

  const onCurrentPageSelected = (index: number) => {
    scrollSectionToTop(index)
  }

  // rendering
  // =

  const renderHeader = () => {
    return (
      <ExpoScrollForwarderView scrollViewTag={scrollViewTag}>
        <ProfileHeader
          profile={profile}
          labeler={labelerInfo}
          descriptionRT={hasDescription ? descriptionRT : null}
          moderationOpts={moderationOpts}
          hideBackButton={hideBackButton}
          isPlaceholderProfile={showPlaceholder}
        />
      </ExpoScrollForwarderView>
    )
  }

  const fun = (tbName: string) => {
    const index = sectionTitles.findIndex(t => t === tbName)
    if (index !== -1) {
      pagerRef.current?.setPage(index, 'desktop-sidebar-click')
    }
  }

  const {
    profileStore: {tabName},
    setTabName,
  } = useProfileStore()

  useFocusEffect(
    useCallback(() => {
      if (tabName) {
        setTimeout(() => {
          fun(tabName)
        })
      }
      return () => {
        pagerRef.current?.setPage(0, 'desktop-sidebar-click')
        setTabName('')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabName]),
  )

  const pagerRef = React.useRef<PagerRef>(null)

  return (
    <ScreenHider
      testID="profileView"
      style={styles.container}
      screenDescription={_(msg`profile`)}
      modui={moderation.ui('profileView')}>
      <PagerWithHeader
        testID="profilePager"
        isHeaderReady={!showPlaceholder}
        items={sectionTitles}
        onPageSelected={onPageSelected}
        onCurrentPageSelected={onCurrentPageSelected}
        ref={pagerRef}
        renderHeader={renderHeader}>
        {showDissTab
          ? ({headerHeight, isFocused, scrollElRef}) => (
              <ProfileFeedSection
                ref={dissSectionRef}
                isMe={isMe}
                feed={`author|${profile.did}|posts_and_author_threads`}
                headerHeight={headerHeight}
                isFocused={isFocused}
                scrollElRef={scrollElRef as ListRef}
                ignoreFilterFor={profile.did}
                setScrollViewTag={setScrollViewTag}
              />
            )
          : null}
        {showBookmarks
          ? ({headerHeight, isFocused, scrollElRef}) => (
              <ProfileFeedSection
                ref={bookmarksSectionRef}
                isMe={isMe}
                feed={`likes|${profile.did}`}
                headerHeight={headerHeight}
                isFocused={isFocused}
                scrollElRef={scrollElRef as ListRef}
                ignoreFilterFor={profile.did}
                setScrollViewTag={setScrollViewTag}
              />
            )
          : null}
        {showEngagements
          ? ({headerHeight, isFocused, scrollElRef}) => (
              <ProfileFeedSection
                ref={engagementsSectionRef}
                isMe={isMe}
                feed={`engagement|${profile.did}`}
                headerHeight={headerHeight}
                isFocused={isFocused}
                scrollElRef={scrollElRef as ListRef}
                ignoreFilterFor={profile.did}
                setScrollViewTag={setScrollViewTag}
              />
            )
          : null}
      </PagerWithHeader>
    </ScreenHider>
  )
}

function useRichText(text: string): [RichTextAPI, boolean] {
  const agent = useAgent()
  const [prevText, setPrevText] = React.useState(text)
  const [rawRT, setRawRT] = React.useState(() => new RichTextAPI({text}))
  const [resolvedRT, setResolvedRT] = React.useState<RichTextAPI | null>(null)
  if (text !== prevText) {
    setPrevText(text)
    setRawRT(new RichTextAPI({text}))
    setResolvedRT(null)
    // This will queue an immediate re-render
  }
  React.useEffect(() => {
    let ignore = false
    async function resolveRTFacets() {
      // new each time
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const resolvedRT = new RichTextAPI({text})
      await resolvedRT.detectFacets(agent)
      if (!ignore) {
        setResolvedRT(resolvedRT)
      }
    }
    resolveRTFacets()
    return () => {
      ignore = true
    }
  }, [text, agent])
  const isResolving = resolvedRT === null
  return [resolvedRT ?? rawRT, isResolving]
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    // @ts-ignore Web-only.
    overflowAnchor: 'none', // Fixes jumps when switching tabs while scrolled down.
  },
  loading: {
    paddingVertical: 10,
    paddingHorizontal: 14,
  },
  emptyState: {
    paddingVertical: 40,
  },
  loadingMoreFooter: {
    paddingVertical: 20,
  },
  endItem: {
    paddingTop: 20,
    paddingBottom: 30,
    color: colors.gray5,
    textAlign: 'center',
  },
})
