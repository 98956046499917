import React, {useEffect, useState} from 'react'
import {ComAtprotoServerTelegramBind} from '@atproto/api'

import {logger} from '#/logger'
import {useAgent, useSession} from '#/state/session'
import {getContacts} from '#/view/com/modals/TelegramModal/util'
import * as telegram from '../../../../../web/telegram'
import {apiHash, apiId, TELEGRAM_SESSION_KEY} from './config'
import {countries as list} from './countries'
import {ApiCountryCode} from './types'

export function useTelegramClient() {
  const [client, setClient] = useState<any>(null)

  useEffect(() => {
    const init = async () => {
      console.log(telegram)
      const {TelegramClient, sessions} = telegram
      const session = new sessions.StringSession(
        localStorage.getItem(TELEGRAM_SESSION_KEY) || '',
      )
      const _client = new TelegramClient(session, apiId, apiHash, {
        connectionRetries: 5,
      })
      setClient(_client)
    }
    init()
  }, [])

  const resetClient = (token?: string) => {
    const {TelegramClient, sessions} = telegram
    if (token) {
      localStorage.setItem(TELEGRAM_SESSION_KEY, token)
    }
    const session = new sessions.StringSession(
      localStorage.getItem(TELEGRAM_SESSION_KEY) || '',
    )
    const _client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    })
    setClient(_client)
    return _client
  }

  return {client, resetClient}
}

export function useTelegramSupportCountries(client: any) {
  // const {Api} = telegram.tl

  const [countries] = useState<ApiCountryCode[]>(list)

  const getCountries = async () => {
    // await client.connect()
    // const res = await client.invoke(
    //   new Api.help.GetCountriesList({
    //     langCode: 'en',
    //     hash: 0,
    //   }),
    // )
    // const list: ApiCountryCode[] = res.countries.map(item => {
    //   return {
    //     defaultName: item.defaultName,
    //     name: item.name,
    //     iso2: item.iso2,
    //     hidden: item.hidden,
    //     countryCode: item.countryCodes[0].countryCode,
    //     patterns: item.countryCodes[0].patterns,
    //     prefixes: item.countryCodes[0].prefixes,
    //   }
    // })
    // setCountries(countries)
  }

  useEffect(() => {
    if (client) {
      getCountries()
    }
  }, [client])

  return countries
}

export const useTelegramContacts = async (client: any) => {
  const res = await client.invoke(
    new telegram.Api.contacts.GetContacts({
      hash: BigInt('-4156887774564') as any,
    }),
  )
  console.log(res)
  return res
}

export const useTelegramLink = () => {
  const {currentAccount} = useSession()
  const [binding, setBinding] = useState(false)
  const [bind, setBind] = useState(false)

  const agent = useAgent()
  React.useEffect(() => {
    if (currentAccount?.accessJwt) {
      setBinding(true)
      agent.com.atproto.server
        .getTelegramUserBasicInfo()
        .then(async res => {
          if (!res.data.telegramId || res.data.telegramId === '0') {
            setBind(false)
          } else {
            setBind(true)
          }
        })
        .finally(() => {
          setBinding(false)
        })
    }
  }, [agent.com.atproto.server, currentAccount?.accessJwt])

  const handleBind = async (client: any) => {
    logger.log('handleBind', {client, bind})
    if (client) {
      try {
        setBinding(true)
        await client.connect()
        let res = await getContacts(client)

        const contacts = res.contacts.map((contact: any) => {
          return {
            user_id: +contact.userId.toString(),
            mutual: contact.mutual,
          }
        })
        const users = res.users.map((user: any) => {
          return {
            id: +user.id.toString(),
            /** The telegram firstname. */
            username: user.username ? user.username : '',
            /** The telegram lastname. */
            firstname: user.firstName ? user.firstName : '',
            /** The telegram lastname. */
            lastname: user.lastName ? user.lastName : '',
            /** The telegram user number. */
            phone_number: user.phone ? user.phone : '',
            // photo: user.photo,
          }
        })

        const params = {
          contacts,
          users,
          save_count: res.savedCount,
        }

        const me = await client.getMe()
        const myId = parseInt(me.id.toString(), 10)

        await agent.com.atproto.server.telegramContacts({
          contacts: params,
          tele_user_id: myId,
        })

        const bindParams: ComAtprotoServerTelegramBind.InputSchema = {
          id: myId,
          username: me.username ? me.username : '',
          lastname: me.lastName ? me.lastName : '',
          firstname: me.firstName ? me.firstName : '',
          phone_number: me.phone ? me.phone : '',
        }
        return agent.com.atproto.server
          .telegramBind(bindParams)
          .then(({data}: any) => {
            if (data.ok) {
              setBind(true)
              location.reload()
            } else {
              setBind(false)
            }
          })
      } catch (error) {
        setBind(false)
        console.error(error)
      } finally {
        setBinding(false)
      }
    } else {
      setBind(false)
    }
  }

  return {
    bind,
    binding,
    handleBind,
  }
}
