import React from 'react'
import {Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'

// import {msg, Trans} from '@lingui/macro'
// import {useLingui} from '@lingui/react'
import {logEvent} from '#/lib/statsig/statsig'
import {isWeb} from '#/platform/detection'
// import {validateHandle} from '#/lib/strings/handles'
// import {useAgent} from '#/state/session'
import {ScreenTransition} from '#/screens/Login/ScreenTransition'
import {useSignupContext, useSubmitSignup} from '#/screens/Signup/state'
import {SaveWebView} from '#/screens/Signup/StepSave/SaveWebView'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonIcon} from '#/components/Button'
// import * as TextField from '#/components/forms/TextField'
// import {At_Stroke2_Corner0_Rounded as At} from '#/components/icons/At'
// import {Check_Stroke2_Corner0_Rounded as Check} from '#/components/icons/Check'
// import {TimesLarge_Stroke2_Corner0_Rounded as Times} from '#/components/icons/Times'
import {Loader} from '#/components/Loader'

export function StepSave() {
  // const {_} = useLingui()
  const t = useTheme()
  const {state, dispatch} = useSignupContext()
  const submit = useSubmitSignup({state, dispatch})
  const [saveImage, setSaveImage] = React.useState(false)
  // const agent = useAgent()
  //   const qrcodeRef = useRef()
  // const [draftValue, setDraftValue] = React.useState(state.handle)
  const saveRef = React.useRef<any>()

  const onNextPress = React.useCallback(async () => {
    try {
      dispatch({type: 'setIsLoading', value: true})

      // const res = await agent.resolveHandle({
      //   handle: createFullHandle(handle, state.userDomain),
      // })

      // if (res.data.did) {
      //   dispatch({
      //     type: 'setError',
      //     value: _(msg`That handle is already taken.`),
      //   })
      //   return
      // }
    } catch (e) {
      // Don't have to handle
    } finally {
      dispatch({type: 'setIsLoading', value: false})
    }

    // Request notification permission for web
    // FIXME find a better place for this
    if (isWeb && 'Notification' in window) {
      if (Notification.permission === 'granted') {
        console.log('Notification permission already granted')
      } else if (Notification.permission !== 'denied') {
        console.log('Requesting notification permission')
        let permission = await Notification.requestPermission()
        if (permission === 'granted') {
          console.log('Notification permission granted')
        } else {
          console.log('Notification permission denied')
        }
      }
    }

    logEvent('signup:nextPressed', {
      activeStep: state.activeStep,
      phoneVerificationRequired:
        state.serviceDescription?.phoneVerificationRequired,
    })

    // phoneVerificationRequired is actually whether a captcha is required
    if (!state.serviceDescription?.phoneVerificationRequired) {
      await submit()

      // setTimeout(() => {
      //   onboardingDispatch({type: 'start'})
      // }, 2000);
      return
    }
    // dispatch({type: 'next'})
  }, [
    dispatch,
    state.activeStep,
    state.serviceDescription?.phoneVerificationRequired,
    submit,
  ])

  const onPressSaveImage = () => {
    setSaveImage(true)
    if (saveRef.current?.onSavePress) {
      saveRef.current.onSavePress()
    }
  }

  const onBackPress = React.useCallback(() => {
    // const handle = handleValueRef.current.trim()

    dispatch({type: 'prev'})
    logEvent('signup:backPressed', {
      activeStep: state.activeStep,
    })
  }, [dispatch, state.activeStep])

  // const validCheck = validateHandle(draftValue, state.userDomain)
  return (
    <View style={[a.flex_1]}>
      <View>
        <Text onPress={onBackPress}>
          <FontAwesomeIcon
            size={18}
            icon="angle-left"
            // style={[.text]}
          />
        </Text>
      </View>
      <ScreenTransition style={[a.flex_1]}>
        <View style={[a.flex_1]}>
          <View>
            <Text
              style={[
                a.text_center,
                a.m_xl,
                a.text_lg,
                a.font_semibold,
                t.atoms.text,
                t.atoms.text,
              ]}>
              Save Passport for Future Login
            </Text>
            <Text style={[a.text_lg, a.m_xl, a.font_normal, {color: '#666'}]}>
              This is your Sipz login credentials. Please keep it safe! You
              could scan the QR code to login Sipz later.
            </Text>
          </View>
          <View style={[a.flex_col, a.justify_center, a.p_2xl]}>
            <SaveWebView
              getRef={ref => {
                saveRef.current = ref
              }}
            />
            {!saveImage ? (
              <View style={[a.flex_row, a.justify_between, a.pb_lg, a.pt_3xl]}>
                <Button
                  style={[
                    isWeb ? {width: 300, margin: 'auto'} : {width: '100%'},
                    {height: 50},
                  ]}
                  label="save image"
                  color="primary"
                  variant="solid"
                  size="medium"
                  onPress={onPressSaveImage}>
                  <Text style={[t.atoms.text_inverted, {color: 'black'}]}>
                    <Trans>Save Image</Trans>
                  </Text>
                </Button>
              </View>
            ) : (
              <View style={[a.flex_row, a.justify_between, a.pb_lg, a.pt_3xl]}>
                <Button
                  style={[
                    isWeb ? {width: 300, margin: 'auto'} : {width: '100%'},
                  ]}
                  label="save image"
                  color="primary"
                  variant="solid"
                  size="medium"
                  onPress={onNextPress}>
                  <Text
                    style={[t.atoms.text_inverted, {color: t.palette.black}]}>
                    <Trans>Sip now</Trans>
                  </Text>
                  {state.isLoading && <ButtonIcon icon={Loader} />}
                </Button>
              </View>
              // <BackNextButtons
              //   isLoading={state.isLoading}
              //   style={[a.justify_center]}
              //   nextButtonStyle={isWeb ? [{width: 300}] : [{width: '100%'}]}
              //   // isNextDisabled={!validCheck.overall}
              //   onBackPress={onBackPress}
              //   onNextPress={onNextPress}
              // />
            )}
          </View>
        </View>
      </ScreenTransition>
    </View>
  )
}
