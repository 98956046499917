export const formatCount = (num: number) =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
    // `1,953` shouldn't be rounded up to 2k, it should be truncated.
    // @ts-expect-error: `roundingMode` doesn't seem to be in the typings yet
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#roundingmode
    roundingMode: 'trunc',
  }).format(num)

export function formatCountShortOnly(num: number): string {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K'
  }

  return String(num)
}

export function formatShortOnly(num?: number, decimals?: number) {
  if (!num) {
    return num
  }
  // console.log('formatShortOnly', num)
  if (num >= 1000000) {
    return parseFloat((num / 1000000)?.toFixed(decimals ?? 1)) + 'M'
  } else if (num >= 1000) {
    return parseFloat((num / 1000)?.toFixed(decimals ?? 1)) + 'K'
  } else {
    return parseFloat(num?.toFixed(decimals ?? 1))
  }
}
