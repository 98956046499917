import {ComAtprotoServerDefs} from '@atproto/api'
import {useQuery} from '@tanstack/react-query'

import {PUBLIC_ENV_CHAINID} from '#/lib/constants'
import {getBetAvailableWithdraw} from '#/lib/ethers/bet'
import {getStakeAvailableWithdraw, getTotalBalances} from '#/lib/ethers/stake'
import {useAgent, useSession} from '../../state/session'

const TRANS_ASSETS_ROOT = 'transaction-assets'
const TRANS_ASSETS_TOTAL_ROOT = 'transaction-assets-total'
const TRANS_ASSETS_STAKE_ROOT = 'transaction-assets-stake'
const TRANS_ASSETS_BET_ROOT = 'transaction-assets-bet'

export const TRANS_STAKE_WITHDRAW_RQKEY = () => [TRANS_ASSETS_STAKE_ROOT]
export const TRANS_BET_WITHDRAW_RQKEY = () => [TRANS_ASSETS_STAKE_ROOT]

export function useWalletListQuery() {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<string[], Error>({
    queryKey: [TRANS_ASSETS_ROOT],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getTransWalletAddress()
      return Array.from(new Set(res.data.walletAddress))
    },
    enabled: !!currentAccount,
  })
}

export function useUserTransRecordsQuery(walletAddress: string) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<ComAtprotoServerDefs.UserTransRecordInfo[], Error>({
    queryKey: [TRANS_ASSETS_ROOT, walletAddress],
    queryFn: async () => {
      try {
        const res = await agent.com.atproto.server.getUserTransRecords({
          walletAddress,
        })
        return res.data.trans
      } catch (error) {
        console.log('getUserTransRecords-error', error)
        return []
      }
    },
    enabled: !!walletAddress && !!currentAccount,
  })
}

export function useTotalBalanceQuery(walletAddress: string) {
  return useQuery<number, Error>({
    queryKey: [TRANS_ASSETS_TOTAL_ROOT, walletAddress],
    queryFn: async () => {
      console.log('useTotalBalanceQuery', walletAddress)
      try {
        const res = await getTotalBalances(PUBLIC_ENV_CHAINID, walletAddress)
        console.log('useTotalBalanceQuery', res)
        return res
      } catch (error) {
        console.log('useTotalBalanceQuery-error', error)
        return 0
      }
    },
    enabled: !!walletAddress,
  })
}

export function useStakeAvailableWithdrawQuery(walletAddress: string) {
  return useQuery<number, Error>({
    queryKey: [TRANS_ASSETS_STAKE_ROOT, walletAddress],
    queryFn: async () => {
      // console.log('useStakeAvailableWithdrawQuery', walletAddress)
      const res = await getStakeAvailableWithdraw(
        PUBLIC_ENV_CHAINID,
        walletAddress,
      )
      // console.log('useStakeAvailableWithdrawQuery', res)
      return res
    },
    enabled: !!walletAddress,
  })
}

export function useBetAvailableWithdrawQuery(walletAddress: string) {
  return useQuery<number, Error>({
    queryKey: [TRANS_ASSETS_BET_ROOT, walletAddress],
    queryFn: async () => {
      const res = await getBetAvailableWithdraw(
        PUBLIC_ENV_CHAINID,
        walletAddress,
      )
      return res
    },
    enabled: !!walletAddress,
  })
}
