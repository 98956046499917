import {BrowserProvider, Contract, ethers} from 'ethers'

import donateContractABI from '../abi/donate_abi.json'
import {SUPPORT_CHAINS} from '../constants'
import {handleApprove, switchChain} from './index'

async function getDonateContract(provider: any, chainId: number) {
  const chainInfoValue = SUPPORT_CHAINS?.find(o => o?.chainId === chainId)
  const contractAddress = chainInfoValue?.contract?.DONATE_ADDRESS
  if (!contractAddress) {
    throw new Error('Missing contract address.')
  }
  console.log('donate--', contractAddress)

  // const web3Provider = new ethers.providers.Web3Provider(provider) // ethers5
  // const signer = web3Provider.getSigner() // ethers5
  const web3Provider = new BrowserProvider(provider) // ethers6
  const signer = await web3Provider.getSigner() // ethers6
  const contract = new Contract(
    contractAddress,
    donateContractABI as any,
    signer,
  )

  return {
    contract,
    signer,
    contractAddress,
    payTokenAddress: chainInfoValue?.payTokenAddress,
  }
}

export const donate = async (
  provider: any,
  amount: number,
  chainId: number,
  postUri: string,
  toAddress: string,
) => {
  const {contract, signer, contractAddress, payTokenAddress} =
    await getDonateContract(provider, chainId)

  await switchChain(provider, chainId)

  const tradeAmount = ethers.parseUnits(amount + '', 6) // ethers6
  // const tradeAmount = ethers.utils.parseUnits(amount + '', 6) // ethers5
  console.log('donate--tradeAmount', tradeAmount)
  // approve
  await handleApprove(contractAddress, signer, payTokenAddress, tradeAmount)
  console.log('donate-postUri', postUri)
  return await contract.donate(postUri, toAddress, tradeAmount, payTokenAddress)
}
