import React, {useEffect, useRef} from 'react'
import {
  KeyboardTypeOptions,
  StyleSheet,
  Text,
  TextInput,
  View,
  ViewStyle,
} from 'react-native'

import {EditIcon} from '#/view/icons/ProfileIcons'
import {atoms as a, useTheme} from '#/alf'

interface ISdlTextInput {
  text: string
  setText: (text: string) => void
  placeholder: string
  style?: ViewStyle
  multiline?: boolean
}

export function SdlTextInput({
  text,
  setText,
  placeholder,
  style,
  multiline,
}: ISdlTextInput) {
  const [showPlaceholder, setShowPlaceholder] = React.useState(true)

  return (
    <View style={[styles.page, style]}>
      <TextInput
        accessibilityRole="none"
        style={styles.input}
        value={text}
        onFocus={() => setShowPlaceholder(false)}
        onBlur={() => setShowPlaceholder(true)}
        onChangeText={newText => setText(newText)}
        keyboardType="default"
        multiline={multiline}
        textAlignVertical="top"
      />
      {showPlaceholder && !text && (
        <View style={[styles.placeholderBox, multiline && styles.multiline]}>
          <Text style={styles.placeholder}>{placeholder}</Text>
          <EditIcon size={19} style={{marginLeft: 10}} primaryColor="#979797" />
        </View>
      )}
    </View>
  )
}

export function SdlInput({
  value,
  setValue,
  style,
  passWord = false,
  maxLength,
  placeholder,
  focus,
  keyboardType,
}: {
  value: string
  setValue: (newValue: string) => void
  style?: ViewStyle
  passWord?: boolean
  maxLength?: number
  placeholder?: string
  focus?: boolean
  keyboardType?: KeyboardTypeOptions
}) {
  const t = useTheme()
  const ref = useRef<TextInput>(null)

  useEffect(() => {
    if (focus) {
      ref.current?.focus()
    }
  }, [focus])

  return (
    <TextInput
      ref={ref}
      keyboardType={keyboardType}
      value={value}
      onChangeText={setValue}
      accessibilityLabel="Text input field"
      accessibilityHint="Enter text to search"
      placeholder={placeholder ?? 'Enter...'}
      placeholderTextColor="#979797"
      secureTextEntry={passWord}
      maxLength={maxLength}
      focusable
      style={[
        style,
        {borderWidth: 1, height: 44},
        a.relative,
        a.p_xs,
        a.rounded_md,
        t.atoms.text,
        a.text_md,
        a.pl_sm,
        a.pr_md,
        t.atoms.border_contrast_low,
      ]}
    />
  )
}

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    width: '100%',
    height: 35,
    borderWidth: 0,
    borderColor: '#E5E5E5',
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: 6,
  },
  input: {
    // backgroundColor: 'pink',
    height: '100%',
    fontSize: 18,
    fontWeight: 'bold',
  },
  placeholderBox: {
    position: 'absolute',
    top: 2,
    left: 0,
    pointerEvents: 'none',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  placeholder: {
    color: '#979797',
    fontSize: 14,
  },
  multiline: {
    alignItems: 'flex-start',
    paddingTop: 5,
  },
})
