import {useSyncExternalStore} from 'react'

import {createStore} from './SdlStore'

const store = createStore({
  visible: true,
})

const {subscribe, getState, setState: setBottomBarStore} = store

export function useBottomBarStore() {
  const profileStore = useSyncExternalStore(subscribe, getState)
  const hideBottomBar = () => {
    setBottomBarStore({visible: false})
  }
  const showBottomBar = () => {
    setBottomBarStore(pre => {
      return {visible: !pre.visible}
    })
  }
  return {
    visible: profileStore.visible,
    hideBottomBar,
    showBottomBar,
  }
}
