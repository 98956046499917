import {BskyAgent} from '@atproto/api'

const relationship = new Map<string, string[]>()

const loading = new Map<string, boolean>()

const eventsMap = new Map<string, Function[]>()

export const clearRelationship = () => {
  relationship.clear()
}

export const getRelationship = async (key: string, agent: BskyAgent) => {
  return new Promise<string[]>(async resolve => {
    let result = relationship.get(key)
    if (result) {
      resolve(result)
    } else if (loading.get(key)) {
      const events = eventsMap.get(key)
      if (events) {
        events.push(resolve)
      } else {
        eventsMap.set(key, [resolve])
      }
    } else {
      try {
        loading.set(key, true)
        const rl = await agent.com.atproto.server.getRelationShips({
          sipz_id_other: key,
        })
        if (eventsMap.get(key)) {
          const events = eventsMap.get(key)
          if (events) {
            events.forEach(event => event(rl.data.relation))
          }
          eventsMap.delete(key)
        }
        loading.delete(key)
        if (rl?.data?.relation) {
          relationship.set(key, rl.data.relation)
          resolve(rl.data.relation)
        } else {
          resolve([])
        }
      } catch (error) {
        loading.delete(key)
        console.error(error)
        if (eventsMap.get(key)) {
          const events = eventsMap.get(key)
          if (events) {
            events.forEach(event => event([]))
          }
          eventsMap.delete(key)
        }
        resolve([])
      }
    }
  })
}
