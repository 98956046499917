import React from 'react'
import {Image, StyleSheet, View} from 'react-native'
import QRCode from 'react-native-qrcode-svg'

export function ShareQRCode({url}: {url: string}) {
  return (
    <View style={styles.page}>
      <QRCode value={url} size={90} color="black" backgroundColor="white" />
      <View style={styles.logo}>
        <Image
          style={[styles.image, {marginRight: 20}]}
          source={require('../../../../../assets/imgs/qr_logo.png')}
          accessibilityIgnoresInvertColors={true}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: 100,
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 10,
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: 33,
    left: 33,
    width: 16,
    height: 16,
    padding: 1,
    backgroundColor: 'white',
  },
  image: {
    width: 14,
    height: 14,
    borderRadius: 20,
  },
})
