import React, {useState} from 'react'
import {
  Button,
  Dimensions,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import {
  BarcodeScanningResult,
  CameraType,
  CameraView,
  // CameraType,
  useCameraPermissions,
} from 'expo-camera'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'

import {usePalette} from '#/lib/hooks/usePalette'
import {isIOS} from '#/platform/detection'

export default function CameraScannerView({
  onCodeScanned,
  onCloseScanner,
}: {
  onCodeScanned: (result: BarcodeScanningResult) => void
  onCloseScanner?: () => void
}) {
  const [facing] = useState<CameraType>('back')
  const [permission, requestPermission] = useCameraPermissions()
  const windowHeight = Dimensions.get('window').height
  const pal = usePalette('default')

  if (!permission) {
    // Camera permissions are still loading.
    return <View />
  }

  if (!permission.granted) {
    // Camera permissions are not granted yet.
    return (
      <View style={[styles.container, {height: windowHeight}]}>
        <Text style={styles.message}>
          We need your permission to show the camera
        </Text>
        <Button onPress={requestPermission} title="grant permission" />
      </View>
    )
  }

  // function toggleCameraFacing() {
  //   setFacing(current => (current === 'back' ? 'front' : 'back'))
  // }

  return (
    <SafeAreaView style={{flex: 1, height: windowHeight}}>
      <CameraView
        style={styles.camera}
        facing={facing}
        onBarcodeScanned={onCodeScanned}
        barcodeScannerSettings={{
          barcodeTypes: ['qr'],
        }}>
        <Pressable
          accessibilityRole="button"
          style={{
            position: 'absolute',
            top: isIOS ? 0 : 10,
            right: 10,
            padding: 10,
            zIndex: 100,
            backgroundColor: pal.text.color,
            borderRadius: 100,
          }}
          onPress={onCloseScanner}>
          <FontAwesomeIcon
            icon="x"
            size={12}
            style={{
              color: String(pal.textInverted.color),
            }}
          />
        </Pressable>
        {/* <View style={styles.buttonContainer}>
          <TouchableOpacity
            accessibilityRole="button"
            style={styles.button}
            onPress={toggleCameraFacing}>
            <Text style={styles.text}>Flip Camera</Text>
          </TouchableOpacity>
        </View> */}
      </CameraView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    height: 1000,
  },
  message: {
    textAlign: 'center',
    paddingBottom: 10,
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 64,
  },
  button: {
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
})
