import React, {memo, useCallback, useState} from 'react'
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  View,
  type ViewStyle,
} from 'react-native'
import {
  AppBskyFeedDefs,
  AppBskyFeedPost,
  AtUri,
  RichText as RichTextAPI,
} from '@atproto/api'
import {plural} from '@lingui/macro'

import {POST_CTRL_HITSLOP} from '#/lib/constants'
import {useHaptics} from '#/lib/haptics'
import {makeProfileLink} from '#/lib/routes/links'
import {toShareUrl} from '#/lib/strings/url-helpers'
import {s} from '#/lib/styles'
import {Shadow} from '#/state/cache/types'
import {useFeedFeedbackContext} from '#/state/feed-feedback'
import {useModalControls} from '#/state/modals'
import {
  usePostLikeMutationQueue,
  usePostOpposeMutationQueue,
  usePostRepostMutationQueue,
} from '#/state/queries/post'
import {useAgent, useRequireAuth, useSession} from '#/state/session'
import {useComposerControls} from '#/state/shell/composer'
import {
  ProgressGuideAction,
  useProgressGuideControls,
} from '#/state/shell/progress-guide'
import {BooIcon, ReplyIcon, ShareIcon, UpvoteIcon} from '#/view/icons/FeedIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {useDialogControl} from '#/components/Dialog'
import {PostDropdownBtn} from '../forms/PostDropdownBtn'
import {formatCount} from '../numeric/format'
import {Text} from '../text/Text'
import {RepostButton} from './RepostButton'

let PostCtrls = ({
  big,
  post,
  record,
  feedContext,
  richText,
  style,
  onPressReply,
  logContext,
  showRightBt,
}: {
  big?: boolean
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  richText: RichTextAPI
  showRightBt?: boolean
  feedContext?: string | undefined
  style?: StyleProp<ViewStyle>
  onPressReply: () => void
  logContext: 'FeedItem' | 'PostThreadItem' | 'Post'
}): React.ReactNode => {
  const [oppose, setOppose] = useState({
    amount: post.opposeCount || 0,
    key: post.viewer?.oppose || '',
  })
  const agent = useAgent()
  const t = useTheme()
  const {openComposer} = useComposerControls()
  const {currentAccount} = useSession()
  const [queueLike, queueUnlike] = usePostLikeMutationQueue(post, logContext)
  const [queueOppose, queueUnoppose] = usePostOpposeMutationQueue(
    post,
    logContext,
  )
  const [queueRepost, queueUnrepost] = usePostRepostMutationQueue(
    post,
    logContext,
  )
  const requireAuth = useRequireAuth()
  const loggedOutWarningPromptControl = useDialogControl()
  const {sendInteraction} = useFeedFeedbackContext()
  const {captureAction} = useProgressGuideControls()
  const playHaptic = useHaptics()

  const shouldShowLoggedOutWarning = React.useMemo(() => {
    return (
      post.author.did !== currentAccount?.did &&
      !!post.author.labels?.find(label => label.val === '!no-unauthenticated')
    )
  }, [currentAccount, post])

  const defaultCtrlColor = React.useMemo(
    () => ({
      color: t.palette.gray_12,
    }),
    [t],
  ) as StyleProp<ViewStyle>

  const onPressToggleLike = React.useCallback(async () => {
    try {
      if (!post.viewer?.like) {
        playHaptic()
        sendInteraction({
          item: post.uri,
          event: 'app.bsky.feed.defs#interactionLike',
          feedContext,
        })
        captureAction(ProgressGuideAction.Like)
        await queueLike()
        if (post.viewer?.oppose) {
          await queueUnoppose()
        }
      } else {
        await queueUnlike()
      }
    } catch (e: any) {
      if (e?.name !== 'AbortError') {
        throw e
      }
    }
  }, [
    playHaptic,
    post.uri,
    post.viewer?.like,
    queueLike,
    queueUnlike,
    sendInteraction,
    captureAction,
    feedContext,
    post.viewer?.oppose,
    queueUnoppose,
  ])

  const onPressToggleOppose = React.useCallback(async () => {
    try {
      if (!post.viewer?.oppose) {
        playHaptic()
        sendInteraction({
          item: post.uri,
          event: 'app.bsky.feed.defs#interactionOppose',
          feedContext,
        })
        captureAction(ProgressGuideAction.Oppose)
        await queueOppose()
        if (post.viewer?.like) {
          await queueUnlike()
        }
      } else {
        await queueUnoppose()
      }
    } catch (e: any) {
      if (e?.name !== 'AbortError') {
        throw e
      }
    }
  }, [
    playHaptic,
    post.uri,
    post.viewer?.oppose,
    queueOppose,
    queueUnoppose,
    sendInteraction,
    captureAction,
    feedContext,
    post.viewer?.like,
    queueUnlike,
  ])

  const onPressToggleUnLike = React.useCallback(async () => {
    if (oppose.key) {
      setOppose({
        amount: 0,
        key: '',
      })
      agent.com.atproto.repo.deleteRecord({
        collection: 'app.bsky.feed.oppose',
        repo: currentAccount?.did || '',
        rkey: oppose.key.split('/').pop() || '',
      })
    } else {
      const temp = {
        collection: 'app.bsky.feed.oppose',
        repo: currentAccount?.did || '',
        record: {
          subject: {
            uri: post.uri,
            cid: post.cid,
          },
          createdAt: new Date().toISOString(),
          $type: 'app.bsky.feed.oppose',
        },
      }
      const res = await agent.com.atproto.repo.createRecord(temp)

      setOppose({
        amount: oppose.amount ? oppose.amount++ : 1,
        key: res.data.uri,
      })
    }
  }, [agent.com.atproto.repo, post, currentAccount?.did, setOppose, oppose])
  onPressToggleUnLike

  const onRepost = useCallback(async () => {
    try {
      if (!post.viewer?.repost) {
        sendInteraction({
          item: post.uri,
          event: 'app.bsky.feed.defs#interactionRepost',
          feedContext,
        })
        await queueRepost()
      } else {
        await queueUnrepost()
      }
    } catch (e: any) {
      if (e?.name !== 'AbortError') {
        throw e
      }
    }
  }, [
    post.uri,
    post.viewer?.repost,
    queueRepost,
    queueUnrepost,
    sendInteraction,
    feedContext,
  ])

  const onQuote = useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#interactionQuote',
      feedContext,
    })
    openComposer({
      quote: {
        uri: post.uri,
        cid: post.cid,
        text: record.text,
        author: post.author,
        indexedAt: post.indexedAt,
      },
    })
  }, [
    openComposer,
    post.uri,
    post.cid,
    post.author,
    post.indexedAt,
    record.text,
    sendInteraction,
    feedContext,
  ])

  const {openModal} = useModalControls()

  const onShare = useCallback(() => {
    const urip = new AtUri(post.uri)
    const href = makeProfileLink(post.author, 'post', urip.rkey)
    const url = toShareUrl(href)

    let target = ''

    if (post.record?.finEntities?.length) {
      post.record?.finEntities.forEach(element => {
        target += `#${element.name} `
      })
    }

    openModal({
      name: 'share-tea',
      avatar: post.author.avatar || '',
      displayName: post.author.displayName || formatHandler(post.author.handle),
      url: url,
      image: post?.embed?.images?.[0]?.thumb || '',
      content: post.record?.text || '',
      replyCount: post.replyCount || 0,
      repostCount: post.repostCount || 0,
      likeCount: post.likeCount || 0,
      target,
    })
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#interactionShare',
      feedContext,
    })
  }, [post, openModal, sendInteraction, feedContext])

  const btnStyle = React.useCallback(
    ({pressed, hovered}: PressableStateCallbackType) => [
      a.gap_xs,
      a.rounded_full,
      a.flex_row,
      a.align_center,
      a.justify_center,
      {padding: 5},
      (pressed || hovered) && t.atoms.bg_contrast_25,
    ],
    [t.atoms.bg_contrast_25],
  )

  return (
    <View style={{flexDirection: 'row'}}>
      <View
        style={[
          a.flex_row,
          a.justify_between,
          a.align_center,
          style,
          !showRightBt && {flex: 1},
        ]}>
        <View style={showRightBt && {width: 70, alignItems: 'flex-start'}}>
          <Pressable
            testID="replyBtn"
            style={btnStyle}
            onPress={() => {
              if (!post.viewer?.replyDisabled) {
                requireAuth(() => onPressReply())
              }
            }}
            accessibilityLabel={plural(post.replyCount || 0, {
              one: 'Reply (# reply)',
              other: 'Reply (# replies)',
            })}
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <ReplyIcon />
            {typeof post.replyCount !== 'undefined' && post.replyCount > 0 ? (
              <Text
                style={[
                  defaultCtrlColor,
                  big ? a.text_md : {fontSize: 15},
                  a.user_select_none,
                ]}>
                {formatCount(post.replyCount)}
              </Text>
            ) : undefined}
          </Pressable>
        </View>

        <View style={showRightBt && {width: 70, alignItems: 'flex-start'}}>
          <Pressable
            testID="likeBtn"
            style={btnStyle}
            onPress={() => requireAuth(() => onPressToggleLike())}
            accessibilityLabel={
              post.viewer?.like
                ? plural(post.likeCount || 0, {
                    one: 'Unlike (# like)',
                    other: 'Unlike (# likes)',
                  })
                : plural(post.likeCount || 0, {
                    one: 'Like (# like)',
                    other: 'Like (# likes)',
                  })
            }
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <UpvoteIcon active={!!post.viewer?.like && !!currentAccount?.did} />
            {typeof post.likeCount !== 'undefined' && post.likeCount > 0 ? (
              <Text
                testID="likeCount"
                style={[
                  [
                    big ? a.text_md : {fontSize: 15},
                    a.user_select_none,
                    post.viewer?.like && !!currentAccount?.did
                      ? [a.font_bold, s.likeColor]
                      : defaultCtrlColor,
                  ],
                ]}>
                {formatCount(post.likeCount)}
              </Text>
            ) : undefined}
          </Pressable>
        </View>

        <View style={showRightBt && {width: 70, alignItems: 'flex-start'}}>
          <Pressable
            testID="opposeBtn"
            style={btnStyle}
            onPress={() => requireAuth(() => onPressToggleOppose())}
            accessibilityLabel={
              post.viewer?.oppose
                ? plural(post.opposeCount || 0, {
                    one: 'Unoppose (# oppose)',
                    other: 'Unoppose (# opposes)',
                  })
                : plural(post.opposeCount || 0, {
                    one: 'Oppose (# oppose)',
                    other: 'Oppose (# opposes)',
                  })
            }
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <BooIcon active={!!post.viewer?.oppose} />
            {typeof post.opposeCount !== 'undefined' && post.opposeCount > 0 ? (
              <Text
                testID="opposeCount"
                style={[
                  [
                    big ? a.text_md : {fontSize: 15},
                    a.user_select_none,
                    post.viewer?.oppose
                      ? [a.font_bold, s.likeColor]
                      : defaultCtrlColor,
                  ],
                ]}>
                {formatCount(post.opposeCount)}
              </Text>
            ) : undefined}

            {/* {oppose.amount > 0 ? (
          <Text
            testID="likeCount"
            style={
              [
                // [
                //   big ? a.text_md : {fontSize: 15},
                //   a.user_select_none,
                //   post.viewer?.like
                //     ? [a.font_bold, s.likeColor]
                //     : defaultCtrlColor,
                // ],
              ]
            }>
            {formatCount(oppose.amount)}
          </Text>
        ) : undefined} */}
          </Pressable>
        </View>

        <View style={showRightBt && {width: 70, alignItems: 'flex-start'}}>
          <RepostButton
            isReposted={!!post.viewer?.repost}
            repostCount={post.repostCount}
            onRepost={onRepost}
            onQuote={onQuote}
            big={big}
          />
        </View>

        {!showRightBt && (
          <Pressable
            testID="shareBt"
            style={btnStyle}
            onPress={() => {
              if (shouldShowLoggedOutWarning) {
                loggedOutWarningPromptControl.open()
              } else {
                onShare()
              }
            }}
            accessibilityLabel={'Share'}
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <ShareIcon />
            {typeof post.likeCount !== 'undefined' && post.likeCount > 0 ? (
              <Text
                testID="likeCount"
                style={[
                  [
                    big ? a.text_md : {fontSize: 15},
                    a.user_select_none,
                    defaultCtrlColor,
                  ],
                ]}>
                {/* {formatCount(post.likeCount)} */}
              </Text>
            ) : undefined}
          </Pressable>
        )}
      </View>
      {showRightBt && (
        <>
          <View style={a.flex_1} />
          <PostDropdownBtn
            testID="postDropdownBtn"
            post={post}
            postFeedContext={feedContext}
            record={record}
            richText={richText}
            style={{padding: 5}}
            hitSlop={POST_CTRL_HITSLOP}
            timestamp={post.indexedAt}
          />
        </>
      )}
    </View>
  )
}
PostCtrls = memo(PostCtrls)
export {PostCtrls}
