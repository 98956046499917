import React from 'react'
import {AppBskyActorDefs as ActorDefs} from '@atproto/api'

import {useProfileFollowsQuery} from '#/state/queries/profile-follows'
import {useSession} from '#/state/session'
import {atoms as a} from '#/alf'
import {ScrollView} from '../../modals/util'
import {ItemProps} from '.'
import {Actor} from './Actor'

export const Following = ({onPress}: ItemProps) => {
  const {currentAccount} = useSession()
  const {
    data,
    // isLoading: isFollowersLoading,
    // isFetchingNextPage,
    // hasNextPage,
    // fetchNextPage,
    // error,
    // refetch,
  } = useProfileFollowsQuery(currentAccount?.did)

  const follows = React.useMemo(() => {
    if (data?.pages) {
      return data.pages.flatMap(page => page.follows)
    }
    return []
  }, [data])
  const renderItem = ({item}: {item: ActorDefs.ProfileViewBasic}) => {
    return <Actor actor={item} onPress={onPress} />
  }

  return (
    <ScrollView style={[a.mt_md, a.flex_1]}>
      {follows.map(item => renderItem({item}))}
    </ScrollView>
  )
}
