import React, {useState} from 'react'
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {isMobile, isWeb} from '#/platform/detection'
import {useAgent, useSession, useSessionApi} from '#/state/session'
import {useProfileQuery} from 'state/queries/profile'
import * as Toast from '#/view/com/util/Toast'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {useDialogControl} from '#/components/Dialog'
import * as Toggle from '#/components/forms/Toggle'
import {Trash_Stroke2_Corner0_Rounded as Trash} from '#/components/icons/Trash'
import {Link} from '#/components/Link'
import * as Prompt from '#/components/Prompt'
// import { Loader } from '#/components/Loader'
import {MaskAccount} from '.'

type Props = {
  mask: MaskAccount
  index: number
  onChange?: (mask: MaskAccount, selected: boolean) => void
}

const maskBackgrounds = ['#E7D6FF', '#C9D1FF', '#D4FFD3', '#FFD7CA']

export const MaskItem = ({mask, index, onChange}: Props) => {
  const t = useTheme()
  const {_} = useLingui()
  const agent = useAgent()
  const {currentAccount, accounts} = useSession()
  const {resumeSession, removeAccount} = useSessionApi()
  const navigation = useNavigation<NavigationProp>()
  const {data: profile} = useProfileQuery({did: mask.did})
  const deletePromptControl = useDialogControl()
  const backgroundColor = maskBackgrounds[index % maskBackgrounds.length]
  const isCurrent = React.useMemo(() => {
    return currentAccount?.did === mask.did
  }, [currentAccount?.did, mask.did])
  const [values] = useState<string[]>(() => {
    return isCurrent ? [mask.did] : []
  })

  const onChangeMask = () => {
    const isSelected = values.length > 0
    onChange?.(mask, !isSelected)
  }

  const onDelete = async () => {
    deletePromptControl.open()
  }

  const onDeleteMask = React.useCallback(async () => {
    try {
      if (mask.did) {
        if (mask.parentDid && currentAccount?.did === mask.did) {
          const account = accounts.find(a => a.did === mask.parentDid)
          if (account) {
            await resumeSession(account)
          }
        }
        const account = accounts.find(a => a.did === mask.did)
        await agent.com.atproto.server.deleteSubAccount({did: mask.did})
        if (account) {
          removeAccount(account)
        }

        Toast.show(_(msg`Mask deleted`))
        navigation.navigate('Mask', {time: Date.now()})
      }
    } catch (error) {}
  }, [
    _,
    accounts,
    agent.com.atproto.server,
    currentAccount?.did,
    mask.did,
    mask.parentDid,
    navigation,
    removeAccount,
    resumeSession,
  ])

  return (
    <View style={[a.mt_xl, t.atoms.bg_gray, a.rounded_md]}>
      <Link to={`/profile/${mask.handle}`} label="link account profile">
        <View style={[styles.container, a.rounded_md, {backgroundColor}]}>
          <View style={styles.summary}>
            <UserAvatar size={58} avatar={profile?.avatar} />
            <View style={styles.summary_right}>
              <View style={[a.w_full, a.flex_2]}>
                <Text style={[{fontSize: 20, fontWeight: 600}]}>
                  {profile?.displayName || formatHandler(mask.handle)}
                </Text>
                <Text style={[{fontSize: 14, fontWeight: 400}]}>
                  @{formatHandler(profile?.handle || mask.handle)}
                </Text>
              </View>
              {/* <View style={[a.w_full, a.flex_1]}>
                <Text style={[a.text_right, {fontSize: 20, fontWeight: 600}]}>
                  $98.7k
                </Text>
                <Text style={[a.text_right, {fontSize: 14, fontWeight: 400}]}>
                  Winnings
                </Text>
              </View> */}
            </View>
          </View>
          <View style={styles.info}>
            <View style={{width: '15%'}}>
              <View>
                <Text style={[styles.info_value]}>
                  {profile?.postsCount || 0}
                </Text>
              </View>
              <View>
                <Text style={[styles.info_text]}>Tea</Text>
              </View>
            </View>
            <View style={[styles.info_item, t.atoms.border_contrast_medium]}>
              <View>
                <Text style={[styles.info_value]}>0</Text>
              </View>
              <View>
                <Text style={[styles.info_text]}>Bookmarks</Text>
              </View>
            </View>
            <View style={[styles.info_item, t.atoms.border_contrast_medium]}>
              <View>
                <Text style={[styles.info_value]}>
                  {profile?.followersCount || 0}
                </Text>
              </View>
              <View>
                <Text style={[styles.info_text]}>Followers</Text>
              </View>
            </View>
            <View style={[styles.info_item, t.atoms.border_contrast_medium]}>
              <View>
                <Text style={[styles.info_value]}>
                  {profile?.followsCount || 0}
                </Text>
              </View>
              <View>
                <Text style={[styles.info_text]}>Following</Text>
              </View>
            </View>
          </View>
        </View>
      </Link>
      <View
        style={[
          a.flex_row,
          a.justify_between,
          a.align_center,
          a.p_lg,
          t.atoms.border_contrast_medium,
        ]}>
        <View style={[a.flex_row, a.gap_sm, a.align_center]}>
          <Toggle.Group
            // disabled={isCurrent}
            type="checkbox"
            label="Mask"
            values={values}
            onChange={onChangeMask}>
            <Toggle.Item name={mask.did} label="Switch Mask">
              <Toggle.Switch />
            </Toggle.Item>
          </Toggle.Group>
          {currentAccount?.did === mask.did ? (
            <Text>
              <Trans>On</Trans>
            </Text>
          ) : (
            <Text style={[t.atoms.text_contrast_low]}>
              <Trans>Off</Trans>
            </Text>
          )}
        </View>
        <TouchableOpacity accessibilityRole="button" onPress={onDelete}>
          <Trash style={[{color: t.palette.gray_10}]} />
        </TouchableOpacity>
      </View>
      <Prompt.Basic
        control={deletePromptControl}
        title={_(msg`Delete Mask`)}
        description={_(
          msg`Deleting will clear the data you generated through the mask`,
        )}
        onConfirm={onDeleteMask}
        confirmButtonCta={_(msg`Delete`)}
        confirmButtonColor="negative"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // flexDirection: 'row',
    flexDirection: 'column',
    paddingHorizontal: isWeb && !isMobile ? 30 : 20,
    paddingVertical: 20,
    // marginTop: 20,
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  summary_right: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  info: {
    display: 'flex',
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  info_item: {
    paddingHorizontal: 8,
    borderLeftWidth: 1,
    flex: 1,
  },
  info_value: {
    fontSize: 14,
    fontWeight: 800,
  },
  info_text: {
    fontSize: 14,
    fontWeight: 400,
  },
})
