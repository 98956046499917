import React from 'react'
import {
  Pressable,
  // Image,
  // ImageStyle,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import ConfettiCannon from 'react-native-confetti-cannon'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {PUBLIC_ENV_CHAINID, SUPPORT_CHAINS} from '#/lib/constants'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {atoms as a, useTheme} from '#/alf'
import {
  AddEditIcon,
  AssetsScanIcon,
  CompeleteIcon,
  ShareIcon,
} from '#/components/icons/StakeIcons'
import {Button} from '../util/forms/Button'

const endoriseBtn = require('../../../../assets/endorise_btn.png')
const donorsBtn = require('../../../../assets/donors_btn.png')
const betBtn = require('../../../../assets/bet_btn.png')

export const snapPoints = ['50%']

export type TransResultModalProps = {
  type:
    | 'stake'
    | 'donate'
    | 'earn-bet'
    | 'create-bet'
    | 'to-approve-bet'
    | 'stake-withdraw'
  amount?: number | string
  txId?: string
}
export function Component({type, amount, txId}: TransResultModalProps) {
  const {_} = useLingui()
  const t = useTheme()
  const {closeModal, openModal} = useModalControls()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const viewConfig = {
    'earn-bet': {
      title: `You bet ${amount} USDC`,
      icon: betBtn,
      iconStyle: {
        width: 297,
        height: 37,
      },
    },
    'create-bet': {
      title: 'Successfully created Bet',
      icon: undefined,
      iconStyle: {},
    },
    'to-approve-bet': {
      title: 'You will be notified once the poster approves',
      icon: undefined,
      iconStyle: {},
    },
    stake: {
      title: `You staked ${amount} USDC`,
      icon: endoriseBtn,
      iconStyle: {
        width: 120,
        height: 22,
      },
    },
    donate: {
      title: `You donated ${amount} USDC`,
      icon: donorsBtn,
      iconStyle: {
        width: 120,
        height: 20,
      },
    },
    'stake-withdraw': {
      title: `You received ${amount} USDC`,
      icon: undefined,
      iconStyle: {},
    },
  }

  const onPressScan = React.useCallback(
    (item: string) => {
      const chainInfo = SUPPORT_CHAINS.find(
        o => o?.chainId === PUBLIC_ENV_CHAINID,
      )
      const url = `${chainInfo?.explorerUrl}/tx/${item}`
      openModal({
        name: 'link-warning',
        text: 'View on block explorer',
        href: url,
      })
    },
    [openModal],
  )

  return (
    <SafeAreaView testID="transtionResultModal">
      <View
        style={[
          styles.confetIcon,
          isMobile ? {position: 'absolute'} : styles.confetIconWeb,
        ]}>
        <ConfettiCannon
          count={200}
          explosionSpeed={200}
          fallSpeed={3000}
          origin={{x: -10, y: 0}}
          fadeOut={true}
        />
      </View>
      <View style={[styles.transContent]}>
        <View style={[styles.compeleteIcon]}>
          <CompeleteIcon />
        </View>
        <Text style={[styles.transTitle, a.font_heavy]}>Success</Text>
        <View style={[a.flex_row, a.align_center, a.gap_sm]}>
          <Text style={[a.font_semibold]}>{viewConfig[type]?.title}</Text>
          {txId && (
            <Pressable
              accessibilityRole="button"
              onPress={() => onPressScan(txId)}>
              <AssetsScanIcon />
            </Pressable>
          )}
        </View>
        {/* {viewConfig[type].icon && (
          <Image
            testID="endorise_btn"
            source={viewConfig[type].icon}
            style={[
              styles.transBtnIcon as StyleProp<ImageStyle>,
              viewConfig[type]?.iconStyle as StyleProp<ImageStyle>,
            ]}
            accessibilityIgnoresInvertColors
          />
        )} */}
      </View>
      <View
        style={[
          styles.transFooter,
          a.align_center,
          a.justify_center,
          isDesktop ? a.flex_row_reverse : [],
        ]}>
        {type === 'earn-bet' && false && (
          <View style={[a.gap_lg, a.flex_row, a.mb_3xl]}>
            <Button
              testID="shareBtn"
              type="primary-outline"
              onPress={() => {
                // closeModal()
              }}
              style={[
                a.flex_1,
                {
                  backgroundColor: t.palette.primary_50,
                  borderColor: t.palette.primary_100,
                },
              ]}>
              <View
                style={[
                  a.h_full,
                  a.flex_row,
                  a.align_center,
                  a.justify_center,
                  a.gap_sm,
                ]}>
                <ShareIcon size={20} />
                <Text
                  style={[a.font_bold, a.text_md, {color: t.palette.black}]}>
                  Share
                </Text>
              </View>
            </Button>
            <Button
              testID="shareBtn"
              type="primary-outline"
              onPress={() => {
                // closeModal()
              }}
              style={[
                a.flex_1,
                {
                  backgroundColor: t.palette.primary_50,
                  borderColor: t.palette.primary_100,
                },
              ]}>
              <View
                style={[
                  a.h_full,
                  a.flex_row,
                  a.align_center,
                  a.justify_center,
                  a.gap_sm,
                ]}>
                <AddEditIcon size={17} />
                <Text
                  style={[a.font_bold, a.text_md, {color: t.palette.black}]}>
                  Post
                </Text>
              </View>
            </Button>
          </View>
        )}
        <Button
          testID="closeBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Close`)}
          accessibilityHint=""
          label={_(msg`Close`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.font_bold, a.text_md]}
          style={[isDesktop ? [a.flex_1, {maxWidth: 220}] : {width: '100%'}]}
        />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  transContent: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  transHeader: {
    alignItems: 'flex-end',
  },
  transTitle: {
    fontSize: 24,
    marginBottom: 20,
  },
  transBtnIcon: {
    marginTop: 20,
    // width: 120,
    // height: 20,
  },
  compeleteIcon: {
    marginTop: 30,
    marginBottom: 10,
  },
  transFooter: {
    marginTop: 20,
    padding: 15,
  },
  confetIcon: {
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  confetIconWeb: {
    // @ts-ignore
    position: 'fixed',
  },
})
