import React from 'react'
import {Image, Pressable, StyleSheet, Text, View} from 'react-native'
import {AppBskyFeedDefs, AtUri} from '@atproto/api'
import {useNavigation} from '@react-navigation/native'

import {Shadow} from '#/state/cache/post-shadow'
import {NavigationProp} from 'lib/routes/types'
import {isNative} from 'platform/detection'
import {atoms as a, useTheme} from '#/alf'
import {ArrowTriangleBottom_Stroke2_Corner1_Rounded as ArrowTriangleBottom} from '#/components/icons/ArrowTriangle'
import {BetDselimiterIcon} from '#/components/icons/StakeIcons'

const betOnIcon = require('../../../../assets/bet_on.png')
const betAgainstIcon = require('../../../../assets/bet_against.png')

type BetProgressProps = {
  onValue: number
  againstValue: number
  post?: Shadow<AppBskyFeedDefs.PostView>
  isMask: boolean
  size?: 'default' | 'small'
  isPreview?: boolean
}

export const BetProgress = (props: BetProgressProps) => {
  const {onValue, againstValue, post, isMask, size, isPreview} = props
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  let onPercent = isMask ? 50 : (onValue * 100) / (againstValue + onValue)
  onPercent = onPercent === 100 ? 50 : onPercent < 10 ? 10 : onPercent
  const againstPercent = 100 - onPercent

  return (
    <View style={[a.flex_1, a.flex_row, a.align_center]}>
      <Pressable
        accessibilityRole="button"
        onPress={() => {
          if (!isPreview && post) {
            navigation.navigate('BetList', {
              did: post?.author.did ?? '',
              rkey: post?.uri ? new AtUri(post?.uri).rkey : '',
              dataType: 'bet-on',
            })
          }
        }}
        style={[
          {
            backgroundColor:
              isMask || onValue > 0 ? t.palette.primary : t.palette.gray_11,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            width: `${onPercent}%`,
          },
        ]}>
        <View
          style={[
            a.flex_row,
            a.relative,
            a.align_center,
            a.justify_center,
            {height: size === 'small' ? 18 : 24},
            {paddingLeft: 10},
          ]}>
          <Image
            testID="betOnIcon"
            source={betOnIcon}
            style={[
              size === 'small' ? styles.smallTypeIcon : styles.typeIcon,
              {left: -10},
            ]}
            accessibilityIgnoresInvertColors
          />
          {!isMask && onValue ? (
            <View style={[a.flex_row, a.align_center]}>
              <Text style={[a.text_xs, a.font_bold, {color: t.palette.white}]}>
                {onValue} USDC
              </Text>
              <ArrowTriangleBottom
                size="xs"
                style={[styles.arrowIcon, {color: t.palette.white}]}
              />
            </View>
          ) : (
            <View style={[styles.blurContainer]}>
              <Text style={[a.text_xs, a.font_bold, {color: t.palette.white}]}>
                XXX USDC
              </Text>
            </View>
          )}
        </View>
      </Pressable>
      <BetDselimiterIcon
        style={[
          styles.defaultDseliIcon,
          size === 'small' && styles.smallDseliIcon,
          styles.dseliIcon,
          {left: `${onPercent - (isNative ? 4.5 : size === 'small' ? 4 : 3)}%`},
        ]}
      />
      <Pressable
        accessibilityRole="button"
        onPress={() => {
          if (!isPreview && post) {
            navigation.navigate('BetList', {
              did: post.author.did,
              rkey: new AtUri(post.uri).rkey,
              dataType: 'bet-against',
            })
          }
        }}
        style={[
          {
            backgroundColor:
              isMask || againstValue > 0
                ? t.palette.yellow_25
                : t.palette.gray_11,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            width: `${againstPercent}%`,
          },
        ]}>
        <View
          style={[
            a.flex_row,
            a.relative,
            a.align_center,
            a.justify_center,
            {height: size === 'small' ? 18 : 24},
          ]}>
          {!isMask && againstValue ? (
            <View style={[a.flex_row, a.align_center]}>
              <Text style={[a.text_xs, a.font_bold, {color: t.palette.white}]}>
                {againstValue} USDC
              </Text>
              <ArrowTriangleBottom
                size="xs"
                style={[styles.arrowIcon, {color: t.palette.white}]}
              />
            </View>
          ) : (
            <View style={[styles.blurContainer]}>
              <Text style={[a.text_xs, a.font_bold, {color: t.palette.white}]}>
                XXX USDC
              </Text>
            </View>
          )}
          <Image
            testID="betAgainstIcon"
            source={betAgainstIcon}
            style={[
              size === 'small' ? styles.smallTypeIcon : styles.typeIcon,
              {right: 0},
            ]}
            accessibilityIgnoresInvertColors
          />
        </View>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  typeIcon: {
    width: 30,
    height: 30,
    position: 'absolute',
  },
  smallTypeIcon: {
    width: 24,
    height: 24,
    position: 'absolute',
  },
  arrowIcon: {
    width: 10,
    height: 10,
    marginLeft: 2,
    zIndex: 2,
  },
  dseliIcon: {
    position: 'absolute',
    zIndex: 2,
    top: isNative ? -5 : -3,
  },
  smallDseliIcon: {
    transform: 'scale(0.7)',
    left: 5,
  },
  defaultDseliIcon: {
    transform: isNative ? 'scale(1)' : 'scale(1.2)',
  },
  blurContainer: {
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    // @ts-ignore
    filter: 'blur(3px)',
    '-webkit-filter': 'blur(3px)',
  },
})
