import React, {useCallback} from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import {Trans} from '@lingui/macro'

import {useSession} from '#/state/session'
import {useComposerControls} from '#/state/shell'
import {
  EngagementEmptyIcon,
  HeartEmptyIcon,
  SearchEmptyIcon,
  TeaEmptyIcon,
} from '#/view/icons/EmptyIcons'
import {useTheme} from '#/alf'

interface IEmptyBox {
  icon: string
  message: string
  isMe?: boolean
  viewStyles?: StyleProp<ViewStyle>
}

export function EmptyBox({icon, message, isMe, viewStyles}: IEmptyBox) {
  const {openComposer} = useComposerControls()
  const {currentAccount} = useSession()
  const iconDom = () => {
    switch (icon) {
      case 'heart':
      case 'notifications':
        return <HeartEmptyIcon />

      case 'engagement':
        return <EngagementEmptyIcon />

      case 'search':
        return <SearchEmptyIcon />

      default:
        return <TeaEmptyIcon />
    }
  }

  const onPressLink = useCallback(() => {
    switch (icon) {
      // case value:

      //   break;

      default:
        openComposer({})
    }
  }, [icon, openComposer])

  const t = useTheme()

  return (
    <View style={[styles.page, viewStyles]}>
      {iconDom()}
      <Text style={styles.message}>{message}</Text>
      {currentAccount?.did && isMe && icon === 'tea' && (
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            justifyContent: 'center',
            height: 48,
            backgroundColor: t.palette.primary,
            paddingHorizontal: 50,
            borderRadius: 100,
            marginTop: 30,
          }}
          onPress={onPressLink}>
          <Text style={styles.link}>
            <Trans>Spill</Trans>
          </Text>
        </TouchableOpacity>
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    width: '100%',
    paddingVertical: 10,
    alignItems: 'center',
  },
  message: {
    fontSize: 16,
    color: '#979797',
  },
  link: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
  },
})
