import React, {useLayoutEffect, useRef, useState} from 'react'
import {
  Keyboard,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import {ComAtprotoServerBindTwitter} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {openXAuthWindow} from '#/lib/authorize/twitter'
import {useTwitter} from '#/lib/hooks/useSocial'
import {isWeb} from '#/platform/detection'
import {ILinkSocialModal, useModalControls} from '#/state/modals'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {Check_Stroke2_Corner0_Rounded as Check} from '#/components/icons/Check'
import {Xicon} from '#/components/icons/Xicon'

export const snapPoints = ['fullscreen']

export function Component({}: ILinkSocialModal) {
  const functionRef = useRef<any>()
  const t = useTheme()
  const {closeModal} = useModalControls()
  const {_} = useLingui()
  const [twitter, setTwitter] =
    useState<ComAtprotoServerBindTwitter.OutputSchema>({
      twitterUserName: '',
      twitterUserId: '',
    })

  const {setBindTwitter} = useTwitter()

  //   const [hint, setHint] = useState<string>()

  useLayoutEffect(() => {
    functionRef.current = async (ev: StorageEvent) => {
      if (ev.key === 'task_x_bind') {
        try {
          const xAccount = JSON.parse(localStorage.getItem('task_x_bind') || '')
          if (xAccount?.twitterUserId) {
            setTwitter(xAccount)
            setBindTwitter(xAccount)
          }
        } catch (err) {
          // TODO : dear with error
          // const _err = err as any
          // if (_err.status === 401) {
          //   setHint('Login status lost. Please reload page and login again')
          //   return
          // }
          // setHint(
          //   _err?.error ||
          //     _err?.message ||
          //     "We can't connect your twitter account. Please try again.",
          // )
        }
      }
    }
  }, [setBindTwitter])

  const bindSocial = () => {
    const handleStorage = functionRef.current
    window.removeEventListener('storage', handleStorage)
    openXAuthWindow('task_x_bind')
    window.addEventListener('storage', handleStorage, false)
  }

  return (
    <TouchableWithoutFeedback
      accessibilityRole="button"
      onPress={() => !isWeb && Keyboard.dismiss()}>
      <View style={[styles.page, a.flex_1]}>
        <View>
          <Text style={styles.title}>
            <Trans>Link social account</Trans>
          </Text>
          <View style={[a.flex, a.flex_col, a.gap_md]}>
            <Text style={{color: t.palette.black_50}}>
              <Trans>
                If you have Two-Step verification enabled, so your account is
                protected with additional password.
              </Trans>
            </Text>
            {!twitter?.twitterUserId && (
              <Button
                variant="solid"
                label={_(msg`Link X (Twitter)`)}
                onPress={() => bindSocial()}
                size="large"
                style={[
                  a.rounded_sm,
                  a.mt_md,
                  {paddingVertical: 10, backgroundColor: t.palette.black},
                ]}>
                <Xicon style={{color: t.palette.white}} />
                <ButtonText style={[a.p_md, {fontSize: 16, fontWeight: 600}]}>
                  <Trans>Link X (Twitter)</Trans>
                </ButtonText>
              </Button>
            )}
            {twitter?.twitterUserId && <TwitterAccount {...twitter} />}
            <Text style={{color: t.palette.black_25}}>
              <Trans>
                The linked identity will not be visible to the public without
                your permissions.
              </Trans>
            </Text>
            <Button
              variant="solid"
              label={_(msg`Link X (Twitter)`)}
              onPress={() => closeModal()}
              size="large"
              style={[
                a.rounded_full,
                a.mt_5xl,
                a.mx_5xl,
                {
                  paddingVertical: 10,
                  backgroundColor: twitter.twitterUserId
                    ? t.palette.primary
                    : t.palette.gray_11,
                },
              ]}>
              {twitter.twitterUserId ? (
                <ButtonText style={[a.p_md, {color: t.palette.black_50}]}>
                  <Trans>Confirm</Trans>
                </ButtonText>
              ) : (
                <ButtonText style={[a.p_md, {color: t.palette.black_50}]}>
                  <Trans>Cancel</Trans>
                </ButtonText>
              )}
            </Button>
          </View>
          <View>{/* <Text>{hint}</Text> */}</View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

function TwitterAccount(props: ComAtprotoServerBindTwitter.OutputSchema) {
  const {twitterUserName} = props
  const t = useTheme()

  return (
    <View
      style={[
        a.flex_row,
        a.align_center,
        a.justify_between,
        a.rounded_md,
        a.border,
        a.p_md,
      ]}>
      <View style={[a.flex_row, a.align_center]}>
        <Xicon style={{color: t.palette.black}} />
        <Text style={[a.p_md, {fontSize: 16, fontWeight: 600}]}>
          <Trans>Link X (Twitter)</Trans>
        </Text>
      </View>
      <View style={[a.flex_row, a.align_center, a.gap_md]}>
        <Text>{twitterUserName}</Text>
        <View
          style={[
            a.align_center,
            a.justify_center,
            a.rounded_full,
            {backgroundColor: '#0ABF52', width: 24, height: 24},
          ]}>
          <Check style={{color: t.palette.white}} size="sm" />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    padding: 10,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
