import React from 'react'
import {StyleSheet, View} from 'react-native'

import {useSession} from '#/state/session'
import {usePalette} from 'lib/hooks/usePalette'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {ProgressGuideList} from '#/components/ProgressGuide/List'
import {DesktopFeeds} from './Feeds'
import {DesktopSearch} from './Search'

export function DesktopRightNav({routeName}: {routeName: string}) {
  const pal = usePalette('default')
  const {hasSession} = useSession()

  const {isTablet} = useWebMediaQueries()
  if (isTablet) {
    return null
  }

  return (
    <View style={[styles.rightNav, pal.view]}>
      <View style={{paddingVertical: 20}}>
        {routeName === 'Search' ? (
          <View style={{marginBottom: 18}}>
            <DesktopFeeds />
          </View>
        ) : (
          <>
            <DesktopSearch />

            {hasSession && (
              <>
                <ProgressGuideList style={[{marginTop: 22, marginBottom: 8}]} />
                <View style={[pal.border, styles.desktopFeedsContainer]}>
                  <DesktopFeeds />
                </View>
              </>
            )}
          </>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  rightNav: {
    // @ts-ignore web only
    position: 'fixed',
    // @ts-ignore web only
    left: 'calc(50vw + 300px + 20px)',
    width: 300,
    maxHeight: '100%',
    overflowY: 'auto',
  },

  message: {
    paddingVertical: 18,
    paddingHorizontal: 12,
  },
  messageLine: {
    marginBottom: 10,
  },
  desktopFeedsContainer: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginTop: 18,
    marginBottom: 18,
  },
})
