import React from 'react'
import {ScrollView, StyleSheet, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {Capsule} from '../../posts/TargetDisplay'
import {usePostTargetStore} from '../../util/sdlStore/PostTargetStore'
import {ITargetItem} from '../../util/sdlStore/targetDate'

interface ITargetPreview {
  previewArray: ITargetItem[]
}

export function TargetPreview({previewArray}: ITargetPreview) {
  const {isMobile} = useWebMediaQueries()
  const {onDeleteTarget} = usePostTargetStore()

  if (!previewArray.length) return null
  return (
    <View>
      <Text
        style={{
          color: '#979797',
          fontSize: 12,
          fontWeight: 700,
          marginBottom: 10,
        }}>
        <Trans>Spill about:</Trans>
      </Text>
      <ScrollView showsHorizontalScrollIndicator={isMobile}>
        <View style={styles.page}>
          {previewArray.map((item, index) => {
            return (
              <Capsule
                key={String(index)}
                name={item.name}
                type={item.type}
                avatar={item.logo}
                onDelete={() => onDeleteTarget(item)}
              />
            )
          })}
        </View>
      </ScrollView>
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: 60,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    paddingRight: 8,
    marginRight: 10,
    borderRadius: 20,
  },
  image: {
    width: 16,
    height: 16,
    marginRight: 10,
    borderRadius: 16,
  },
})
