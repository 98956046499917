import React from 'react'
import {ActivityIndicator, View} from 'react-native'

import {s} from 'lib/styles'
import {atoms as a, useTheme} from '#/alf'
import {CenteredView} from './Views'

export function LoadingScreen() {
  const t = useTheme()
  return (
    <CenteredView style={[s.flex1, a.justify_center]}>
      <View style={[s.p20]}>
        <ActivityIndicator size="large" color={t.palette.primary} />
      </View>
    </CenteredView>
  )
}
