import {useSyncExternalStore} from 'react'

import {createStore} from './SdlStore'

const store = createStore({
  tabName: '',
})

const {subscribe, getState, setState: setProfile} = store

export function useProfileStore() {
  const profileStore = useSyncExternalStore(subscribe, getState)
  const setTabName = (tabName: string) => {
    setProfile({tabName})
  }
  return {
    profileStore,
    setTabName,
  }
}
