import React, {useState} from 'react'
import {View} from 'react-native'
import {StyleSheet, Text} from 'react-native'
import {Trans} from '@lingui/macro'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {atoms as a} from '#/alf'
import {Button, ButtonIcon} from '#/components/Button'
import {Loader} from '#/components/Loader'
import {SdlInput} from '../../composer/text-input/SdlTextInput'
import {SdlButton} from '../../util/SdlButton'

interface IProps {
  onLogin: (code: string) => void
}
export function LoginCode(props: IProps) {
  const {isDesktop} = useWebMediaQueries()
  const {closeModal} = useModalControls()
  const {onLogin} = props
  const [code, setCode] = useState<string[]>(Array.from({length: 5}, () => ''))
  const [focusIndex, setFocusIndex] = useState<number>(0)
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   if (code.filter(Boolean).length === 5 && /\d+/.test(code.join(''))) {
  //     onLogin(code.join(''))
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [code])

  const onConfirm = () => {
    if (code.filter(Boolean).length === 5 && /\d+/.test(code.join(''))) {
      setLoading(true)
      onLogin(code.join(''))
    }
  }

  return (
    <View>
      <View>
        <Text style={styles.title}>
          <Trans>Telegram login code</Trans>
        </Text>
        <View style={a.mb_lg}>
          <Text style={a.mb_2xl}>
            <Trans>
              Send the code to the Telegram app on your other device
            </Trans>
          </Text>
          <View style={{...a.flex, ...a.justify_between, ...a.flex_row}}>
            {code.map((item, index) => (
              <SdlInput
                key={index}
                value={item}
                maxLength={1}
                keyboardType="numeric"
                placeholder={''}
                focus={index === focusIndex}
                style={{
                  width: 50,
                  height: 50,
                  ...a.rounded_sm,
                  ...a.text_center,
                  ...a.font_bold,
                }}
                setValue={val => {
                  setFocusIndex(index + 1)
                  setCode(c => {
                    c.splice(index, 1, val)
                    return [...c]
                  })
                }}
              />
            ))}
          </View>
        </View>
      </View>

      <View
        style={[{gap: 12, marginTop: 16}, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          color="primary"
          // size="large"
          variant="solid"
          disabled={loading}
          label="Confirm"
          style={[a.flex_1, a.rounded_full]}
          onPress={onConfirm}>
          <Text style={[a.font_bold, a.text_md]}>
            <Trans>Confirm</Trans>
          </Text>
          {loading && <ButtonIcon size="md" icon={Loader} />}
        </Button>
        <SdlButton
          disabled={loading}
          type="secondary"
          text="Cancel"
          onPress={closeModal}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    padding: 10,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
