import React, {useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {PUBLIC_ENV_CHAINID} from '#/lib/constants'
import {stake} from '#/lib/ethers/stake'
import {usePalette} from '#/lib/hooks/usePalette'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {
  useCreateTransMutation,
  useUpdateTransMutation,
} from '#/state/queries/transaction-bet'
import {useSession} from '#/state/session'
import {useWallet} from '#/state/shell/wallet'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
// import {cleanError} from 'lib/strings/errors'
import * as Toast from 'view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {TokenInput} from '../auth/util/TextInput'
import {StakeDonateModalProps} from '../modals/StakeDonate'
// import {ErrorMessage} from '../util/error/ErrorMessage'
import {Button} from '../util/forms/Button'
import {simplifyMsg} from '../util/Transaction'

export const StakeTab = (props: StakeDonateModalProps) => {
  const {setParentError, setParentPending, setParentAmount} = props
  const pal = usePalette('default')
  const {_} = useLingui()
  const t = useTheme()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {openModal, closeModal} = useModalControls()
  const {provider, currentWallet} = useWallet()
  const createTransMutation = useCreateTransMutation()
  const updateTransMutation = useUpdateTransMutation()
  const {currentAccount} = useSession()

  const postId = `${props.transPostAuthor?.did?.split(':')?.[2]}/${props.rKey}`
  const uri = makeRecordUri(
    props.transPostAuthor?.did,
    'app.bsky.feed.post',
    props.rKey,
  )

  const [amount, setAmount] = useState<string | undefined>(props.amount ?? '')
  const [pending, setPending] = useState<boolean>(props?.pending ?? false)
  const [error, setError] = useState<string | undefined>(
    props?.error ?? undefined,
  )
  const [confirmLabel, setConfirmLabel] = useState<string | undefined>(
    'Confirm',
  )

  async function onStake() {
    console.log('onStake-click', currentWallet, provider, PUBLIC_ENV_CHAINID)
    try {
      // const accounts = await switchAccount(provider);
      // console.log('accounts------', accounts);
      if (!currentWallet?.publicKey) {
        openModal({name: 'connect-wallet', type: 'connect'})
        return
      }
      setPending(true)
      setParentPending?.(true)

      const tx = await stake(
        provider,
        Number(amount),
        PUBLIC_ENV_CHAINID,
        postId,
      )
      console.log('onStakeHandler', tx)
      if (tx) {
        try {
          const res = await createTransMutation.mutateAsync({
            param: {
              id: tx?.hash,
              payer: currentAccount?.did ?? '',
              method: 'insider-stake.stake',
            },
          })
          console.log('createTransMutation', res)
        } catch (error) {
          console.log('createTransMutation-error', error)
        }
        const reception = await tx.wait()
        console.log('unStakeHandler-reception', reception)
        // if (reception?.transactionHash) { // ethers5
        if (reception?.hash) {
          // ethers6
          try {
            const res = await updateTransMutation.mutateAsync({
              param: {
                repo: currentAccount?.did ?? '',
                collection: 'app.bsky.feed.stake',
                record: {
                  uri: uri,
                  transId: reception?.hash,
                  method: 'insider-stake.stake',
                  amount: Number(amount) * 1000000,
                },
              },
            })
            console.log('updateTransMutation', res)
          } catch (error) {
            console.log('updateTransMutation-error', error)
          }
          resSet()
          setAmount(undefined)
          closeModal()
          openModal({name: 'trans-result', type: 'stake', amount})
        }
      }
    } catch (err) {
      logger.error(`stake:`, {message: err})
      resSet()
      let reason =
        (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
      reason = simplifyMsg(reason)
      if (reason) {
        Toast.show(`Transaction failed: ${reason}`, 'xmark')
      } else {
        Toast.show(
          _(msg`Transaction failed: this operation caused some error`),
          'xmark',
        )
      }
    }
  }

  function resSet() {
    setError(undefined)
    setPending(false)
    setParentPending?.(false)
    setParentError?.(undefined)
    setParentAmount?.(undefined)
  }

  async function onConfirm() {
    if (!amount || isNaN(Number(amount))) {
      setError('Input amount')
      setParentError?.('Input amount')
      return
    } else if (Number(amount) < 5) {
      setError('Invalid amount')
      setParentError?.('Invalid amount')
      setConfirmLabel('Minimum of 5 USDC')
      return
    }
    setConfirmLabel('Confirm')
    if (props.type === 'create-post-stake') {
      if (!currentWallet?.publicKey) {
        openModal({name: 'connect-wallet', type: 'connect'})
        return
      }
      closeModal()
    } else {
      await onStake()
    }
  }

  // console.log('stakeTabPanel', currentWallet?.publicKey)

  return (
    <View style={[pal.view, a.mt_xl, isMobile && {paddingHorizontal: 18}]}>
      <TokenInput
        testID="stakeAmount"
        tokenSymbol="USDC"
        placeholder="0.00"
        value={amount}
        isError={!!error}
        onChange={value => {
          let data = value
          if (data && Number(data) > 1000000000000) {
            data = '1000000000000'
          }
          setAmount(data)
          setError(undefined)

          setParentAmount?.(data)
          setParentError?.(undefined)
        }}
      />
      {/* {error && error !== '' ? (
        <ErrorMessage message={cleanError(error)} style={styles.error} />
      ) : undefined} */}
      <View style={[a.mt_xl, a.gap_md]}>
        <View style={[a.flex_row, a.align_center]}>
          <View style={styles.dot} />
          <Text style={[a.text_xs]}>
            Stake USDC to show your confidence. The more you stake, the more
            exposure you will get.
          </Text>
        </View>
        <View style={[a.flex_row, a.align_center]}>
          <View style={styles.dot} />
          <Text style={[a.text_xs]}>
            You can withdraw your funds 24 hours after your last stake for this
            post (2% will be deducted as service fee).
          </Text>
        </View>
        <View style={[a.flex_row, a.align_center]}>
          <View style={styles.dot} />
          <Text style={[a.text_xs]}>
            Posts confirmed by the Community Committee to violate{' '}
            <Text style={[a.underline, {color: t.palette.primary}]}>
              Community Guidelines
            </Text>{' '}
            will incur a 20% penalty on the staked amount.
          </Text>
        </View>
      </View>
      <View style={[a.mt_xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="default"
          disabled={pending || !amount}
          withLoading={true}
          accessibilityLabel={_(msg`${pending ? 'Pending' : confirmLabel}`)}
          accessibilityHint=""
          label={_(msg`${pending ? 'Pending' : confirmLabel}`)}
          onPress={onConfirm}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
            {opacity: pending || !amount ? 0.8 : 1},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Cancel`)}
          accessibilityHint=""
          label={_(msg`Cancel`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dot: {
    width: 5,
    height: 5,
    backgroundColor: '#666',
    borderRadius: 5,
    marginRight: 5,
  },
  error: {
    borderRadius: 6,
    marginTop: 10,
  },
})
