import React, {useEffect, useState} from 'react'
import {Image, SafeAreaView, StyleSheet, Text, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import dayjs from 'dayjs'

import {PUBLIC_ENV_CHAINID} from '#/lib/constants'
import {joinBet} from '#/lib/ethers/bet'
import {usePalette} from '#/lib/hooks/usePalette'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {
  useCreateTransMutation,
  useUpdateTransMutation,
} from '#/state/queries/transaction-bet'
import {useSession} from '#/state/session'
import {useWallet} from '#/state/shell/wallet'
import {JudgeModeType} from '#/state/transaction/bet'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
// import {cleanError} from 'lib/strings/errors'
import {TokenMinMaxInput} from '#/view/com/auth/util/TextInput'
import * as Toast from 'view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import * as Toggle from '#/components/forms/Toggle'
// import {Link} from '#/components/Link'
// import {ErrorMessage} from '../util/error/ErrorMessage'
import {Button} from '../util/forms/Button'
import {simplifyMsg} from '../util/Transaction'

const betOnIcon = require('../../../../assets/bet_on.png')
const betAgainstIcon = require('../../../../assets/bet_against.png')

const MIN_VALUE = 5
const MAX_VALUE = 5000
const STEP_VALUE = 5
export const snapPoints = ['50%']

export type TransPostAuthorProps = {
  did: string
  payWallet?: string | undefined
  avatar: string
  displayName: string
}
export type BetModalType =
  | 'join-bet'
  | 'challenge'
  | 'create-bet'
  | 'create-bet-request'
export type BetType = 'on' | 'against'
export interface BetModalProps {
  type: BetModalType
  rKey?: string
  transPostAuthor?: TransPostAuthorProps
  endTime?: number
  betType?: BetType
  amount?: number | undefined
  error?: string | undefined
  pending?: boolean
  publicSide?: boolean
}

export interface BetToEarnModalProps extends BetModalProps {
  setParentAmount?: (value: number | undefined) => void
  setParentBetType?: (value: 'on' | 'against') => void
  setParentError?: (value: string | undefined) => void
  setParentPending?: (value: boolean) => void
  setResponse?: (value: {success: boolean; hash?: string} | undefined) => void
  parentEndTime?: number | undefined
  setParentEndTime?: (value: number | undefined) => void
  parentJudgeMode?: JudgeModeType
  setParentJudgeMode?: (value: JudgeModeType) => void
  parentPublicSide?: boolean
  setParentPublicSide?: (value: boolean) => void
}

export const defaultProps = {
  type: 'create-bet',
  rKey: '',
  transPostAuthor: undefined,
  endTime: undefined,
  betType: 'on',
  amount: undefined,
  error: undefined,
}

export const Component = function BetToEarnModalComponent(
  props: BetToEarnModalProps,
) {
  const {
    type,
    rKey,
    transPostAuthor,
    setParentBetType,
    setParentAmount,
    setParentError,
    setParentPending,
    setResponse,
  } = props
  const {_} = useLingui()
  const t = useTheme()
  const pal = usePalette('default')
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {provider, currentWallet} = useWallet()
  const {openModal, closeModal, closeAllModals} = useModalControls()
  const createTransMutation = useCreateTransMutation()
  const updateTransMutation = useUpdateTransMutation()
  const {currentAccount} = useSession()

  const postId = `${transPostAuthor?.did?.split(':')?.[2]}/${rKey}`
  const uri = makeRecordUri(
    transPostAuthor?.did ?? '',
    'app.bsky.feed.post',
    rKey ?? '',
  )

  const initChallengeValue = props.amount ?? 5

  const [betType, setBetType] = useState<BetType[]>(
    props.betType ? [props.betType] : ['on'],
  )
  const [amount, setAmount] = useState<number | undefined>(props.amount)
  const [error, setError] = useState<string | undefined>(props.error)
  const [pending, setPending] = useState<boolean>(props.pending ?? false)
  const [confirmLabel, setConfirmLabel] = useState<string | undefined>(
    type === 'create-bet' ? 'Next' : 'Confirm',
  )
  const [showPreview, setShowPreview] = useState<boolean>(false)

  const onConfirm = async () => {
    if (type === 'join-bet') {
      await onBet()
    } else if (type === 'challenge') {
      setResponse?.({
        success: true,
        hash: 'sss',
      })
      // return betData;
      // if (!betData.amount) {
      //   setParentError('Input amount')
      //   return
      // } else if (betData.amount < initChallengeValue) {
      //   setParentError('Invalid amount')
      //   return
      // }
      closeModal()
    } else if (type === 'create-bet') {
      // console.log('onConfirm', amount)
      if (!amount) {
        setError('Input amount')
        setParentError?.('Input amount')
        return
      } else if (amount < 5) {
        setError('Invalid amount')
        setParentError?.('Invalid amount')
        setConfirmLabel('Minimum of 5 USDC')
        return
      }
      if (!props.endTime) {
        setError('Input endTime')
        setParentError?.('Invalid endTime')
        return
      }
      setConfirmLabel('Confirm')
      if (!currentWallet?.publicKey) {
        openModal({name: 'connect-wallet', type: 'connect'})
        return
      }
      if (showPreview) {
        setParentAmount?.(amount)
        closeAllModals()
      } else {
        setShowPreview(true)
      }
    } else if (type === 'create-bet-request') {
      // onCreateRequest();
      if (!amount) {
        setParentError?.('Input amount')
        return
      } else if (amount < 5) {
        setParentError?.('Invalid amount')
        setConfirmLabel('Minimum of 5 USDC')
        return
      }
      setConfirmLabel('Confirm')
    }
  }

  const onCancel = () => {
    if (type === 'create-bet') {
      if (showPreview) {
        setShowPreview(false)
      } else {
        setAmount(undefined)
        setError(undefined)

        setParentAmount?.(undefined)
        setParentError?.(undefined)
        // back to set-bet
        closeModal()
      }
    } else {
      closeModal()
    }
  }

  async function onBet() {
    console.log('onBet-click', currentWallet, amount)
    try {
      if (!currentWallet?.publicKey) {
        openModal({name: 'connect-wallet', type: 'connect'})
        return
      }
      // if (betType.length <= 0) {
      //   setError('Select bet on or bet against')
      //   setParentError?.('Input amount')
      //   return
      // }
      if (!amount) {
        setError('Input amount')
        setParentError?.('Input amount')
        return
      } else if (amount < 5) {
        setError('Invalid amount')
        setParentError?.('Invalid amount')
        setConfirmLabel('Minimum of 5 USDC')
        return
      }
      setConfirmLabel('Confirm')
      setParentPending?.(true)
      setPending(true)
      const tx = await joinBet(
        provider,
        amount,
        PUBLIC_ENV_CHAINID,
        postId,
        betType?.[0],
      )
      console.log('onBet', tx)
      if (tx) {
        try {
          const res = await createTransMutation.mutateAsync({
            param: {
              id: tx?.hash,
              payer: currentAccount?.did ?? '',
              method: 'insider-bet.bet',
              payload: JSON.stringify({
                side: betType?.[0] === 'on' ? true : false,
              }),
            },
          })
          console.log('createTransMutation', res)
        } catch (error) {
          console.log('createTransMutation-error', error)
        }
        const reception = await tx.wait()
        console.log('onBetHandler-reception', reception)
        // if (reception?.transactionHash) { // ethers5
        if (reception?.hash) {
          // ethers6
          try {
            const res = await updateTransMutation.mutateAsync({
              param: {
                repo: currentAccount?.did ?? '',
                collection: 'app.bsky.feed.bet',
                record: {
                  uri: uri,
                  transId: reception?.hash,
                  method: 'insider-bet.bet',
                  // amount: Number(amount),
                },
              },
            })
            console.log('updateTransMutation', res)
          } catch (error) {
            console.log('updateTransMutation-error', error)
          }
          setParentPending?.(false)
          setPending(false)
          setResponse?.({
            success: true,
            hash: reception?.hash,
          })
          closeModal()
          // only join-bet show success modal
          if (type === 'join-bet') {
            openModal({name: 'trans-result', type: 'earn-bet', amount})
          }
        }
      }
    } catch (err) {
      logger.error(`bet:`, {message: err})
      setParentPending?.(false)
      setPending(false)
      setResponse?.({
        success: false,
      })
      let reason =
        (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
      reason = simplifyMsg(reason)
      if (reason) {
        Toast.show(`Transaction failed: ${reason}`, 'xmark')
      } else {
        Toast.show(
          _(msg`Transaction failed: this operation caused some error`),
          'xmark',
        )
      }
    }
  }

  const Preview = () => {
    return (
      <View style={styles.previewContainer}>
        <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
          Bet details:
        </Text>
        <View style={[a.flex_row, a.justify_between, a.align_center]}>
          <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
            Your Pre-betting
          </Text>
          <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
            Bet {betType} {amount} USDC
          </Text>
        </View>
        <View style={[a.flex_row, a.justify_between, a.align_center]}>
          <Text style={[a.text_sm, {color: t.palette.gray_10}]}>Deadline</Text>
          <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
            {props.endTime
              ? dayjs(props.endTime * 1000).format('HH:mm on MMM DD, YYYY')
              : ''}
          </Text>
        </View>
        <View style={[a.flex_row, a.justify_between, a.align_center]}>
          <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
            Result determination
          </Text>
          <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
            Popularity
          </Text>
        </View>
        <View style={[a.flex_row, a.justify_between, a.align_center]}>
          <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
            Public the amounts for each side
          </Text>
          <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
            {props?.publicSide ? 'Yes' : 'No'}
          </Text>
        </View>
      </View>
    )
  }

  useEffect(() => {
    setAmount(props.amount)
  }, [props.amount])

  // console.log('betData.betType', betType, amount, props.endTime)

  return (
    <SafeAreaView
      testID="stakeDonateModal"
      style={[pal.view, isMobile && {paddingHorizontal: 18}]}>
      <Text
        style={[
          a.text_xl,
          a.font_bold,
          a.flex,
          a.justify_center,
          a.text_center,
        ]}>
        {showPreview ? 'Preview' : 'Bet to earn'}
      </Text>
      {showPreview ? (
        <Preview />
      ) : (
        <View style={[a.mt_3xl]}>
          {type === 'challenge' && (
            <Text style={[a.text_sm, a.font_semibold, a.mb_xl]}>
              The spiller has bet {initChallengeValue} USDC. To challenge, you
              need to{' '}
              <Text style={[a.font_bold]}>
                bet at least {initChallengeValue} USDC on the "Bet against"
              </Text>
            </Text>
          )}
          <Toggle.Group
            label={_(msg``)}
            type="radio"
            values={betType}
            // disabled={type === 'challenge' || type === 'create-bet'}
            onChange={(keys: string[]) => {
              const key = keys[0]
              if (!key) return
              setBetType([key as 'on' | 'against'])
              setParentBetType?.(key as 'on' | 'against')
            }}>
            <View
              style={[a.flex, a.flex_row, a.align_center, a.justify_center]}>
              <Toggle.Item
                name="on"
                label={_(msg`Bet on`)}
                disabled={type === 'challenge'}
                type="radio"
                style={[a.flex_1, a.pl_md]}>
                <Toggle.Radio />
                <Image
                  testID="betOnIcon"
                  source={betOnIcon}
                  style={[styles.typeIcon]}
                  accessibilityIgnoresInvertColors
                />
                <Toggle.LabelText style={[a.text_md]}>
                  <Trans>Bet on</Trans>
                </Toggle.LabelText>
              </Toggle.Item>

              <Toggle.Item
                name="against"
                label={_(msg`Bet against`)}
                disabled={type === 'create-bet'}
                type="radio"
                style={[a.flex_1]}>
                <Toggle.Radio />
                <Image
                  testID="betAgainstIcon"
                  source={betAgainstIcon}
                  style={[styles.typeIcon]}
                  accessibilityIgnoresInvertColors
                />
                <Toggle.LabelText style={[a.text_md]}>
                  <Trans>Bet against</Trans>
                </Toggle.LabelText>
              </Toggle.Item>
            </View>
          </Toggle.Group>
          <View style={[a.mt_lg, a.w_full, a.flex_col]}>
            <TokenMinMaxInput
              step={STEP_VALUE}
              min={type === 'challenge' ? initChallengeValue : MIN_VALUE}
              max={MAX_VALUE}
              placeholder={'0.00'}
              value={amount}
              isError={!!error}
              onChange={value => {
                setAmount(value)
                setError(undefined)

                setParentAmount?.(value)
                setParentError?.(undefined)
              }}
            />
            {/* {error && error !== '' ? (
              <ErrorMessage message={cleanError(error)} style={styles.error} />
            ) : undefined} */}
          </View>
          {/* <Text style={[a.text_xs, a.py_lg, {color: t.palette.contrast_500}]}>
          The amount must be an integer multiple of 5
        </Text> */}
          {(type === 'join-bet' || type === 'challenge') && (
            <View style={[a.pt_lg]}>
              {/* <Link action="navigate" to={'#'} label=""> */}
              <Text
                style={[
                  a.text_xs,
                  a.font_bold,
                  {color: t.palette.primary, textDecorationLine: 'underline'},
                ]}>
                Learn more about the rules
              </Text>
              {/* </Link> */}
            </View>
          )}
          {type === 'create-bet-request' && (
            <View>
              <Text
                style={[a.text_xs, a.pt_lg, {color: t.palette.contrast_500}]}>
                If approved within 24 hours, your bet will take effect.
              </Text>
              <Text
                style={[a.text_xs, a.py_lg, {color: t.palette.contrast_500}]}>
                If rejected or not approved, you will be notified to withdraw
              </Text>
            </View>
          )}
        </View>
      )}
      <View style={[a.mt_5xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="primary"
          disabled={pending}
          withLoading={true}
          accessibilityLabel={_(msg`${pending ? 'Pending' : confirmLabel}`)}
          accessibilityHint=""
          label={_(msg`${pending ? 'Pending' : confirmLabel}`)}
          onPress={onConfirm}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={onCancel}
          accessibilityLabel={_(
            msg`${type === 'create-bet' ? 'Previous' : 'Cancel'}`,
          )}
          accessibilityHint=""
          label={_(msg`${type === 'create-bet' ? 'Previous' : 'Cancel'}`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  typeIcon: {
    width: 30,
    height: 30,
  },
  error: {
    borderRadius: 6,
    marginTop: 10,
  },
  spinner: {
    flex: 1,
    marginRight: 10,
    minWidth: 150,
  },
  previewContainer: {
    marginTop: 10,
    paddingHorizontal: 10,
    paddingVertical: 15,
    backgroundColor: '#F7F7F7',
    borderRadius: 6,
    gap: 10,
  },
})
