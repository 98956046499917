import React from 'react'
import {StyleProp, ViewStyle} from 'react-native'
import {Path} from 'react-native-svg'

import {useTheme} from '#/alf'
import {SdlSVG, useThemeIconColor} from './ProfileIcons'

interface TopBarIconsProps {
  size?: number
  style?: StyleProp<ViewStyle>
  active?: boolean
  leftNavColor?: string
}

export const MenuIcon = (props: TopBarIconsProps) => {
  const color = useThemeIconColor('#15161B', '#ffffff')
  return (
    <SdlSVG
      defaultSize={24}
      size={props.size}
      style={[{transform: [{translateY: 2}]}, props.style]}>
      <Path d="M3 5H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <Path d="M3 12H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <Path d="M3 19H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </SdlSVG>
  )
}

export const BookMarkIcon = (props: TopBarIconsProps) => {
  const color = useThemeIconColor('#333333', '#ffffff')
  const t = useTheme()
  return (
    <SdlSVG
      defaultSize={24}
      size={props.size}
      style={[{transform: [{translateY: 1}]}, props.style]}>
      {props?.active ? (
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 19.5035V6.99832C4.99707 5.34078 6.34078 3.99707 7.99832 3.99707H16.0017C17.6592 3.99707 19.0029 5.34078 19.0029 6.99832V19.5035C19.0031 19.6911 18.8983 19.8629 18.7316 19.9487C18.5648 20.0345 18.3641 20.0198 18.2116 19.9107L12.2911 15.6809C12.117 15.5563 11.883 15.5563 11.7089 15.6809L5.7884 19.9097C5.63605 20.0187 5.43554 20.0335 5.26887 19.9479C5.10219 19.8624 4.99729 19.6909 4.99707 19.5035Z"
          fill={props?.leftNavColor ?? t.palette.primary}
          stroke={props?.leftNavColor ?? t.palette.primary}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 19.5035V6.99832C4.99707 5.34078 6.34078 3.99707 7.99832 3.99707H16.0017C17.6592 3.99707 19.0029 5.34078 19.0029 6.99832V19.5035C19.0031 19.6911 18.8983 19.8629 18.7316 19.9487C18.5648 20.0345 18.3641 20.0198 18.2116 19.9107L12.2911 15.6809C12.117 15.5563 11.883 15.5563 11.7089 15.6809L5.7884 19.9097C5.63605 20.0187 5.43554 20.0335 5.26887 19.9479C5.10219 19.8624 4.99729 19.6909 4.99707 19.5035Z"
          stroke={color}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </SdlSVG>
  )
}
