import React, {useEffect, useState} from 'react'
import {SafeAreaView, StyleSheet, Text, View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {ethers} from 'ethers'

import {PUBLIC_ENV_CHAINID} from '#/lib/constants'
import {getStake, withdraw} from '#/lib/ethers/stake'
import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {
  useCreateTransMutation,
  useUpdateTransMutation,
} from '#/state/queries/transaction-bet'
import {useSession} from '#/state/session'
import {useWallet} from '#/state/shell/wallet'
import * as Toast from 'view/com/util/Toast'
import {formatWalletAddress} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '../util/forms/Button'
import {TransPostAuthorProps} from './BetToEarn'

export type WithdrawPreviewModalProps = {
  rKey: string
  transPostAuthor: TransPostAuthorProps
}

export const Component = function WithdrawPreviewModalComponent(
  props: WithdrawPreviewModalProps,
) {
  const {rKey, transPostAuthor} = props
  const pal = usePalette('default')
  const t = useTheme()
  const {_} = useLingui()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {openModal, closeModal} = useModalControls()
  const createTransMutation = useCreateTransMutation()
  const updateTransMutation = useUpdateTransMutation()
  const {currentAccount} = useSession()
  const {provider, currentWallet} = useWallet()
  const postId = `${transPostAuthor.did?.split(':')?.[2]}/${rKey}`
  const uri = makeRecordUri(
    transPostAuthor.did ?? '',
    'app.bsky.feed.post',
    rKey,
  )

  const [pending, setPending] = useState<boolean>(false)
  const [tradeAmount, setTradeAmount] = useState<string>('')
  const [amount, setAmount] = useState<number | undefined>(undefined)
  const [fee, setFee] = useState<number | undefined>(undefined)

  async function onStakeWithdrawFromPost() {
    try {
      if (!currentWallet?.publicKey) {
        openModal({name: 'connect-wallet', type: 'connect'})
        return
      }
      if (!amount) {
        Toast.show(`No balance available for withdrawal`)
        return
      }
      console.log('onWithdrawAll', currentWallet, postId)
      setPending(true)
      const tx = await withdraw(
        provider,
        PUBLIC_ENV_CHAINID,
        postId,
        tradeAmount,
      )
      console.log('onWithdrawAll', tx, tradeAmount)
      if (tx) {
        try {
          const res = await createTransMutation.mutateAsync({
            param: {
              id: tx?.hash,
              payer: currentAccount?.did ?? '',
              method: 'insider-stake.withdraw',
            },
          })
          console.log('createTransMutation', res)
        } catch (error) {}
        const reception = await tx.wait()
        console.log('unStakeHandler-reception', reception)
        // if (reception?.transactionHash) { // ethers5
        if (reception?.hash) {
          // ethers6
          try {
            const res = await updateTransMutation.mutateAsync({
              param: {
                repo: currentAccount?.did ?? '',
                collection: 'app.bsky.feed.stake',
                record: {
                  uri: uri,
                  transId: reception?.hash,
                  method: 'insider-stake.withdraw',
                  // amount: Number(ethers.formatUnits(remainAmount, 6)),
                },
              },
            })
            console.log('updateTransMutation', res)
          } catch (error) {}
          setPending(false)
          closeModal()
          openModal({
            name: 'trans-result',
            type: 'stake-withdraw',
            amount,
            txId: reception?.hash,
          })
        }
      }
    } catch (err) {
      logger.error(`withdraw`, {message: err})
      setPending(false)
      const reason =
        (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
      if (reason) {
        Toast.show(`Transaction failed: ${reason}`, 'xmark')
      } else {
        Toast.show(
          _(msg`Transaction failed: this operation caused some error`),
          'xmark',
        )
      }
    }
  }

  useEffect(() => {
    getStake(provider, PUBLIC_ENV_CHAINID, postId).then(res => {
      setTradeAmount(res)
      const data = Number(ethers.formatUnits(res, 6))
      setAmount(Number((data * 0.98).toFixed(2)))
      setFee(Number((data * 0.02).toFixed(2)))
    })
  }, [postId, provider])

  console.log('withdrawPreview', tradeAmount, amount, fee, transPostAuthor)

  return (
    <SafeAreaView
      testID="stakeDonateModal"
      style={[pal.view, isMobile && {paddingHorizontal: 18}]}>
      <Text
        style={[
          a.text_xl,
          a.font_bold,
          a.flex,
          a.justify_center,
          a.text_center,
        ]}>
        Preview
      </Text>
      <View style={styles.previewContainer}>
        <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
          Withdraw details:
        </Text>
        <View style={[a.flex_row, a.justify_between, a.align_center]}>
          <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
            Withdraw to
          </Text>
          <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
            {formatWalletAddress(currentWallet?.publicKey ?? '')}
          </Text>
        </View>
        <View style={[a.flex_row, a.justify_between, a.align_center]}>
          <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
            Receive amount
          </Text>
          <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
            {amount} USDC
          </Text>
        </View>
        <View style={[a.flex_row, a.justify_between, a.align_center]}>
          <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
            Service fee
          </Text>
          <Text style={[a.text_sm, a.font_bold, {color: t.palette.black_50}]}>
            {fee} USDC
          </Text>
        </View>
      </View>
      <View style={[a.mt_5xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="default"
          disabled={pending}
          withLoading={true}
          accessibilityLabel={_(msg`${pending ? 'Pending' : 'Confirm'}`)}
          accessibilityHint=""
          label={_(msg`${pending ? 'Pending' : 'Confirm'}`)}
          onPress={onStakeWithdrawFromPost}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`${'Cancel'}`)}
          accessibilityHint=""
          label={_(msg`${'Cancel'}`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  previewContainer: {
    marginTop: 10,
    paddingHorizontal: 10,
    paddingVertical: 15,
    backgroundColor: '#F7F7F7',
    borderRadius: 6,
    gap: 10,
  },
})
