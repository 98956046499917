import React, {memo, useCallback} from 'react'
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from 'react-native'
import {AppBskyActorDefs, ModerationDecision, ModerationUI} from '@atproto/api'
import {useQueryClient} from '@tanstack/react-query'

import {useGetTimeAgo} from '#/lib/hooks/useTimeAgo'
import {precacheProfile} from '#/state/queries/profile'
import {useTickEveryMinute} from '#/state/shell'
import {usePalette} from 'lib/hooks/usePalette'
import {makeProfileLink} from 'lib/routes/links'
import {forceLTR} from 'lib/strings/bidi'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {TypographyVariant} from 'lib/ThemeContext'
import {formatHandler} from '#/screens/Onboarding/util'
import {RelationshipTag} from '../posts/RelationshipTag'
import {TextLinkOnWebOnly} from './Link'
import {Text} from './text/Text'
import {PreviewableUserAvatar} from './UserAvatar'

interface PostMetaOpts {
  author: AppBskyActorDefs.ProfileViewBasic
  moderation: ModerationDecision | undefined
  authorHasWarning: boolean
  postHref: string
  timestamp: string
  showAvatar?: boolean
  avatarModeration?: ModerationUI
  avatarSize?: number
  displayNameType?: TypographyVariant
  displayNameStyle?: StyleProp<TextStyle>
  onOpenAuthor?: () => void
  style?: StyleProp<ViewStyle>
  rightButton?: () => React.ReactNode
}

let PostMeta = (opts: PostMetaOpts): React.ReactNode => {
  const pal = usePalette('default')
  const displayName =
    opts.author.displayName || formatHandler(opts.author.handle)
  const profileLink = makeProfileLink(opts.author)
  const queryClient = useQueryClient()
  const onOpenAuthor = opts.onOpenAuthor
  const onBeforePressAuthor = useCallback(() => {
    precacheProfile(queryClient, opts.author)
    onOpenAuthor?.()
  }, [queryClient, opts.author, onOpenAuthor])

  const ago = useGetTimeAgo()
  const tick = useTickEveryMinute()

  const time = ago(opts.timestamp, tick)

  return (
    <View style={[styles.container, opts.style, {alignItems: 'flex-start'}]}>
      {opts.showAvatar && (
        <View style={styles.avatar}>
          <PreviewableUserAvatar
            size={opts.avatarSize || 16}
            profile={opts.author}
            moderation={opts.avatarModeration}
            type={opts.author.associated?.labeler ? 'labeler' : 'user'}
          />
        </View>
      )}
      <Text
        numberOfLines={1}
        style={[styles.maxWidth, pal.textLight, opts.displayNameStyle]}>
        <TextLinkOnWebOnly
          type={opts.displayNameType || 'lg-bold'}
          style={[pal.text]}
          lineHeight={1.2}
          disableMismatchWarning
          text={forceLTR(
            sanitizeDisplayName(
              displayName,
              opts.moderation?.ui('displayName'),
            ),
          )}
          href={profileLink}
          onBeforePress={onBeforePressAuthor}
        />
        <RelationshipTag did={opts.author.did} />
        <Text style={{marginLeft: 2, color: '#666'}}>{`  · ${time}`}</Text>
      </Text>
      {opts.rightButton?.()}
    </View>
  )
}
PostMeta = memo(PostMeta)
export {PostMeta}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 4,
    zIndex: 1,
    flex: 1,
  },
  avatar: {
    alignSelf: 'center',
  },
  maxWidth: {
    flex: 1,
  },
})
