import React, {useMemo} from 'react'
import {SafeAreaView, StatusBar, Text, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {useSubAccounts} from '#/lib/hooks/useIsMe'
import {
  CommonNavigatorParams,
  NativeStackScreenProps,
  NavigationProp,
} from '#/lib/routes/types'
import {useAgent, useSession} from '#/state/session'
import * as Toast from '#/view/com/util/Toast'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {useDialogControl} from '#/components/Dialog'
import * as Prompt from '#/components/Prompt'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'MaskSetting'>
export function Setting({route}: Props) {
  const {_} = useLingui()
  const t = useTheme()
  const {currentAccount} = useSession()
  const agent = useAgent()
  const {subAccounts} = useSubAccounts()
  const {handle} = route.params
  const navigation = useNavigation<NavigationProp>()
  const deletePromptControl = useDialogControl()

  const isCurrent = useMemo(() => {
    return currentAccount?.handle === route.params.handle
  }, [currentAccount?.handle, route?.params?.handle])

  const onLinkWallet = () => {
    navigation.navigate('LinkedWallet')
  }

  const onShowDelete = () => {
    deletePromptControl.open()
  }

  const onDeleteMask = React.useCallback(async () => {
    const account = subAccounts?.find(_account => _account.handle === handle)
    try {
      if (account?.did) {
        await agent.com.atproto.server.deleteSubAccount({did: account.did})
        Toast.show(_(msg`Mask deleted`))
        navigation.navigate('Mask', {time: Date.now()})
      }
    } catch (error) {}
  }, [_, agent.com.atproto.server, handle, navigation, subAccounts])

  return (
    <SafeAreaView style={[a.flex_1, t.atoms.bg_gray]}>
      <StatusBar
        animated={true}
        backgroundColor={t.atoms.bg_gray.backgroundColor}
      />
      <ViewHeader
        canGoBack
        title={_(msg`Mask Setting`)}
        showOnDesktop
        style={t.atoms.bg_gray}
      />
      <CenteredView style={[a.flex_1, a.p_lg]}>
        <View style={[a.flex_1, a.flex_col, a.gap_lg]}>
          <Button
            testID={`linkWalletButton`}
            style={[a.rounded_md, a.overflow_hidden]}
            onPress={onLinkWallet}
            label="Link Wallet">
            {({hovered, pressed}) => (
              <View
                style={[
                  a.flex_1,
                  a.rounded_md,
                  a.align_center,
                  a.justify_center,
                  {height: 52},
                  // a.p_xl,
                  t.atoms.bg_white,
                  (hovered || pressed) && t.atoms.bg_contrast_25,
                ]}>
                <Text style={[a.align_baseline, a.text_md, a.font_semibold]}>
                  <Trans>Link Wallet</Trans>
                </Text>
              </View>
            )}
          </Button>

          {isCurrent && !currentAccount?.mnemonic && (
            <Button
              testID={`deleteMaskButton`}
              style={[a.rounded_md, a.overflow_hidden]}
              onPress={onShowDelete}
              label="Link Wallet">
              {({hovered, pressed}) => (
                <View
                  style={[
                    a.flex_1,
                    a.rounded_md,
                    a.align_center,
                    a.justify_center,
                    {height: 52},
                    t.atoms.bg_white,
                    (hovered || pressed) && t.atoms.bg_contrast_25,
                  ]}>
                  <Text
                    style={[
                      a.align_baseline,
                      t.atoms.text_danger,
                      a.text_md,
                      a.font_semibold,
                    ]}>
                    <Trans>Delete Mask</Trans>
                  </Text>
                </View>
              )}
            </Button>
          )}
        </View>
      </CenteredView>
      <Prompt.Basic
        control={deletePromptControl}
        title={_(msg`Delete Mask?`)}
        description={_(
          msg`Are you sure you want to delete this mask @${handle} from Portfolio?`,
        )}
        onConfirm={onDeleteMask}
        confirmButtonCta={_(msg`Delete`)}
        confirmButtonColor="negative"
      />
    </SafeAreaView>
  )
}
