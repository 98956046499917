import {BrowserProvider, Contract, ethers} from 'ethers'

import betRequestContractABI from '../abi/bet_request_abi.json'
import {SUPPORT_CHAINS} from '../constants'
import {BET_TYPE_CONTRACT} from './bet'
import {handleApprove, switchChain} from './index'

async function getBetRequestContract(provider: any, chainId: number) {
  const chainInfoValue = SUPPORT_CHAINS?.find(o => o?.chainId === chainId)
  const contractAddress = chainInfoValue?.contract?.REQUEST_BET_ADDRESS
  if (!contractAddress) {
    throw new Error('Missing contract address.')
  }
  console.log('joinBet--', contractAddress)

  // const web3Provider = new ethers.providers.Web3Provider(provider) // ethers5
  // const signer = web3Provider.getSigner() // ethers5
  const web3Provider = new BrowserProvider(provider) // ethers6
  const signer = await web3Provider.getSigner() // ethers6
  const contract = new Contract(
    contractAddress,
    betRequestContractABI as any,
    signer,
  )

  return {
    contract,
    signer,
    contractAddress,
    payTokenAddress: chainInfoValue?.payTokenAddress,
  }
}

// 1. createBetRequest
export const createBetRequest = async (
  provider: any,
  amount: number,
  chainId: number,
  postId: string,
  betType: 'on' | 'against' | 'private',
) => {
  const {contract, signer, contractAddress, payTokenAddress} =
    await getBetRequestContract(provider, chainId)

  await switchChain(provider, chainId)

  const tradeAmount = ethers.parseUnits(amount + '', 6) // ethers6
  // const tradeAmount = ethers.utils.parseUnits(amount + '', 6) // ethers5
  console.log('createBetRequest--tradeAmount', tradeAmount)
  // approve
  await handleApprove(contractAddress, signer, payTokenAddress, tradeAmount)
  console.log('createBetRequest-postId', postId)
  return await contract.createBetRequest(
    postId,
    tradeAmount,
    BET_TYPE_CONTRACT[betType],
  )
}

// 2. claimBetRequest
export const claimBetRequest = async (
  provider: any,
  chainId: number,
  postId: string,
) => {
  const {contract} = await getBetRequestContract(provider, chainId)
  console.log('claimBetRequest-postId', postId)
  return await contract.claimBetRequest(postId)
}

// 3. batchClaimBetRequests
export const betBatchClaimBetRequests = async (
  provider: any,
  chainId: number,
) => {
  const {contract} = await getBetRequestContract(provider, chainId)
  return await contract.batchClaimBetRequests()
}
