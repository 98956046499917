import {BrowserProvider, ethers} from 'ethers'

import erc20ContractABI from '../abi/erc20_abi.json'
import {SUPPORT_CHAINS} from '../constants'

export async function handleApprove(
  contractAddress: string,
  signer: any,
  tokenAddress: string,
  tradeAmount: bigint,
) {
  const ownerAddress = await signer?.getAddress()
  if (!ownerAddress) return
  const contract = new ethers.Contract(tokenAddress, erc20ContractABI, signer)
  let allowance = 0
  try {
    allowance = await contract.allowance(ownerAddress, contractAddress)
  } catch (error) {}
  if (tradeAmount > allowance) {
    const tx = await contract.approve(contractAddress, tradeAmount)
    const transactionReceipt = await tx?.wait()
    console.log('approveRes', transactionReceipt)
  }
}

export async function switchChain(provider: any, chainId: number) {
  // Request the current network ID from MetaMask
  console.log('switchChain---1', chainId, `0x${(+chainId).toString(16)}`)
  const currentNetworkId = await provider?.request({method: 'eth_chainId'})
  if (parseInt(currentNetworkId + '') !== chainId) {
    // Check if the current network matches the desired network
    console.log('switchChain-chainId', chainId)
    if (
      provider.isWalletConnect &&
      provider?.session?.namespaces?.eip155?.chains &&
      provider?.session?.namespaces?.eip155?.chains?.indexOf(
        'eip155:' + chainId,
      ) <= 0
    ) {
      await addChain(provider, chainId)
    } else {
      try {
        // Prompt the user to switch networks
        await provider
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: `0x${(+chainId).toString(16)}`}],
          })
          .then((result: any) => {
            console.log('wallet_switchEthereumChain-result', result)
          })
      } catch (error: any) {
        console.log('switchChain-error', error)
        if (
          error?.code === 4902 ||
          error?.data?.orginalError?.code === 4902 ||
          error?.code === -32602 ||
          error.toString().indexOf('wallet_addEthereumChain') >= 0
        ) {
          await addChain(provider, chainId)
        } else {
          throw new Error(
            `Unrecognized chain ID ${chainId.toString()}. Please change your chain`,
          )
        }
      }
    }
  }
}

async function addChain(provider: any, chainId: number) {
  const chainConfig = SUPPORT_CHAINS.find(o => o?.chainId === chainId)
  if (!chainConfig) {
    throw new Error(`Unrecognized chain ID ${chainId.toString()}.`)
  }

  try {
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${(+chainId).toString(16)}`, // ethers5
          // chainId: ethers.toBeHex(chainId), // ethers56
          blockExplorerUrls: [chainConfig?.explorerUrl],
          chainName: chainConfig?.name,
          rpcUrls: [chainConfig?.rpcUrl],
          nativeCurrency: chainConfig?.native_currency,
        },
      ],
    })
  } catch (error) {
    console.log('addChain-error', error)
    throw new Error(
      `Unrecognized chain ID ${chainId.toString()}. Please add your chain`,
    )
  }
}

export function selectAccount(provider: any) {
  return provider
    .request({
      method: 'wallet_requestPermissions',
      params: [{eth_accounts: {}}],
    })
    .then((permissions: any) => {
      const accountsPermission = permissions.find(
        (permission: any) => permission.parentCapability === 'eth_accounts',
      )
      if (accountsPermission) {
        console.log('eth_accounts permission successfully requested!')
      }
    })
}

export async function onSignMessage(provider: any, signMsg: string) {
  // const web3Provider = new ethers.providers.Web3Provider(provider); //ether5
  const web3Provider = new BrowserProvider(provider) //ether6
  const signer = await web3Provider.getSigner()

  return await signer?.signMessage(signMsg)
}

export async function switchAccount(provider: any): Promise<string[]> {
  return provider.request({
    method: 'eth_requestAccounts',
    params: [],
  })
}
