import React, {useState} from 'react'

import {useAgent, useSession} from '#/state/session'
import {MaskAccount} from '#/view/screens/Mask'

// const subAccounts: MaskAccount[] = []
export const useSubAccounts = () => {
  const {currentAccount} = useSession()
  const [subAccounts, setSubAccounts] = useState<MaskAccount[]>([])
  const agent = useAgent()
  React.useEffect(() => {
    agent.com.atproto.server.getSubAccountLIst().then(res => {
      if (res.success) {
        const list =
          res.data.subAccounts?.map(account => {
            return {
              ...account,
              handle: account.handle,
              avatar: account.avatar,
              service: currentAccount?.service,
            } as MaskAccount
          }) || []
        setSubAccounts(list)
      }
    })
  }, [agent.com.atproto.server, currentAccount?.service])

  return {subAccounts}
}

export const useIsMe = (did: string): boolean => {
  const {subAccounts} = useSubAccounts()
  const {currentAccount} = useSession()

  if (currentAccount?.did === did) {
    return true
  }
  const hasSub = subAccounts.find(sub => sub.did === did)
  if (hasSub) {
    return true
  }
  return false
}
