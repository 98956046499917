/**
 * A kind of companion API to ./feed.ts. See that file for more info.
 */

import React from 'react'
import {AppState} from 'react-native'
import {useQueryClient} from '@tanstack/react-query'
import EventEmitter from 'eventemitter3'

import BroadcastChannel from '#/lib/broadcast'
import {isDev} from '#/lib/constants'
import {logger} from '#/logger'
import {isWeb} from '#/platform/detection'
import {useAgent, useSession} from '#/state/session'
import {resetBadgeCount} from 'lib/notifications/notifications'
import {useModerationOpts} from '../../preferences/moderation-opts'
import {truncateAndInvalidate} from '../util'
import {RQKEY as RQKEY_NOTIFS} from './feed'
import {CachedFeedPage, FeedPage} from './types'
import {fetchPage} from './util'

const UPDATE_INTERVAL = 30 * 1e3 // 30sec

const broadcast = new BroadcastChannel('NOTIFS_BROADCAST_CHANNEL')

const emitter = new EventEmitter()

type StateContext = string

interface ApiContext {
  markAllRead: () => Promise<void>
  checkUnread: (opts?: {
    invalidate?: boolean
    isPoll?: boolean
  }) => Promise<void>
  getCachedUnreadPage: () => FeedPage | undefined
}

const stateContext = React.createContext<StateContext>('')

const apiContext = React.createContext<ApiContext>({
  async markAllRead() {},
  async checkUnread() {},
  getCachedUnreadPage: () => undefined,
})

export function Provider({children}: React.PropsWithChildren<{}>) {
  const {hasSession} = useSession()
  const agent = useAgent()
  const queryClient = useQueryClient()
  const moderationOpts = useModerationOpts()

  const [numUnread, setNumUnread] = React.useState('')

  const checkUnreadRef = React.useRef<ApiContext['checkUnread'] | null>(null)
  const cacheRef = React.useRef<CachedFeedPage>({
    usableInFeed: false,
    syncedAt: new Date(),
    data: undefined,
    unreadCount: 0,
  })

  React.useEffect(() => {
    function markAsUnusable() {
      if (cacheRef.current) {
        cacheRef.current.usableInFeed = false
      }
    }
    emitter.addListener('invalidate', markAsUnusable)
    return () => {
      emitter.removeListener('invalidate', markAsUnusable)
    }
  }, [])

  // periodic sync
  React.useEffect(() => {
    if (!hasSession || !checkUnreadRef.current) {
      return
    }
    checkUnreadRef.current() // fire on init
    const interval = setInterval(
      () => checkUnreadRef.current?.({isPoll: true}),
      UPDATE_INTERVAL,
    )
    return () => clearInterval(interval)
  }, [hasSession])

  // listen for broadcasts
  React.useEffect(() => {
    const listener = ({data}: MessageEvent) => {
      cacheRef.current = {
        usableInFeed: false,
        syncedAt: new Date(),
        data: undefined,
        unreadCount:
          data.event === '30+'
            ? 30
            : data.event === ''
            ? 0
            : parseInt(data.event, 10) || 1,
      }
      setNumUnread(data.event)
    }
    broadcast.addEventListener('message', listener)
    return () => {
      broadcast.removeEventListener('message', listener)
    }
  }, [setNumUnread])

  // create API
  const api = React.useMemo<ApiContext>(() => {
    return {
      async markAllRead() {
        // update server
        await agent.updateSeenNotifications(
          cacheRef.current.syncedAt.toISOString(),
        )

        // update & broadcast
        setNumUnread('')
        broadcast.postMessage({event: ''})
        resetBadgeCount()
      },

      async checkUnread({
        invalidate,
        isPoll,
      }: {invalidate?: boolean; isPoll?: boolean} = {}) {
        try {
          if (!agent.session) return
          if (AppState.currentState !== 'active') {
            return
          }

          // reduce polling if unread count is set
          if (isPoll && cacheRef.current?.unreadCount !== 0) {
            // if hit 30+ then don't poll, otherwise reduce polling by 50%
            if (cacheRef.current?.unreadCount >= 30 || Math.random() >= 0.5) {
              return
            }
          }

          // count
          const {page, indexedAt: lastIndexed} = await fetchPage({
            agent,
            cursor: undefined,
            limit: 40,
            queryClient,
            moderationOpts,

            // only fetch subjects when the page is going to be used
            // in the notifications query, otherwise skip it
            fetchAdditionalData: !!invalidate,
          })
          const unreadCount = countUnread(page)
          const unreadCountStr =
            unreadCount >= 30
              ? '30+'
              : unreadCount === 0
              ? ''
              : String(unreadCount)

          if (
            isWeb &&
            'Notification' in window &&
            Notification.permission === 'granted'
          ) {
            function hashCode(str: string): number {
              let hash: number = 0
              for (let i = 0, len = str.length; i < len; i++) {
                let chr = str.charCodeAt(i)
                hash = (hash << 5) - hash + chr
                hash |= 0 // Convert to 32bit integer
              }
              return hash
            }

            const notificationTitle = 'Spiz Notification'
            const baseUrl = isDev
              ? 'https://app-dev.insider.win/'
              : 'https://sipz.io/'
            var sent = false
            for (let item of page?.items) {
              if (item.notification.isRead) {
                continue
              }
              if (sent) {
                // 1 notification a time
                console.debug('Notification has already been sent once')
                break
              }
              const notificationId = 'NOTIFY' + hashCode(JSON.stringify(item))
              try {
                const notified = localStorage.getItem(notificationId)
                if (notified === '1') {
                  console.debug('Notification already sent ' + notificationId)
                  continue
                }
              } catch (ignore) {
                console.warn(
                  'Get notification status for ' + notificationId + ' failed',
                  ignore,
                )
              }
              try {
                localStorage.setItem(notificationId, '1')
              } catch (ignore) {
                console.warn(
                  'Set notification status=1 for ' + notificationId + ' failed',
                  ignore,
                )
                continue
              }
              const userHandle =
                '@' + item.notification.author.handle.split('.', 1)[0]
              const postUri =
                baseUrl +
                'profile/' +
                item.notification.reasonSubject
                  ?.replace('at://', '')
                  .replace('app.bsky.feed.post', 'post')
              const notifyUri =
                baseUrl +
                'profile/' +
                item.notification.uri
                  ?.replace('at://', '')
                  .replace('app.bsky.feed.post', 'post')

              switch (item.type) {
                case 'post-like': {
                  sent = true
                  const notification = new Notification(notificationTitle, {
                    body: userHandle + ' liked your tea',
                    icon: item.notification.author.avatar,
                  })
                  notification.addEventListener('click', () => {
                    window.open(postUri)
                  })
                  break
                }
                case 'quote': {
                  sent = true
                  const notification = new Notification(notificationTitle, {
                    body: userHandle + ' quote your tea',
                    icon: item.subject?.author.avatar,
                  })
                  notification.addEventListener('click', () => {
                    window.open(notifyUri)
                  })
                  break
                }
                case 'repost': {
                  sent = true
                  const notification = new Notification(notificationTitle, {
                    body: userHandle + ' reposted your tea',
                    icon: item.subject?.author.avatar,
                  })
                  notification.addEventListener('click', () => {
                    window.open(postUri)
                  })
                  break
                }
                case 'follow': {
                  sent = true
                  const notification = new Notification(notificationTitle, {
                    body: userHandle + ' followed you',
                    icon: item.subject?.author.avatar,
                  })
                  var did = ''
                  type recordKeyType = keyof typeof item.notification.record
                  Object.keys(item.notification.record).forEach(key => {
                    if (key === 'subject') {
                      did = item.notification.record[key as recordKeyType]
                    }
                  })
                  notification.addEventListener('click', () => {
                    window.open(baseUrl + 'profile/' + did + '/followers')
                  })
                  break
                }
                case 'mention': {
                  sent = true
                  const notification = new Notification(notificationTitle, {
                    body: userHandle + ' mentioned you',
                    icon: item.subject?.author.avatar,
                  })
                  notification.addEventListener('click', () => {
                    window.open(notifyUri)
                  })
                  break
                }
                case 'reply': {
                  sent = true
                  const notification = new Notification(notificationTitle, {
                    body: userHandle + ' replied you',
                    icon: item.subject?.author.avatar,
                  })
                  notification.addEventListener('click', () => {
                    window.open(notifyUri)
                  })
                  break
                }
                case 'guarantee': {
                  sent = true
                  var status = 0
                  type recordKeyType = keyof typeof item.notification.record
                  Object.keys(item.notification.record).forEach(key => {
                    if (key === 'status') {
                      status = item.notification.record[key as recordKeyType]
                    }
                  })

                  var body = userHandle + ' invited you to be the guarantor'
                  if (status === 1) {
                    body = userHandle + ' agreed to be your guarantor'
                  } else if (status === 2) {
                    body = userHandle + ' rejected to be your guarantor'
                  }

                  const notification = new Notification(notificationTitle, {
                    body: body,
                    icon: item.subject?.author.avatar,
                  })
                  notification.addEventListener('click', () => {
                    window.open(postUri)
                  })
                  break
                }
              }
            }
          }

          // track last sync
          const now = new Date()
          const lastIndexedDate = lastIndexed
            ? new Date(lastIndexed)
            : undefined
          cacheRef.current = {
            usableInFeed: !!invalidate, // will be used immediately
            data: page,
            syncedAt:
              !lastIndexedDate || now > lastIndexedDate ? now : lastIndexedDate,
            unreadCount,
          }

          // update & broadcast
          setNumUnread(unreadCountStr)
          if (invalidate) {
            truncateAndInvalidate(queryClient, RQKEY_NOTIFS())
          }
          broadcast.postMessage({event: unreadCountStr})
        } catch (e) {
          logger.warn('Failed to check unread notifications', {error: e})
        }
      },

      getCachedUnreadPage() {
        // return cached page if it's marked as fresh enough
        if (cacheRef.current.usableInFeed) {
          return cacheRef.current.data
        }
      },
    }
  }, [setNumUnread, queryClient, moderationOpts, agent])
  checkUnreadRef.current = api.checkUnread

  return (
    <stateContext.Provider value={numUnread}>
      <apiContext.Provider value={api}>{children}</apiContext.Provider>
    </stateContext.Provider>
  )
}

export function useUnreadNotifications() {
  return React.useContext(stateContext)
}

export function useUnreadNotificationsApi() {
  return React.useContext(apiContext)
}

function countUnread(page: FeedPage) {
  let num = 0
  for (const item of page.items) {
    if (!item.notification.isRead) {
      num++
    }
    if (item.additional) {
      for (const item2 of item.additional) {
        if (!item2.isRead) {
          num++
        }
      }
    }
  }
  return num
}

export function invalidateCachedUnreadPage() {
  emitter.emit('invalidate')
}
