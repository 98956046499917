import React from 'react'
import {StyleSheet, View} from 'react-native'
import {AppBskyActorDefs, RichText as RichTextAPI} from '@atproto/api'

// import {Trans} from '@lingui/macro'
import {Shadow} from '#/state/cache/types'
// import {isInvalidHandle} from 'lib/strings/handles'
import {isIOS} from 'platform/detection'
import {atoms as a, useTheme} from '#/alf'
// import {NewskieDialog} from '#/components/NewskieDialog'
import {Text} from '#/components/Typography'

export function ProfileHeaderHandle({
  disableTaps,
  descriptionRT,
}: {
  profile: Shadow<AppBskyActorDefs.ProfileViewDetailed>
  disableTaps?: boolean
  descriptionRT: RichTextAPI | null
}) {
  const t = useTheme()
  // const invalidHandle = isInvalidHandle(profile.handle)
  // const blockHide = profile.viewer?.blocking || profile.viewer?.blockedBy
  return (
    <View
      style={[styles.container]}
      pointerEvents={disableTaps ? 'none' : isIOS ? 'auto' : 'box-none'}>
      {/* <NewskieDialog profile={profile} disabled={disableTaps} />
      {profile.viewer?.followedBy && !blockHide ? (
        <View style={[t.atoms.bg_contrast_25, a.rounded_xs, a.px_sm, a.py_xs]}>
          <Text style={[t.atoms.text, a.text_sm]}>
            <Trans>Follows you</Trans>
          </Text>
        </View>
      ) : undefined}
      <Text
        numberOfLines={1}
        style={[
          invalidHandle
            ? [
                a.border,
                a.text_xs,
                a.px_sm,
                a.py_xs,
                a.rounded_xs,
                {borderColor: t.palette.contrast_200},
              ]
            : [a.text_md, a.leading_tight, t.atoms.text_contrast_medium],
          web({wordBreak: 'break-all'}),
        ]}>
        {invalidHandle ? <Trans>⚠Invalid Handle</Trans> : `@${profile.handle}`}
      </Text> */}
      {descriptionRT && (
        <Text style={[t.atoms.text, styles.bio, a.text_sm]}>
          {descriptionRT.text}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  bio: {
    marginVertical: 0,
  },
})
