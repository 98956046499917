import {BrowserProvider, Contract, ethers} from 'ethers'

import stakeContractABI from '../abi/stake_abi.json'
import {SUPPORT_CHAINS} from '../constants'
import {handleApprove, switchChain} from './index'

async function getStakeContract(provider: any, chainId: number) {
  const chainInfoValue = SUPPORT_CHAINS?.find(o => o?.chainId === chainId)
  const contractAddress = chainInfoValue?.contract?.STAKE_ADDRESS
  if (!contractAddress) {
    throw new Error('Missing contract address.')
  }

  // const web3Provider = new ethers.providers.Web3Provider(provider) // ethers5
  // const signer = web3Provider.getSigner() // ethers5
  const web3Provider = new BrowserProvider(provider) // ethers6
  const signer = await web3Provider.getSigner() // ethers6
  const contract = new Contract(
    contractAddress,
    stakeContractABI as any,
    signer,
  )

  return {
    contract,
    signer,
    contractAddress,
    payTokenAddress: chainInfoValue?.payTokenAddress,
  }
}

async function getStakeContractReadOnly(chainId: number) {
  const chainInfoValue = SUPPORT_CHAINS?.find(o => o?.chainId === chainId)
  const contractAddress = chainInfoValue?.contract?.STAKE_ADDRESS
  if (!contractAddress) {
    throw new Error('Missing contract address.')
  }

  const provider = ethers.getDefaultProvider(chainInfoValue?.rpcUrl)
  const contract = new Contract(
    contractAddress,
    stakeContractABI as any,
    provider,
  )

  return contract
}

export const stake = async (
  provider: any,
  amount: number,
  chainId: number,
  postUri: string,
) => {
  const {contract, signer, contractAddress, payTokenAddress} =
    await getStakeContract(provider, chainId)

  await switchChain(provider, chainId)

  const tradeAmount = ethers.parseUnits(amount + '', 6) // ethers6
  // const tradeAmount = ethers.utils.parseUnits(amount + '', 6) // ethers5
  // console.log('stake--tradeAmount', tradeAmount)
  // approve
  await handleApprove(contractAddress, signer, payTokenAddress, tradeAmount)
  // console.log('stake-postUri', postUri)
  return await contract.stake(postUri, tradeAmount)
}

export const withdraw = async (
  provider: any,
  chainId: number,
  postUri: string,
  amount: string,
) => {
  const {contract} = await getStakeContract(provider, chainId)

  await switchChain(provider, chainId)

  return await contract.withdraw(postUri, amount)
}

export const stakeBatchWithdrawAll = async (provider: any, chainId: number) => {
  const {contract} = await getStakeContract(provider, chainId)

  await switchChain(provider, chainId)

  return await contract.batchWithdrawAll()
}

export const getStake = async (
  provider: any,
  chainId: number,
  postUri: string,
) => {
  const {contract, signer} = await getStakeContract(provider, chainId)
  // console.log('getStake', postUri, signer.address)
  const res = await contract.getStake(postUri, signer.address)
  // console.log('getStake--res', res?.[3])
  return res?.[3]
}

export const getTotalBalances = async (chainId: number, address: string) => {
  // console.log('getTotalBalances', address)
  const contract = await getStakeContractReadOnly(chainId)
  const res = await contract.getTotalBalances(address)
  // console.log('getTotalBalances--res', res)
  return Number(res?.[0] ? ethers.formatUnits(res?.[0], 6) : 0)
}

export const getClaimableBalance = async (chainId: number, address: string) => {
  const contract = await getStakeContractReadOnly(chainId)
  const res = await contract.getClaimableBalance(address)
  // console.log('getClaimableBalance--res', res)
  return Number(res ? ethers.formatUnits(res, 6) : 0)
}

export const getRemainAmount = async (provider: any, chainId: number) => {
  const {contract, signer} = await getStakeContract(provider, chainId)
  // console.log('getRemainAmount', signer.address)
  const res = await contract.getRemainAmount(signer.address)
  // console.log('getRemainAmount--res', res?.[3])
  return res?.[3]
}

export const getStakeAvailableWithdraw = async (
  chainId: number,
  address: string,
) => {
  const contract = await getStakeContractReadOnly(chainId)
  const res = await contract.getClaimableStake(address)
  // console.log('getStakeAvailableWithdraw--res', res)
  return Number(res ? ethers.formatUnits(res, 6) : 0)
}
