type Unsubscribe = () => void
type Listener<T> = (state: T) => void
type UpdateFun<T> = (prevState: T) => T

export function createStore<T>(initialState: T) {
  let state: T = initialState
  const listeners: Listener<T>[] = []

  function subscribe(listener: Listener<T>): Unsubscribe {
    listeners.push(listener)

    return function unsubscribe() {
      const index = listeners.indexOf(listener)
      if (index !== -1) {
        listeners.splice(index, 1)
      }
    }
  }

  function getState(): T {
    return state
  }

  function setState(newState: T | UpdateFun<T>) {
    let nextState: T
    if (typeof newState === 'function') {
      nextState = (newState as UpdateFun<T>)(state)
    } else {
      nextState = newState
    }
    state = nextState
    listeners.forEach(listener => listener(state))
  }

  return {subscribe, getState, setState}
}
