import React from 'react'
import {SafeAreaView, Text, View} from 'react-native'
import {useNavigation} from '@react-navigation/native'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {NavigationProp} from 'lib/routes/types'
import {atoms as a, useTheme} from '#/alf'
import {EmptyWalletIcon} from '#/components/icons/StakeIcons'
import {Button} from '../util/forms/Button'

export const snapPoints = ['50%']
export const Component = function DetectLinkedWalletModalComponent() {
  const t = useTheme()
  const pal = usePalette('default')
  const navigation = useNavigation<NavigationProp>()
  const {closeModal} = useModalControls()
  const {isMobile, isDesktop} = useWebMediaQueries()

  return (
    <SafeAreaView testID="detectLinkedWalletModal">
      <View style={[pal.view, a.mt_xl, isMobile && {paddingHorizontal: 18}]}>
        <View style={[a.align_center, a.mb_3xl]}>
          <EmptyWalletIcon style={{width: 151, height: 151}} />
          <Text style={[a.text_lg, a.font_semibold, a.text_center, a.pt_md]}>
            Enable tipping by linking your wallet account
          </Text>
        </View>
        <View style={[a.mt_md, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
          <Button
            testID="confirmBtn"
            type="primary"
            accessibilityLabel={'Go'}
            accessibilityHint=""
            label={'Go'}
            onPress={() => {
              navigation.navigate('LinkedWallet')
              closeModal()
            }}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md, {color: t.palette.black}]}
            style={[
              isDesktop ? a.flex_1 : {},
              {backgroundColor: t.palette.primary},
            ]}
          />
          <Button
            testID="cancelBtn"
            type="default"
            onPress={() => {
              closeModal()
            }}
            accessibilityLabel={'Later'}
            accessibilityHint=""
            label={'Later'}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md]}
            style={[isDesktop ? a.flex_1 : {}]}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}
