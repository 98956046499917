import {useCallback, useState} from 'react'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {PUBLIC_ENV_CHAINID} from '#/lib/constants'
import {stake} from '#/lib/ethers/stake'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {logger} from '#/logger'
import {TransPostAuthorProps} from '#/view/com/modals/BetToEarn'
import {StakeDonateModalType} from '#/view/com/modals/StakeDonate'
import {simplifyMsg} from '#/view/com/util/Transaction'
import * as Toast from 'view/com/util/Toast'
import {useModalControls} from '../modals'
import {
  useCreateTransMutation,
  useUpdateTransMutation,
} from '../queries/transaction-bet'
import {useWallet} from '../shell/wallet'

export type Props = {
  type: StakeDonateModalType
  rKey: string
  transPostAuthor: TransPostAuthorProps
}

export interface IOnStake {
  rKey: string
  transPostAuthor: TransPostAuthorProps
}
export type TransStatus = 'init' | 'pending' | 'success' | 'error'

export const useStake = (props: Props) => {
  const {rKey, transPostAuthor} = props
  const {provider, currentWallet} = useWallet()
  const {openModal, closeModal} = useModalControls()
  const {_} = useLingui()
  const createTransMutation = useCreateTransMutation()
  const updateTransMutation = useUpdateTransMutation()

  // const postId = `${transPostAuthor?.did?.split(':')?.[2]}/${rKey}`
  // const uri = makeRecordUri(
  //   props.transPostAuthor?.did,
  //   'app.bsky.feed.post',
  //   props.rKey,
  // )

  const [parentAmount, setParentAmount] = useState<string | undefined>(
    undefined,
  )
  const [parentError, setParentError] = useState<string | undefined>(undefined)
  const [parentPending, setParentPending] = useState<boolean>(false)
  const [response, setResponse] = useState<
    {success: boolean; hash?: string} | undefined
  >(undefined)
  const [status, setStatus] = useState<TransStatus>('init')

  const onStake = useCallback(
    async (stakeProps: IOnStake) => {
      console.log(
        'onStake-click',
        currentWallet,
        PUBLIC_ENV_CHAINID,
        parentAmount,
      )
      const postId = `${stakeProps?.transPostAuthor?.did?.split(':')?.[2]}/${
        stakeProps.rKey
      }`
      const urIParam = makeRecordUri(
        stakeProps.transPostAuthor?.did,
        'app.bsky.feed.post',
        stakeProps.rKey,
      )
      try {
        if (!currentWallet?.publicKey) {
          openModal({name: 'connect-wallet', type: 'connect'})
          return
        }
        if (!parentAmount || parseInt(parentAmount) < 5) {
          return
        }
        setStatus('pending')
        setParentPending(true)
        const tx = await stake(
          provider,
          Number(parentAmount),
          PUBLIC_ENV_CHAINID,
          postId,
        )
        console.log('onStakeHandler', tx)
        if (tx) {
          try {
            const res = await createTransMutation.mutateAsync({
              param: {
                id: tx?.hash,
                payer: stakeProps?.transPostAuthor?.did,
                method: 'insider-stake.stake',
              },
            })
            console.log('createTransMutation', res)
          } catch (error) {
            console.log('createTransMutation-error', error)
          }
          const reception = await tx.wait()
          console.log('unStakeHandler-reception', reception)
          // if (reception?.transactionHash) { // ethers5
          if (reception?.hash) {
            // ethers6
            try {
              const res = await updateTransMutation.mutateAsync({
                param: {
                  repo: stakeProps.transPostAuthor.did,
                  collection: 'app.bsky.feed.stake',
                  record: {
                    uri: urIParam,
                    transId: reception?.hash,
                    method: 'insider-stake.stake',
                    amount: Number(parentAmount) * 1000000,
                  },
                },
              })
              console.log('updateTransMutation', res)
            } catch (error) {
              console.log('updateTransMutation-error', error)
            }
            setStatus('success')
            setParentPending(false)
            setResponse({
              success: true,
              hash: reception?.hash,
            })
            if (props.type !== 'create-post-stake') {
              openModal({
                name: 'trans-result',
                type: 'stake',
                amount: parentAmount,
              })
            }
          }
        }
      } catch (err) {
        logger.error(`stake:`, {message: err})
        setStatus('error')
        setParentPending(false)
        setResponse({
          success: false,
        })
        let reason =
          (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
        reason = simplifyMsg(reason)
        if (reason) {
          Toast.show(`Transaction failed: ${reason}`, 'xmark')
        } else {
          Toast.show(
            _(msg`Transaction failed: this operation caused some error`),
            'xmark',
          )
        }
      }
    },
    [
      _,
      createTransMutation,
      currentWallet,
      openModal,
      parentAmount,
      props.type,
      provider,
      updateTransMutation,
    ],
  )

  const showStakeModal = useCallback(
    (param?: StakeDonateModalType) => {
      openModal({
        name: 'stake-donate',
        type: param !== undefined ? param : 'stake',
        rKey,
        transPostAuthor,
        amount: parentAmount,
        setParentAmount,
        setParentError,
        pending: parentPending,
        setParentPending,
        setResponse,
      })
    },
    [openModal, parentAmount, parentPending, rKey, transPostAuthor],
  )

  function closeStakeModal() {
    closeModal()
  }

  return {
    status,
    amount: parentAmount,
    error: parentError,
    pending: parentPending,
    response,
    showStakeModal,
    closeStakeModal,
    onStake,
  }
}
