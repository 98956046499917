import {useSyncExternalStore} from 'react'

import {createStore} from './SdlStore'
import {ITargetItem} from './targetDate'

const store = createStore({
  selectedArray: [] as ITargetItem[],
})

const {subscribe, getState, setState: setPostStore} = store

export function usePostTargetStore() {
  const targetStore = useSyncExternalStore(subscribe, getState)
  const setSelectedTargetStore = (targets: ITargetItem[]) => {
    setPostStore(pre => ({...pre, selectedArray: targets}))
  }

  const onDeleteTarget = (target: ITargetItem) => {
    const targetIndex = targetStore.selectedArray.findIndex(
      item => item.id === target.id,
    )
    if (targetIndex !== -1) {
      const newTargetArray = [...targetStore.selectedArray]
      newTargetArray.splice(targetIndex, 1)
      setSelectedTargetStore(newTargetArray)
    }
  }

  return {
    targetStore,
    setSelectedTargetStore,
    onDeleteTarget,
  }
}
