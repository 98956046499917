import 'react-datepicker/dist/react-datepicker.css'

import React, {useCallback, useState} from 'react'
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from 'react-native'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'

import {usePalette} from 'lib/hooks/usePalette'
import {TypographyVariant} from 'lib/ThemeContext'
import {atoms as a, useTheme} from '#/alf'
import {CalendarIcon} from '#/components/icons/StakeIcons'
import {Text} from '../text/Text'
import {Button, ButtonType} from './Button'
interface Props {
  testID?: string
  value: Date
  onChange: (date: Date) => void
  buttonType?: ButtonType
  buttonStyle?: StyleProp<ViewStyle>
  buttonLabelType?: TypographyVariant
  buttonLabelStyle?: StyleProp<TextStyle>
  accessibilityLabel: string
  accessibilityHint: string
  accessibilityLabelledBy?: string
}

export function DateTimeInput(props: Props) {
  const t = useTheme()
  const pal = usePalette('default')
  const [show, setShow] = useState(false)
  const [value, setValue] = useState(props.value)

  const onChangeInternal = (v: Date | null) => {
    console.log('debugger', v, dayjs(v).format('HH:mm on MM DD, YYYY'))
    if (!v) {
      return
    }
    setValue(v)
    props.onChange(v)
    setShow(false)
  }

  const onPress = useCallback(() => {
    setShow(true)
  }, [setShow])

  return (
    <View style={[styles.textInput, pal.border]}>
      <Button
        type={props.buttonType}
        style={props.buttonStyle}
        onPress={onPress}
        accessibilityLabel={props.accessibilityLabel}
        accessibilityHint={props.accessibilityHint}
        accessibilityLabelledBy={props.accessibilityLabelledBy}>
        <View style={styles.button}>
          <Text
            style={[a.text_sm, a.font_semibold, {color: t.palette.black_25}]}>
            Deadline
          </Text>
          <View style={[a.flex_row, a.align_center, a.gap_md]}>
            <Text
              type={props.buttonLabelType}
              style={[pal.text, props.buttonLabelStyle]}>
              {dayjs(props.value).format('HH:mm on MMM DD, YYYY')}
            </Text>
            <CalendarIcon />
          </View>
        </View>
      </Button>
      {show && (
        <DatePicker
          open={show}
          selected={value}
          onChange={date => onChangeInternal(date)}
          showTimeSelect
          timeIntervals={30}
          timeCaption="time"
          dateFormat="HH:mm MMM DD, YYYY"
          // includeDateIntervals={[
          //   {
          //     // start: new Date(new Date().getTime()),
          //     start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          //     end: new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000),
          //   },
          // ]}
          className="bet-datetime-picker"
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 14,
    borderWidth: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textInput: {
    paddingVertical: 5,
    fontWeight: '500',
    borderRadius: 6,
    borderWidth: 1,
    marginRight: 8,
  },
  button: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
