import React, {useEffect, useRef} from 'react'
import {Text, View} from 'react-native'
import ViewShot from 'react-native-view-shot'
// import RNFS from 'react-native-fs'
// import {CameraRoll} from '@react-native-camera-roll/camera-roll'
// import {AppBskyGraphStarterpack} from '@atproto/api'
import {requestMediaLibraryPermissionsAsync} from 'expo-image-picker'
import {createAssetAsync} from 'expo-media-library'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {logger} from '#/logger'
import {isNative, isWeb} from '#/platform/detection'
import * as Toast from '#/view/com/util/Toast'
import {LogoHorizontal} from '#/view/icons/LogoHorizontal'
import {formatHandler} from '#/screens/Onboarding/util'
import {useSignupContext} from '#/screens/Signup/state'
import {atoms as a, useTheme} from '#/alf'
import {QrCodeInner} from '#/components/StarterPack/QrCode'
// import { logEvent } from '#/lib/statsig/statsig'

export function SaveWebView({getRef}: {getRef: (ref: any) => void}) {
  const {_} = useLingui()
  const t = useTheme()
  const {state} = useSignupContext()
  //   const submit = useSubmitSignup({state, dispatch})
  // const agent = useAgent()
  const qrcodeRef = useRef<ViewShot>(null)
  // const [isProcessing, setIsProcessing] = React.useState(false)

  const onSavePress = React.useCallback(async () => {
    return qrcodeRef.current?.capture?.().then(async (uri: string) => {
      if (isNative) {
        const res = await requestMediaLibraryPermissionsAsync()

        if (!res) {
          Toast.show(
            _(
              msg`You must grant access to your photo library to save a QR code`,
            ),
          )
          return false
        }

        // Incase of a FS failure, don't crash the app
        try {
          await createAssetAsync(`file://${uri}`)
        } catch (e: unknown) {
          Toast.show(
            _(msg`An error occurred while saving the QR code!`),
            'xmark',
          )
          logger.error('Failed to save QR code', {error: e})
          return false
        }
      } else {
        const canvas = await getCanvas(uri)
        const imgHref = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')

        const link = document.createElement('a')
        link.setAttribute(
          'download',
          `${state.handle.replaceAll(' ', '_')}.png`,
        )
        link.setAttribute('href', imgHref)
        link.click()
      }

      // logEvent('starterPack:share', {
      //   wallet: starterPack.uri,
      //   shareType: 'qrcode',
      //   qrShareType: 'save',
      // })
      // setIsProcessing(false)
      Toast.show(
        isWeb
          ? _(msg`QR code has been downloaded!`)
          : _(msg`QR code saved to your camera roll!`),
      )
      return true
      // control.close()
    })
  }, [_, state.handle])

  useEffect(() => {
    getRef({onSavePress})
  }, [getRef, onSavePress])

  // const validCheck = validateHandle(draftValue, state.userDomain)
  return (
    <View style={[a.align_center, a.p_lg]}>
      <View style={[t.atoms.bg_white, a.rounded_sm]}>
        {state.wallet.mnemonic?.phrase && (
          <ViewShot ref={qrcodeRef}>
            <View
              style={[
                a.gap_xl,
                a.p_xl,
                a.align_center,
                a.rounded_md,
                t.atoms.bg_white,
              ]}>
              <Text style={[a.text_xl, a.text_center, {color: 'black'}]}>
                @{formatHandler(state.handle)}
              </Text>
              <View style={[a.rounded_sm, a.overflow_hidden]}>
                <QrCodeInner link={state.wallet.mnemonic?.phrase} />
              </View>

              <View style={[a.flex_row, a.align_center, {gap: 5}]}>
                <LogoHorizontal style={{width: 130, height: 30}} />
              </View>
            </View>
          </ViewShot>
        )}
      </View>
    </View>
  )
}

const getCanvas = (base64: string): Promise<HTMLCanvasElement> => {
  return new Promise(resolve => {
    const image = new Image()
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      const ctx = canvas.getContext('2d')
      ctx?.drawImage(image, 0, 0)
      resolve(canvas)
    }
    image.src = base64
  })
}
