import React from 'react'
import {View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

// import {useModalControls} from '#/state/modals/index'
import {useLoggedOutViewControls} from '#/state/shell/logged-out'
import {useCloseAllActiveElements} from '#/state/util'
import {usePalette} from 'lib/hooks/usePalette'
import {s} from 'lib/styles'
import {Button} from '#/view/com/util/forms/Button'
import {Text} from '#/view/com/util/text/Text'
import {/*atoms as a,*/ useTheme} from '#/alf'
// import {AppLanguageDropdown} from '#/components/AppLanguageDropdown'
import {Link} from '#/components/Link'
import {NavLogo} from '../icons/NavLogo'

let NavSignupCard = ({}: {}): React.ReactNode => {
  const {_} = useLingui()
  const pal = usePalette('default')
  const t = useTheme()
  const {requestSwitchToAccount} = useLoggedOutViewControls()
  const closeAllActiveElements = useCloseAllActiveElements()
  // const {openModal} = useModalControls()

  const showSignIn = React.useCallback(() => {
    closeAllActiveElements()
    requestSwitchToAccount({requestedAccount: 'none'})
  }, [requestSwitchToAccount, closeAllActiveElements])

  const showCreateAccount = React.useCallback(() => {
    closeAllActiveElements()
    requestSwitchToAccount({requestedAccount: 'new'})
    // setShowLoggedOut(true)
  }, [requestSwitchToAccount, closeAllActiveElements])

  // const showTele = React.useCallback(() => {
  //   closeAllActiveElements()
  //   openModal({name: 'telegram'})
  //   // setShowLoggedOut(true)
  // }, [openModal, closeAllActiveElements])

  return (
    <View
      style={{
        alignItems: 'flex-start',
        paddingTop: 40,
        marginBottom: 24,
      }}>
      <Link
        to="/"
        label="Sipz - Home"
        style={{width: '100%', justifyContent: 'center'}}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}>
          <NavLogo width={100} fill="#000" />
        </View>
      </Link>

      <View style={{paddingTop: 28}}>
        <Text type="md-bold" style={[pal.text, {textAlign: 'center'}]}>
          <Trans>Login to see more contents and join the discussion.</Trans>
        </Text>
      </View>

      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          paddingTop: 12,
          gap: 15,
        }}>
        <Button
          style={[t.atoms.bg_primary, {width: 90}]}
          onPress={showCreateAccount}
          accessibilityHint={_(msg`Sign up`)}
          accessibilityLabel={_(msg`Sign up`)}>
          <Text type="md" style={[{color: 'black'}, s.bold, s.textCenter]}>
            <Trans>Sign up</Trans>
          </Text>
        </Button>
        <Button
          style={[{width: 90}]}
          type="default"
          onPress={showSignIn}
          accessibilityHint={_(msg`Login`)}
          accessibilityLabel={_(msg`Login`)}>
          <Text type="md" style={[pal.text, s.bold, s.textCenter]}>
            <Trans>Login</Trans>
          </Text>
        </Button>
        {/* <Button
          type="default"
          onPress={showTele}
          accessibilityHint={_(msg`Telegram`)}
          accessibilityLabel={_(msg`Telegram`)}>
          <Text type="md" style={[pal.text, s.bold]}>
            <Trans>Telegram</Trans>
          </Text>
        </Button> */}
      </View>

      {/* <View style={[a.pt_2xl, a.w_full]}>
        <AppLanguageDropdown />
      </View> */}
    </View>
  )
}
NavSignupCard = React.memo(NavSignupCard)
export {NavSignupCard}
