import React, {useCallback} from 'react'
import {Pressable, StyleSheet, TouchableOpacity, View} from 'react-native'
import {useNavigation} from '@react-navigation/native'

import {usePalette} from '#/lib/hooks/usePalette'
import {s} from '#/lib/styles'
import {useProfileQuery} from '#/state/queries/profile'
import {SessionAccount} from '#/state/session'
import {NavigationProp} from 'lib/routes/types'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {MaskIcon} from '#/view/icons/DrawerIcons'
import {Text} from 'view/com/util/text/Text'
import {formatHandler} from '#/screens/Onboarding/util'
// import { useTheme } from '#/alf'
// import {atoms as a} from '#/alf'

let DrawerProfileCard = ({
  account,
  closeDrawer,
  onPressProfile,
}: {
  account: SessionAccount
  onPressProfile: () => void
  closeDrawer: () => void
}): React.ReactNode => {
  // const t = useTheme()
  const pal = usePalette('default')
  const navigation = useNavigation<NavigationProp>()
  const {data: profile} = useProfileQuery({did: account.did})

  const onPressMask = useCallback(() => {
    navigation?.navigate('Mask', {name: 'Manage Mask'})
    closeDrawer()
  }, [closeDrawer, navigation])

  return (
    <Pressable
      accessibilityRole="button"
      accessibilityLabel="Press me"
      accessibilityHint="Press to trigger an action"
      onPress={onPressProfile}>
      <UserAvatar
        size={34}
        avatar={profile?.avatar}
        usePlainRNImage={true}
        type={profile?.associated?.labeler ? 'labeler' : 'user'}
      />
      <View style={styles.profile}>
        <View style={styles.displayName}>
          <Text
            type="title-lg"
            style={[pal.text, s.bold, styles.profileCardDisplayName]}
            numberOfLines={1}>
            {profile?.displayName || formatHandler(account.handle)}
          </Text>
          <Text
            type="2xl"
            style={[pal.textLight, styles.profileCardHandle]}
            numberOfLines={1}>
            {`@${
              formatHandler(profile?.handle || '') ||
              formatHandler(account.handle)
            }`}
          </Text>
        </View>

        <TouchableOpacity
          testID="maskButton"
          accessibilityRole="button"
          style={styles.mask}
          onPress={onPressMask}>
          <MaskIcon />
        </TouchableOpacity>

        {/* <View style={[styles.winContainer, a.rounded_md, {borderColor: t.palette.primary}]}>
          <View style={[a.rounded_md, styles.winItem]}>
            <Text style={[a.text_lg, styles.winAccount, {color: t.palette.primary}]}>$24.7k</Text>
          </View>
          <View style={[styles.winItem, t.atoms.bg_primary]}>
            <Text style={[styles.winText, a.text_xs]}>Winnings</Text>
          </View>
        </View> */}
      </View>
    </Pressable>
  )
}
export default React.memo(DrawerProfileCard)

const styles = StyleSheet.create({
  profileCardDisplayName: {
    fontSize: 18,
    marginTop: 20,
    paddingRight: 30,
  },
  profileCardHandle: {
    fontSize: 12,
    marginTop: 4,
    paddingRight: 30,
  },
  profileCardFollowers: {
    marginTop: 16,
  },
  profile: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: -10,
    marginBottom: 16,
    position: 'relative',
  },
  mask: {
    position: 'absolute',
    top: -25,
    right: 0,
  },
  displayName: {
    flex: 1,
  },
  winContainer: {
    borderWidth: 1,
    width: 80,
    height: 60,
    overflow: 'hidden',
  },
  winItem: {
    height: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  winText: {
    color: '#fff',
  },
  winAccount: {
    fontWeight: '800',
  },
})
