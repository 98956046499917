import React, {useMemo} from 'react'
import {StyleProp, StyleSheet, ViewStyle} from 'react-native'
import {ClipPath, Defs, G, Path, Rect} from 'react-native-svg'

import {useThemeName} from '#/alf/util/useColorModeTheme'
import {SdlSVG} from './ProfileIcons'

interface BottomIconProps {
  size?: number
  style?: StyleProp<ViewStyle>
  active?: boolean
}

export const HomeIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {color: '#292D32', activeColor: 'black', colordown: 'white'}
      : {color: 'white', activeColor: 'white', colordown: 'black'}
  }, [theme])

  return (
    <SdlSVG
      defaultSize={25}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      {active ? (
        <>
          <Path
            d="M3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016L3.63 7.04016Z"
            fill={colors.activeColor}
            stroke={colors.activeColor}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M12 17.9902V14.9902"
            stroke={colors.colordown}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <Path
            d="M3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016L3.63 7.04016Z"
            stroke={colors.color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M12 17.9902V14.9902"
            stroke={colors.color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    </SdlSVG>
  )
}

export const ProfileIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {activeColor: '#292D32', colordown: 'white'}
      : {activeColor: 'white', colordown: 'black'}
  }, [theme])

  return (
    <SdlSVG
      defaultSize={25}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      {active ? (
        <>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8014 2H12.5V0H21.8014C23.5655 0 24.5435 2.04354 23.4368 3.41739L23.4068 3.45456L21.2155 5.69865L19.7845 4.30135L21.8939 2.1412C21.901 2.12816 21.9025 2.11892 21.9031 2.11263C21.9044 2.09896 21.9021 2.07888 21.8916 2.05683C21.881 2.03479 21.8668 2.02042 21.8554 2.01285C21.8476 2.0077 21.8335 2 21.8014 2Z"
            fill={colors.activeColor}
          />
          <Path
            d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
            fill={colors.activeColor}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
            fill={colors.activeColor}
          />
          <Path
            d="M6.5 8.21504C6.5 7.91128 6.74624 7.66504 7.05 7.66504H8.15C8.45376 7.66504 8.7 7.91128 8.7 8.21504V11.515C8.7 11.8188 8.45376 12.065 8.15 12.065H7.05C6.74624 12.065 6.5 11.8188 6.5 11.515V8.21504Z"
            fill={colors.colordown}
          />
          <Path
            d="M12 8.21504C12 7.91128 12.2462 7.66504 12.55 7.66504H13.65C13.9538 7.66504 14.2 7.91128 14.2 8.21504V11.515C14.2 11.8188 13.9538 12.065 13.65 12.065H12.55C12.2462 12.065 12 11.8188 12 11.515V8.21504Z"
            fill={colors.colordown}
          />
        </>
      ) : (
        <>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 9.4267 23.19 7.04258 21.8111 5.08868L23.4068 3.45456L23.4368 3.41739C24.5435 2.04354 23.5655 0 21.8014 0H12.5V0.0102278C12.3342 0.00343142 12.1675 0 12 0ZM18.6356 2C19.3154 2.45201 19.9465 2.97149 20.5193 3.54889L21.8939 2.1412C21.901 2.12816 21.9025 2.11892 21.9031 2.11263C21.9044 2.09896 21.9021 2.07888 21.8916 2.05683C21.881 2.03479 21.8668 2.02042 21.8554 2.01285C21.8476 2.0077 21.8335 2 21.8014 2H18.6356ZM6.5 8.21504C6.5 7.91128 6.74624 7.66504 7.05 7.66504H8.15C8.45376 7.66504 8.7 7.91128 8.7 8.21504V11.515C8.7 11.8188 8.45376 12.065 8.15 12.065H7.05C6.74624 12.065 6.5 11.8188 6.5 11.515V8.21504ZM12.55 7.66504C12.2462 7.66504 12 7.91128 12 8.21504V11.515C12 11.8188 12.2462 12.065 12.55 12.065H13.65C13.9538 12.065 14.2 11.8188 14.2 11.515V8.21504C14.2 7.91128 13.9538 7.66504 13.65 7.66504H12.55Z"
            fill={colors.activeColor}
          />
        </>
      )}
    </SdlSVG>
  )
}

export const NotificationsIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {activeColor: '#292D32', colordown: 'white'}
      : {activeColor: 'white', colordown: 'black'}
  }, [theme])

  return (
    <SdlSVG
      defaultSize={25}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      {active ? (
        <>
          <Defs>
            <ClipPath id="clip0_6436_362778">
              <Rect width="24" height="24" fill={colors.colordown} />
            </ClipPath>
            <ClipPath id="clip1_6436_362778">
              <Rect
                width="21.17"
                height="24"
                fill={colors.colordown}
                transform="translate(1.41016)"
              />
            </ClipPath>
          </Defs>
          <G clipPath="url(#clip0_6436_362778)">
            <G clipPath="url(#clip1_6436_362778)">
              <Path
                d="M22.4699 17.74L21.6499 15.29V9.24C21.6499 4.94 18.4499 1.14 14.0299 0.21C12.7099 -0.07 11.1999 -0.07 9.86992 0.21C5.39992 1.15 2.26992 4.86 2.26992 9.24V15.29L1.49992 17.79C1.30992 18.4 1.41992 19.05 1.79992 19.57C2.17992 20.09 2.76992 20.38 3.40992 20.38H8.22992C8.28992 20.97 8.46992 21.52 8.74992 22.01C9.45992 23.2 10.7699 24.01 12.2699 24.01C13.7699 24.01 15.0899 23.21 15.7999 22.01C16.0899 21.52 16.2599 20.97 16.3199 20.38H20.5699C21.2099 20.38 21.8199 20.07 22.1899 19.55C22.5699 19.03 22.6699 18.36 22.4599 17.75L22.4699 17.74ZM12.2099 22.3C11.1299 22.3 10.2599 21.43 10.2199 20.37H14.2099C14.1699 21.44 13.2999 22.3 12.2199 22.3H12.2099Z"
                fill={colors.activeColor}
              />
              <Path
                d="M12.21 4.44043C11.66 4.44043 11.21 4.89043 11.21 5.44043V8.77043C11.21 9.32043 11.66 9.77043 12.21 9.77043C12.76 9.77043 13.21 9.32043 13.21 8.77043V5.44043C13.21 4.89043 12.76 4.44043 12.21 4.44043Z"
                fill={colors.colordown}
              />
            </G>
          </G>
        </>
      ) : (
        <>
          <Defs>
            <ClipPath id="clip0_6436_362781">
              <Rect width="24" height="24" fill="white" />
            </ClipPath>
            <ClipPath id="clip1_6436_362781">
              <Rect
                width="21.17"
                height="24"
                fill="white"
                transform="translate(1.41016)"
              />
            </ClipPath>
          </Defs>
          <G clipPath="url(#clip0_6436_362781)">
            <G clipPath="url(#clip1_6436_362781)">
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6499 15.29L22.4699 17.74L22.4599 17.75C22.6699 18.36 22.5699 19.03 22.1899 19.55C21.8199 20.07 21.2099 20.38 20.5699 20.38H16.3199C16.2599 20.97 16.0899 21.52 15.7999 22.01C15.0899 23.21 13.7699 24.01 12.2699 24.01C10.7699 24.01 9.45992 23.2 8.74992 22.01C8.46992 21.52 8.28992 20.97 8.22992 20.38H3.40992C2.76992 20.38 2.17992 20.09 1.79992 19.57C1.41992 19.05 1.30992 18.4 1.49992 17.79L2.26992 15.29V9.24C2.26992 4.86 5.39992 1.15 9.86992 0.21C11.1999 -0.07 12.7099 -0.07 14.0299 0.21C18.4499 1.14 21.6499 4.94 21.6499 9.24V15.29ZM10.2999 2.17C6.74992 2.91 4.26992 5.82 4.26992 9.24V14.44H19.6599V9.24C19.6599 5.88 17.1199 2.9 13.6299 2.17C12.5899 1.95 11.3399 1.95 10.2999 2.17ZM10.2199 20.37C10.2599 21.43 11.1299 22.3 12.2099 22.3H12.2199C13.2999 22.3 14.1699 21.44 14.2099 20.37H10.2199ZM3.99992 16.44L3.40992 18.37H20.5699L19.9799 16.44H3.99992ZM11.21 5.44043C11.21 4.89043 11.66 4.44043 12.21 4.44043C12.76 4.44043 13.21 4.89043 13.21 5.44043V8.77043C13.21 9.32043 12.76 9.77043 12.21 9.77043C11.66 9.77043 11.21 9.32043 11.21 8.77043V5.44043Z"
                fill={colors.activeColor}
              />
            </G>
          </G>
        </>
      )}
    </SdlSVG>
  )
}

export const SearchIcon = ({size, style, active}: BottomIconProps) => {
  const theme = useThemeName()
  const colors = useMemo(() => {
    return theme === 'light'
      ? {color: '#292D32', activeColor: 'black', colordown: 'white'}
      : {color: 'white', activeColor: 'white', colordown: 'black'}
  }, [theme])

  if (active) {
    return (
      <SdlSVG
        defaultSize={24}
        size={size}
        style={[
          {transform: [{translateY: 0}, {translateX: 0}]},
          styles.ctrlIcon,
          style,
        ]}>
        <Path
          d="M1.5 11.5C1.5 17.0228 5.97715 21.5 11.5 21.5C17.0228 21.5 21.5 17.0228 21.5 11.5C21.5 5.97715 17.0228 1.5 11.5 1.5C5.97715 1.5 1.5 5.97715 1.5 11.5Z"
          fill={colors.activeColor}
          stroke={colors.activeColor}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M6 11.5C6 8.46243 8.46243 6 11.5 6"
          stroke={colors.colordown}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M21.5 21.5L19.5 19.5"
          stroke={colors.activeColor}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SdlSVG>
    )
  }

  return (
    <SdlSVG
      defaultSize={24}
      size={size}
      style={[
        {transform: [{translateY: 0}, {translateX: 0}]},
        styles.ctrlIcon,
        style,
      ]}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 22.5C5.42487 22.5 0.5 17.5751 0.5 11.5C0.5 5.42487 5.42487 0.5 11.5 0.5C17.5751 0.5 22.5 5.42487 22.5 11.5C22.5 17.5751 17.5751 22.5 11.5 22.5ZM2.5 11.5C2.5 16.4706 6.52944 20.5 11.5 20.5C16.4706 20.5 20.5 16.4706 20.5 11.5C20.5 6.52944 16.4706 2.5 11.5 2.5C6.52944 2.5 2.5 6.52944 2.5 11.5Z"
        fill={colors.color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7929 18.7929C19.1834 18.4024 19.8166 18.4024 20.2071 18.7929L22.2071 20.7929C22.5976 21.1834 22.5976 21.8166 22.2071 22.2071C21.8166 22.5976 21.1834 22.5976 20.7929 22.2071L18.7929 20.2071C18.4024 19.8166 18.4024 19.1834 18.7929 18.7929Z"
        fill={colors.color}
      />
    </SdlSVG>
  )
}

const styles = StyleSheet.create({
  ctrlIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
