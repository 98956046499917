import React from 'react'
import {View} from 'react-native'
import {StyleSheet, Text} from 'react-native'
import {Trans} from '@lingui/macro'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {atoms as a, useTheme} from '#/alf'
import {SdlInput} from '../../composer/text-input/SdlTextInput'
import {SdlButton} from '../../util/SdlButton'
import {apiHash, apiId} from './config'
import {CountryDropdown} from './CountryDropdown'
import {useTelegramSupportCountries} from './hooks'
import {ApiCountryCode, BaseLoginParams} from './types'

interface IProps {
  client: any
  updateLoginParams: (p: BaseLoginParams) => void
  onCancel?: () => void
}
export function LoginBase(props: IProps) {
  const {client, updateLoginParams} = props
  const [phone, setPhone] = React.useState('')
  const [password, setPassword] = React.useState('')
  const {closeModal} = useModalControls()
  const {isDesktop} = useWebMediaQueries()
  const [country, setCountry] = React.useState<ApiCountryCode | null>(null)

  const t = useTheme()
  const countries = useTelegramSupportCountries(client)

  const getCode = React.useCallback(async () => {
    if (client) {
      const _phone = phone.replace(/\s+/g, '')
      await client.connect()
      await client.sendCode(
        {
          apiId,
          apiHash,
        },
        _phone,
      )
      updateLoginParams({phone: _phone, password})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, phone, password])

  const formatPhone = (val: string) => {
    if (`+${country?.countryCode}`.indexOf(val) === 0) {
      setPhone(val)
      return
    }
    if (country?.patterns) {
      const _phone = val
        .replace(/[^\d]/g, '')
        .replace(`${country.countryCode}`, '')
        .split('')
      const pattern = country.patterns[0].split(' ')
      const len = pattern.length
      let result = []
      for (let i = 0; i < len; i++) {
        if (_phone.length > pattern[i].length) {
          result.push(_phone.splice(0, pattern[i].length).join(''))
        } else {
          result.push(_phone.join(''))
          break
        }
      }
      setPhone(
        [`+${country.countryCode}`, ...result.filter(Boolean)]
          .filter(Boolean)
          .join(' '),
      )
    } else {
      setPhone(val)
    }
  }

  const onCancel = () => {
    closeModal()
  }

  return (
    <View style={[styles.page, a.flex_1]}>
      <View>
        <Text style={styles.title}>
          <Trans>Link Telegram</Trans>
        </Text>
        <View style={a.mb_md}>
          <Text style={a.mb_sm}>
            <Trans>Your Country</Trans>
          </Text>
          <CountryDropdown
            list={countries}
            updateCountry={val => {
              setCountry(val)
              setPhone(`+${val.countryCode}`)
            }}
          />
        </View>
        <View style={a.mb_md}>
          <Text style={a.mb_sm}>
            <Trans>Your Phone Number</Trans>
          </Text>
          <SdlInput value={phone} setValue={val => formatPhone(val)} />
        </View>
        <View style={a.mb_md}>
          <Text style={a.mb_sm}>
            <Trans>Password (Optional)</Trans>
          </Text>
          <SdlInput value={password} setValue={setPassword} />
        </View>
        <View style={a.mb_md}>
          <Text style={{color: t.palette.contrast_500}}>
            <Trans>
              If you have Two-Step verification enabled, so your account is
              protected with additional password.
            </Trans>
          </Text>
        </View>
      </View>

      <View
        style={[{gap: 10, marginTop: 16}, isDesktop ? a.flex_row_reverse : {}]}>
        <SdlButton text="Next" onPress={getCode} />
        <SdlButton text="Cancel" type="secondary" onPress={onCancel} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    // position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 0,
    flex: 1,
    // padding: 10,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
