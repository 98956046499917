import React, {useEffect, useMemo, useState} from 'react'
import {KeyboardAvoidingView, View} from 'react-native'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {LayoutAnimationConfig} from 'react-native-reanimated'
import {BarcodeScanningResult} from 'expo-camera'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'
import {ethers} from 'ethers'

import {track} from '#/lib/analytics/analytics'
// import {useAnalytics} from '#/lib/analytics/analytics'
import {DEFAULT_SERVICE, SDN_SERVICE} from '#/lib/constants'
import {parserMnemonic} from '#/lib/media/manip'
import {NavigationProp} from '#/lib/routes/types'
import {cleanError} from '#/lib/strings/errors'
import {logger} from '#/logger'
import {useServiceQuery} from '#/state/queries/service'
import {SessionAccount, useSession, useSessionApi} from '#/state/session'
import {SendingNetworkAgent, SignLoginProps} from '#/state/session/sdn-agent'
import {useLoggedOutView} from '#/state/shell/logged-out'
import {LoggedOutLayout} from '#/view/com/util/layouts/LoggedOutLayout'
import {LoadingScreen} from 'view/com/util/LoadingScreen'
import {ForgotPasswordForm} from '#/screens/Login/ForgotPasswordForm'
// import {LoginForm} from '#/screens/Login/LoginForm'
import {PasswordUpdatedForm} from '#/screens/Login/PasswordUpdatedForm'
import {SetNewPasswordForm} from '#/screens/Login/SetNewPasswordForm'
import {ScannerScreen} from '#/screens/Scanner'
import {atoms as a} from '#/alf'
import {ChooseAccountForm} from './ChooseAccountForm'
import {ScreenTransition} from './ScreenTransition'

enum Forms {
  Login,
  ChooseAccount,
  ScannerQRCode,
  ForgotPassword,
  SetNewPassword,
  PasswordUpdated,
  LoginLoader,
}

export const Login = ({onPressBack}: {onPressBack: () => void}) => {
  const {_} = useLingui()

  const {accounts} = useSession()
  const {signIn} = useSessionApi()
  // const {track} = useAnalytics()
  const {requestedAccountSwitchTo} = useLoggedOutView()
  const requestedAccount = accounts.find(
    acc => acc.did === requestedAccountSwitchTo,
  )

  const [error, setError] = React.useState<string>('')
  const [, setImageError] = useState<string>('')
  const [mnemonic, setMnemonic] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [logging, setLogging] = useState(false)
  const navigation = useNavigation<NavigationProp>()
  const [serviceUrl, setServiceUrl] = React.useState<string>(
    requestedAccount?.service || DEFAULT_SERVICE,
  )
  const [, setInitialHandle] = React.useState<string>(
    requestedAccount?.handle || '',
  )
  const [currentForm, setCurrentForm] = React.useState<Forms>(
    requestedAccount
      ? Forms.Login
      : accounts.length
      ? Forms.ChooseAccount
      : Forms.Login,
  )

  useEffect(() => {
    if (logging) {
      setCurrentForm(Forms.LoginLoader)
    }
  }, [logging])

  useEffect(() => {
    if (error) {
      setCurrentForm(Forms.ChooseAccount)
    }
  }, [error])

  const {
    data: serviceDescription,
    error: serviceError,
    // refetch: refetchService,
  } = useServiceQuery(serviceUrl)

  const onSelectAccount = (account?: SessionAccount) => {
    if (account?.service) {
      setServiceUrl(account.service)
    }
    setInitialHandle(account?.handle || '')
    setCurrentForm(Forms.Login)
  }

  const wallet = useMemo(() => {
    if (mnemonic) {
      return ethers.Wallet.fromPhrase(mnemonic)
    }
  }, [mnemonic])

  const onCodeScanned = React.useCallback((code: BarcodeScanningResult) => {
    if (code) {
      // setServiceUrl(code.data)
      setCurrentForm(Forms.ChooseAccount)
      setTimeout(() => {
        if (code.raw) {
          const isValidMnemonic = ethers.Mnemonic.isValidMnemonic(code.raw)
          if (isValidMnemonic && code.raw) {
            setMnemonic(code.raw)
          }
        }
      })
    }
  }, [])

  const onCloseScanner = () => {
    gotoForm(Forms.ChooseAccount)
  }

  const gotoForm = (form: Forms) => {
    setError('')
    setCurrentForm(form)
  }

  const doLogin = React.useCallback(async () => {
    if (!wallet) {
      setLogging(false)
      return
    }

    const params: SignLoginProps = {
      serviceUrl,
      address,
      wallet,
    }
    try {
      const agent = new SendingNetworkAgent({
        state: params,
        sdnServerUrl: SDN_SERVICE,
      })
      const data = await agent.login()
      if (data) {
        await signIn(
          {service: serviceUrl, data: {...data, mnemonic}},
          'LoginForm',
        )
        navigation.navigate('Home')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLogging(false)
    }
  }, [address, mnemonic, navigation, serviceUrl, signIn, wallet])

  useEffect(() => {
    if (wallet) {
      wallet.getAddress().then(value => {
        setAddress(value)
        doLogin()
      })
    } else {
      setAddress('')
    }
  }, [doLogin, wallet])

  const onImportWallet = React.useCallback(async (img: RNImage | null) => {
    setImageError('')
    if (!img) {
      // setNewUserBanner(null)
      // setUserBanner(null)
      setMnemonic('')
      return
    }
    setLogging(true)
    setError('')
    track('EditProfile:BannerSelected')
    try {
      const code = await parserMnemonic(img)
      console.log(code)
      const isValidMnemonic = ethers.Mnemonic.isValidMnemonic(code)
      if (isValidMnemonic && code) {
        setMnemonic(code)
      } else {
        setError('Invalid QR code')
        console.error('qrcode Recognition failure', img, code)
      }
      // const finalImg = await compressIfNeeded(img, 1000000)
      // setNewUserBanner(finalImg)
      // setUserBanner(finalImg.path)
    } catch (e: any) {
      setImageError(cleanError(e))
    }
  }, [])

  React.useEffect(() => {
    if (serviceError) {
      setError(
        _(
          msg`Unable to contact your service. Please check your Internet connection.`,
        ),
      )
      logger.warn(`Failed to fetch service description for ${serviceUrl}`, {
        error: String(serviceError),
      })
    } else {
      setError('')
    }
  }, [serviceError, serviceUrl, _])

  // const onPressForgotPassword = () => {
  //   track('Signin:PressedForgotPassword')
  //   setCurrentForm(Forms.ForgotPassword)
  // }

  let content = null
  let title = ''
  let description = ''

  switch (currentForm) {
    case Forms.Login:
    case Forms.ChooseAccount:
      title = _(msg`Login`)
      description = _(msg`Select from an existing account`)
      content = (
        <ChooseAccountForm
          onSelectAccount={onSelectAccount}
          onSelectNewWallet={onImportWallet}
          onPressBack={onPressBack}
          onPressScanner={() => {
            gotoForm(Forms.ScannerQRCode)
          }}
        />
      )
      break
    case Forms.ScannerQRCode:
      title = _(msg`Scanner QRCode`)
      description = _(msg`Scanner QRCode`)
      content = (
        <ScannerScreen
          onCodeScanned={onCodeScanned}
          onCloseScanner={onCloseScanner}
        />
      )
      break
    case Forms.ForgotPassword:
      title = _(msg`Forgot Password`)
      description = _(msg`Let's get your password reset!`)
      content = (
        <ForgotPasswordForm
          error={error}
          serviceUrl={serviceUrl}
          serviceDescription={serviceDescription}
          setError={setError}
          setServiceUrl={setServiceUrl}
          onPressBack={() => gotoForm(Forms.Login)}
          onEmailSent={() => gotoForm(Forms.SetNewPassword)}
        />
      )
      break
    case Forms.SetNewPassword:
      title = _(msg`Forgot Password`)
      description = _(msg`Let's get your password reset!`)
      content = (
        <SetNewPasswordForm
          error={error}
          serviceUrl={serviceUrl}
          setError={setError}
          onPressBack={() => gotoForm(Forms.ForgotPassword)}
          onPasswordSet={() => gotoForm(Forms.PasswordUpdated)}
        />
      )
      break
    case Forms.PasswordUpdated:
      title = _(msg`Password updated`)
      description = _(msg`You can now sign in with your new password.`)
      content = (
        <PasswordUpdatedForm onPressNext={() => gotoForm(Forms.Login)} />
      )
      break
  }

  if (currentForm === Forms.LoginLoader) {
    return (
      <View style={[a.flex_1, a.h_full, a.justify_center, a.align_center]}>
        <LoadingScreen />
      </View>
    )
  }

  return (
    <KeyboardAvoidingView testID="signIn" behavior="padding" style={a.flex_1}>
      <LoggedOutLayout
        leadin=""
        title={title}
        description={description}
        scrollable>
        <LayoutAnimationConfig skipEntering skipExiting>
          <ScreenTransition style={[a.flex_1]} key={currentForm}>
            {content}
          </ScreenTransition>
        </LayoutAnimationConfig>
      </LoggedOutLayout>
    </KeyboardAvoidingView>
  )
}
