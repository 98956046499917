import React from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'

import {useModalControls} from '#/state/modals'
import {useTelegramClient} from '#/view/com/modals/TelegramModal/hooks'
import {CenteredView} from '#/view/com/util/Views'
import {SvgComponent as TelegramLink} from '#/view/icons/TelegramLink'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {Telegram} from '#/components/icons/Telegram'
import {Loader} from '#/components/Loader'

type Props = {
  bind: boolean
  binding: boolean
  handleBind: () => Promise<void>
}
export function LinkTelegram({binding, handleBind}: Props) {
  const {client} = useTelegramClient()
  const t = useTheme()
  const {openModal} = useModalControls()

  const onPressLink = async () => {
    if (client && (await client.checkAuthorization())) {
      await handleBind()
    } else {
      openModal({
        name: 'telegram',
      })
    }
  }

  return (
    <CenteredView sideBorders style={[a.flex_1]}>
      <View style={styles.container}>
        <Text style={styles.title}>
          <Trans>A True Circle for Free Expression</Trans>
        </Text>
        <TelegramLink />
        <Button
          style={styles.linkButton}
          color="primary"
          variant="solid"
          size="large"
          label="Link Telegram"
          disabled={binding}
          onPress={onPressLink}>
          <Telegram style={{color: t.atoms.text.color}} />
          <Text style={[t.atoms.text, {fontSize: 16, fontWeight: 600}]}>
            <Trans>Link&Invite</Trans>
          </Text>
          {binding && <Loader />}
        </Button>
        <View>
          <Text style={styles.subTitle}>
            <Trans>Invite 2 friends and start exploring! </Trans>
          </Text>
        </View>
      </View>
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    flex: 1,
    padding: 20,
    paddingTop: 100,
    gap: 20,
    alignItems: 'center',
    // backgroundColor: 'rgba(0,0,0,0.5)',
  },
  linkButton: {
    width: '70%',
  },
  title: {
    fontSize: 32,
    fontWeight: 800,
    textAlign: 'center',
  },

  subTitle: {
    fontSize: 14,
    textAlign: 'center',
    color: '#333333',
  },
})
