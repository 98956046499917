import React from 'react'
import {View} from 'react-native'
import {BarcodeScanningResult} from 'expo-camera'

// import {Code} from 'react-native-vision-camera'
import {atoms as a} from '#/alf'
// import {ScannerWebView} from './ScannerWebView'
import CameraScannerView from './CameraScannerView'

export function ScannerScreen({
  onCodeScanned,
  onCloseScanner,
}: {
  onCodeScanned: (codes: BarcodeScanningResult) => void
  onCloseScanner?: () => void
}) {
  return (
    <View style={[a.h_full_vh]}>
      {/* <ScannerWebView
        onCodeScanned={onCodeScanned}
        onCloseScanner={onCloseScanner}
      /> */}
      <CameraScannerView
        onCodeScanned={onCodeScanned}
        onCloseScanner={onCloseScanner}
      />
    </View>
  )
}
