import React from 'react'
import {SafeAreaView, ScrollView, StyleSheet, Text, View} from 'react-native'
import {StackActions, useNavigation} from '@react-navigation/native'

import {emitSoftReset} from '#/state/events'
import {useSession} from '#/state/session'
import {useSetDrawerOpen} from '#/state/shell'
import {useAnalytics} from 'lib/analytics/analytics'
import {useNavigationTabState} from 'lib/hooks/useNavigationTabState'
import {usePalette} from 'lib/hooks/usePalette'
import {getTabState, TabState} from 'lib/routes/helpers'
import {NavigationProp} from 'lib/routes/types'
import {s} from 'lib/styles'
import {useTheme} from 'lib/ThemeContext'
import {isWeb} from 'platform/detection'
import {useProfileStore} from '#/view/com/util/sdlStore/ProfileStore'
import {NavSignupCard} from '#/view/shell/NavSignupCard'
import {useTheme as useAlfTheme} from '#/alf'
import DrawerProfileCard from './DrawerProfileCard'
import {
  // AssetsMenuItem,
  BookmarksMenuItem,
  // MessageMenuItem,
  NotificationsMenuItem,
  ProfileMenuItem,
  SettingsMenuItem,
} from './MenuItems'

let DrawerContent = ({}: {}): React.ReactNode => {
  const theme = useTheme()
  const t = useAlfTheme()
  const pal = usePalette('default')
  const setDrawerOpen = useSetDrawerOpen()
  const navigation = useNavigation<NavigationProp>()
  const {track} = useAnalytics()
  const {
    // isAtHome,
    isAtSearch,
    // isAtFeeds,
    // isAtNotifications,
    // isAtMyProfile
  } = useNavigationTabState()
  const {hasSession, currentAccount} = useSession()

  // events
  // =

  const {setTabName} = useProfileStore()

  const onPressTab = React.useCallback(
    (tab: string) => {
      track('Menu:ItemClicked', {url: tab})
      const state = navigation.getState()
      setDrawerOpen(false)
      if (tab === 'Bookmarks') {
        setTabName('Bookmarks')
      }
      if (isWeb) {
        // hack because we have flat navigator for web and MyProfile does not exist on the web navigator -ansh
        if (tab === 'MyProfile') {
          navigation.navigate('Profile', {name: currentAccount!.handle})
        } else if (tab === 'Bookmarks') {
          navigation.navigate('Profile', {
            name: currentAccount!.handle,
            tabName: 'Bookmarks',
          })
        } else {
          // @ts-ignore must be Home, Search, Notifications, or MyProfile
          navigation.navigate(tab)
        }
      } else {
        const tabState = getTabState(state, tab)
        if (tabState === TabState.InsideAtRoot) {
          emitSoftReset()
        } else if (tabState === TabState.Inside) {
          navigation.dispatch(StackActions.popToTop())
        } else {
          if (tab === 'Bookmarks') {
            navigation.navigate(`MyProfileTab`)
          } else {
            // @ts-ignore must be Home, Search, Notifications, or MyProfile
            navigation.navigate(`${tab}Tab`)
          }
        }
      }
    },
    [track, navigation, setDrawerOpen, currentAccount, setTabName],
  )

  // const onPressHome = React.useCallback(() => onPressTab('Home'), [onPressTab])

  // const onPressSearch = React.useCallback(
  //   () => onPressTab('Search'),
  //   [onPressTab],
  // )

  const noPressNotification = React.useCallback(
    () => onPressTab('Notifications'),
    [onPressTab],
  )

  const onPressProfile = React.useCallback(() => {
    onPressTab('MyProfile')
  }, [onPressTab])

  const onPressBookMark = React.useCallback(() => {
    onPressTab('Bookmarks')
  }, [onPressTab])

  // const onPressMyFeeds = React.useCallback(() => {
  //   track('Menu:ItemClicked', {url: 'Feeds'})
  //   navigation.navigate('Feeds')
  //   setDrawerOpen(false)
  // }, [navigation, setDrawerOpen, track])

  // const onPressLists = React.useCallback(() => {
  //   track('Menu:ItemClicked', {url: 'Lists'})
  //   navigation.navigate('Lists')
  //   setDrawerOpen(false)
  // }, [navigation, track, setDrawerOpen])

  const onPressSettings = React.useCallback(() => {
    track('Menu:ItemClicked', {url: 'Settings'})
    navigation.navigate('Settings')
    setDrawerOpen(false)
  }, [navigation, track, setDrawerOpen])

  // const noPressMessage = React.useCallback(
  //   () => onPressTab('Messages'),
  //   [onPressTab],
  // )

  // const onPressFeedback = React.useCallback(() => {
  //   track('Menu:FeedbackClicked')
  //   Linking.openURL(
  //     FEEDBACK_FORM_URL({
  //       email: currentAccount?.email,
  //       handle: currentAccount?.handle,
  //     }),
  //   )
  // }, [track, currentAccount])

  // const onPressHelp = React.useCallback(() => {
  //   track('Menu:HelpClicked')
  //   Linking.openURL(HELP_DESK_URL)
  // }, [track])

  // rendering
  // =

  return (
    <View
      testID="drawer"
      style={[
        styles.view,
        {maxWidth: 300},
        theme.colorScheme === 'light' ? pal.view : t.atoms.bg_contrast_25,
      ]}>
      <SafeAreaView style={s.flex1}>
        <ScrollView style={styles.main}>
          {hasSession && currentAccount ? (
            // <></>
            <View style={{}}>
              <DrawerProfileCard
                account={currentAccount}
                onPressProfile={onPressProfile}
                closeDrawer={() => setDrawerOpen(false)}
              />
            </View>
          ) : (
            <View style={{paddingRight: 20}}>
              <NavSignupCard />
            </View>
          )}
          <ProfileMenuItem isActive={isAtSearch} onPress={onPressProfile} />
          <BookmarksMenuItem isActive={isAtSearch} onPress={onPressBookMark} />
          {/* <AssetsMenuItem isActive={isAtSearch} onPress={() => {}} /> */}
          <View style={styles.container}>
            <View style={styles.box} />
          </View>
          <Text> General</Text>
          {/* <MessageMenuItem isActive={isAtSearch} onPress={noPressMessage} /> */}
          <NotificationsMenuItem
            isActive={isAtSearch}
            onPress={noPressNotification}
          />
          <SettingsMenuItem isActive={isAtSearch} onPress={onPressSettings} />
        </ScrollView>
      </SafeAreaView>
    </View>
  )
}
DrawerContent = React.memo(DrawerContent)
export {DrawerContent}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  box: {
    width: '100%',
    height: 38,
    borderTopWidth: 1,
    borderTopColor: '#d9d9d9',
  },
  view: {
    flex: 1,
    paddingBottom: 50,
  },
  main: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
})
