import React, {useCallback, useState} from 'react'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import {Trans} from '@lingui/macro'

import {IVisible, useModalControls} from '#/state/modals'
import {RadioIcon, RightIcon} from '#/view/icons/ModalIcons'
import {useTheme} from '#/alf'

export const snapPoints = ['fullscreen']

export function Component({visible, setVisible}: IVisible) {
  const t = useTheme()
  const {closeModal} = useModalControls()
  const [value, setValue] = useState(visible)

  const onPressConfirm = useCallback(() => {
    setVisible(value)
    closeModal()
  }, [closeModal, setVisible, value])

  const getActive = useCallback(
    (str: string) => {
      return str === value
    },
    [value],
  )

  return (
    <View style={styles.page}>
      <Text
        style={{
          fontWeight: 'bold',
          fontSize: 19,
          textAlign: 'center',
          paddingBottom: 30,
        }}>
        <Trans>Visible To</Trans>
      </Text>

      <TouchableWithoutFeedback
        accessibilityRole="button"
        onPress={() => setValue('Public')}>
        <View
          style={[
            styles.item,
            getActive('Public') && {borderColor: '#0ABF52'},
          ]}>
          <RadioIcon active={getActive('Public')} />
          <View style={styles.itemText}>
            <Text style={[styles.itemLabel, {fontWeight: '500'}]}>
              <Trans>Public</Trans>
            </Text>
            <Text style={styles.itemValue}>
              <Trans>Anyone may see it in 'Explore'</Trans>
            </Text>
          </View>
          <View style={{flex: 1, alignItems: 'flex-end'}}>
            <RightIcon />
          </View>
        </View>
      </TouchableWithoutFeedback>

      <TouchableWithoutFeedback
        accessibilityRole="button"
        onPress={() => setValue('Circle')}>
        <View
          style={[
            styles.item,
            getActive('Circle') && {borderColor: '#0ABF52'},
          ]}>
          <RadioIcon active={getActive('Circle')} />
          <View style={styles.itemText}>
            <Text style={[styles.itemLabel, {fontWeight: '500'}]}>
              <Trans>Circle</Trans>
            </Text>
            <Text>
              <Trans>Only to your telegram mutual contacts</Trans>
            </Text>
          </View>
          <View style={{flex: 1, alignItems: 'flex-end'}}>
            <RightIcon />
          </View>
        </View>
      </TouchableWithoutFeedback>

      <View style={styles.ctrl}>
        <TouchableOpacity
          accessibilityRole="button"
          style={[styles.button, {backgroundColor: t.palette.primary}]}
          onPress={onPressConfirm}>
          <Text
            style={{
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'black',
            }}>
            <Trans>Confirm</Trans>
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          accessibilityRole="button"
          style={styles.button}
          onPress={closeModal}>
          <Text
            style={[
              t.atoms.text,
              {
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#5E6272',
              },
            ]}>
            <Trans>Close</Trans>
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: 'auto',
    padding: 10,
    paddingHorizontal: 20,
    backgroundColor: 'transparent',
  },
  item: {
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 8,
    marginBottom: 12,
    padding: 10,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  itemText: {},
  itemLabel: {
    fontSize: 16,
  },
  itemValue: {
    fontSize: 12,
  },
  ctrl: {
    marginTop: 20,
  },
  button: {
    height: 42,
    padding: 10,
    marginBottom: 10,
    borderRadius: 100,
    backgroundColor: '#F1F3F5',
    justifyContent: 'center',
  },
})
